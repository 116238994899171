import styled from 'styled-components';

const ButtonLinkStyle = styled.button`
  font-size: 12px;
  color: ${({ theme, blue }) => (blue && theme.blue500) || theme.grey700};
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default ButtonLinkStyle;
