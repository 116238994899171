import { differenceInCalendarMonths, parseISO } from 'date-fns';
import { EVENT_TYPES, UNIQUE_EVENT_TYPES_BY_HEIFER } from '../constants';
import { getEventsByType } from './heiferEvents';

export const canHeiferHaveAnotherEventOfType = (heifer, type) => {
  const nbOfEventsOfThisType = getEventsByType(heifer.events, type).length;
  if (nbOfEventsOfThisType === 0) {
    return true;
  }

  return !UNIQUE_EVENT_TYPES_BY_HEIFER.includes(type);
};

export const allEventTypes = () => {
  return Object.keys(EVENT_TYPES).map(type => EVENT_TYPES[type]);
};

export const getEventTypeShortName = (eventType = []) => {
  const shortNamesByTypes = {
    [EVENT_TYPES.ABATTOIR]: 'Abattoir',
    [EVENT_TYPES.ABORTION]: 'Avortement',
    [EVENT_TYPES.BIRTH]: 'Naissance',
    [EVENT_TYPES.BREEDING_TIP]: 'Conseil accouplement',
    [EVENT_TYPES.CALVING]: 'Vêlage',
    [EVENT_TYPES.DEATH]: 'Mort',
    [EVENT_TYPES.DELEGATION]: 'Délégation',
    [EVENT_TYPES.DISEASE]: 'Maladie',
    [EVENT_TYPES.INSEMINATION]: 'Insémination',
    [EVENT_TYPES.MEASUREMENT]: 'Mesure',
    [EVENT_TYPES.NO_RETURN]: 'Pas de retour',
    [EVENT_TYPES.OTHER]: 'Autre',
    [EVENT_TYPES.RETURN]: 'Retour',
    [EVENT_TYPES.RETURN_ACCEPTED]: 'Retour accepté',
    [EVENT_TYPES.SOLD]: 'Vente',
    [EVENT_TYPES.SONOGRAM_1]: 'Échographie génisse pleine',
    [EVENT_TYPES.SONOGRAM_2]: 'Échographie génisse pleine confirmée',
    [EVENT_TYPES.SONOGRAM_EMPTY]: 'Échographie génisse vide',
    [EVENT_TYPES.SONOGRAM_OTHERS]: 'Échographie génisse pleine complémentaire',
  };

  return shortNamesByTypes[eventType] || "Type d'évènement inconnu";
};

export const getDescription = (event, events = []) => {
  switch (event.type) {
    case EVENT_TYPES.MEASUREMENT:
      return `Mesure ${differenceInCalendarMonths(
        parseISO(event.date),
        parseISO(getEventsByType(events, EVENT_TYPES.BIRTH)[0].date),
      )} mois ${
        event.weighingType && event.weighingType !== 'OTHER'
          ? `(${event.weighingType})`
          : ''
      }`;
    case EVENT_TYPES.INSEMINATION:
      return `Insémination ${event.sexed ? 'sexée' : ''}`;
    case EVENT_TYPES.DELEGATION:
      return 'Mise en délégation';
    case EVENT_TYPES.SONOGRAM_1:
      return 'Échographie génisse pleine';
    case EVENT_TYPES.SONOGRAM_2:
      return 'Échographie génisse pleine confirmée';
    case EVENT_TYPES.SONOGRAM_EMPTY:
      return 'Échographie génisse vide';
    case EVENT_TYPES.SONOGRAM_OTHERS:
      return 'Échographie génisse pleine complémentaire';
    case EVENT_TYPES.DISEASE:
      return `Maladie ${event.disease}`;
    case EVENT_TYPES.BIRTH:
      return 'Naissance';
    case EVENT_TYPES.DEATH:
      return 'Mort';
    case EVENT_TYPES.RETURN:
      return 'Retour (estimation)';
    case EVENT_TYPES.CALVING:
      return 'Vêlage (estimation)';
    case EVENT_TYPES.ABORTION:
      return 'Avortement';
    // We don't want it to be known
    case EVENT_TYPES.SOLD:
    case EVENT_TYPES.OTHER:
      return 'Autre';
    case EVENT_TYPES.ABATTOIR:
      return 'Abattoir';
    case EVENT_TYPES.NO_RETURN:
      return 'Pas de retour chez le naisseur';
    case EVENT_TYPES.RETURN_ACCEPTED:
      return 'Retour accepté par le naisseur';
    case EVENT_TYPES.BREEDING_TIP:
      return 'Conseils accouplement';
    default:
      return null;
  }
};

export const getExtraInfos = (event, returnEstimatedWeight) => {
  switch (event.type) {
    case EVENT_TYPES.MEASUREMENT:
      return `Poids génisse : ${event.weight} kg${
        event.gmq ? ` (GMQ : ${event.gmq}kg/j)` : ''
      }`;
    case EVENT_TYPES.DISEASE:
      return `Traitement : ${event.treatment}`;
    case EVENT_TYPES.ABATTOIR:
      return event.note ? `Note : ${event.note}` : null;
    case EVENT_TYPES.RETURN:
      return returnEstimatedWeight
        ? `Poids génisse : ${returnEstimatedWeight.toFixed(0)}kg`
        : null;
    case EVENT_TYPES.INSEMINATION:
      return event.bull ? `Taureau : ${event.bull}` : 'Taureau non saisi';
    default:
      return null;
  }
};

// Get the color that will be used as indicator in the timeline
export const getTypeColor = type => {
  switch (type) {
    case EVENT_TYPES.SONOGRAM_1:
    case EVENT_TYPES.SONOGRAM_2:
    case EVENT_TYPES.SONOGRAM_EMPTY:
    case EVENT_TYPES.SONOGRAM_OTHERS:
      return 'green';
    case EVENT_TYPES.DISEASE:
    case EVENT_TYPES.ABATTOIR:
      return 'red';
    case EVENT_TYPES.BIRTH:
    case EVENT_TYPES.DEATH:
    case EVENT_TYPES.SOLD:
    case EVENT_TYPES.NO_RETURN:
    case EVENT_TYPES.RETURN_ACCEPTED:
      return 'purple';
    case EVENT_TYPES.RETURN:
    case EVENT_TYPES.CALVING:
      return 'orange';
    default:
      return 'blue';
  }
};
