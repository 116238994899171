import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { ForgotPassword } from 'aws-amplify-react';
import { toast } from 'react-toastify';

import H1 from '../../atoms/titles/H1';
import AuthLayout from '../../layouts/Auth';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';

const FormStyle = styled.form`
  width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  input {
    margin-bottom: 30px;
  }
`;

const Title = styled(H1)`
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
`;

const Description = styled.p`
  margin-bottom: 40px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export default class MyForgotPassword extends ForgotPassword {
  state = {
    delivery: null,
    username: '',
    code: '',
    password: '',
  };

  input = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  sendCode = async event => {
    event.preventDefault();
    const { username } = this.state;

    // If it's a phone number with 10 digits, then add the international prefix for Cognito
    const login = /^\d{10}$/.test(username)
      ? `+33${username.toString().slice(-9)}`
      : username;

    try {
      const data = await Auth.forgotPassword(login);

      if ('CodeDeliveryDetails' in data) {
        this.setState({ delivery: data.CodeDeliveryDetails });
      }
    } catch (err) {
      toast.error(
        "Une erreur est survenue, veuillez contacter l'administrateur",
      );
    }
  };

  submitCode = async event => {
    event.preventDefault();
    const { username, code, password } = this.state;

    // If it's a phone number with 10 digits, then add the international prefix for Cognito
    const login = /^\d{10}$/.test(username)
      ? `+33${username.toString().slice(-9)}`
      : username;

    try {
      await Auth.forgotPasswordSubmit(login, code, password);

      this.changeState('signIn');
      this.setState({ delivery: null });
    } catch (err) {
      toast.error(
        "Une erreur est survenue, veuillez contacter l'administrateur",
      );
    }
  };

  render() {
    if (!this._validAuthStates.includes(this.props.authState)) {
      this._isHidden = true;
      return null;
    }

    const { delivery } = this.state;

    return (
      <AuthLayout>
        {delivery ? (
          <FormStyle onSubmit={this.submitCode}>
            <Title>Nouveau mot de passe</Title>
            <Input
              type="text"
              name="code"
              autoComplete="off"
              onChange={this.input}
              value={this.state.code}
              label="Code de vérification"
            />
            <Input
              type="password"
              name="password"
              onChange={this.input}
              label="Nouveau mot de passe"
              value={this.state.password}
            />
            <SubmitButtonContainer>
              <Button type="submit">Valider</Button>
            </SubmitButtonContainer>
          </FormStyle>
        ) : (
          <FormStyle onSubmit={this.sendCode}>
            <Title>Mot de passe oublié</Title>
            <Description>
              Un code de vérification vous sera envoyé par email pour
              réinitialiser votre mot de passe.
            </Description>
            <Input
              type="text"
              placeholder="E-mail"
              label="E-mail"
              name="username"
              onChange={this.input}
              value={this.state.username}
              required
            />
            <SubmitButtonContainer>
              <Button type="submit">Envoyer le code de vérification</Button>
            </SubmitButtonContainer>
          </FormStyle>
        )}
      </AuthLayout>
    );
  }
}

MyForgotPassword.propTypes = {
  hide: PropTypes.array,
  authData: PropTypes.object,
};
