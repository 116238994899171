import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CellStyle from '../../atoms/table/TableCell';
import HeaderRowStyle from '../../atoms/table/TableHeaderRow';
import {
  orderByAttribut,
  orderByDate,
  orderByFarmName,
} from '../../commons/orderList';
import EmptyList from '../EmptyList';
import Item from './Item';

export const IdCellStyle = styled(CellStyle)`
  flex: 2;
  > * {
    font-weight: 700;
  }
`;

export const SmallCellStyle = styled(CellStyle)`
  flex: 1;
`;

export const MediumCellStyle = styled(CellStyle)`
  flex: 2;
`;

export const BreederCellStyle = styled(CellStyle)`
  flex: 3;
`;

class HeiferReturnsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDirection: 'desc',
      orderValue: 'return_date',
    };
  }

  getOrderedList = () => {
    const { list } = this.props;
    const { orderValue, orderDirection } = this.state;
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'producer':
      case 'breeder':
        return orderByFarmName(list, orderValue, orderStatement);
      case 'transfer_date':
      case 'return_date':
        return orderByDate(list, orderValue, orderStatement);
      default:
        return orderByAttribut(list, orderValue, orderStatement);
    }
  };

  toggleOrder = orderValue => {
    const { orderDirection } = this.state;

    this.setState({
      orderValue,
      orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  isRowActive = name => {
    const { orderValue, orderDirection } = this.state;

    return orderValue === name ? `active ${orderDirection}` : '';
  };

  render = () => {
    const { list } = this.props;

    return (
      <div>
        <HeaderRowStyle>
          <IdCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('id_heifer')}`}
              onClick={() => this.toggleOrder('id_heifer')}
            >
              Génisse
            </button>
          </IdCellStyle>

          <BreederCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('producer')}`}
              onClick={() => this.toggleOrder('producer')}
            >
              Naisseur
            </button>
          </BreederCellStyle>

          <BreederCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('breeder')}`}
              onClick={() => this.toggleOrder('breeder')}
            >
              Éleveur
            </button>
          </BreederCellStyle>

          <MediumCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('return_date')}`}
              onClick={() => this.toggleOrder('return_date')}
            >
              Date J-50
            </button>
          </MediumCellStyle>

          <MediumCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('transfer_date')}`}
              onClick={() => this.toggleOrder('transfer_date')}
            >
              Date réelle de retour
            </button>
          </MediumCellStyle>
        </HeaderRowStyle>

        {list.length > 0 ? (
          this.getOrderedList().map(heifer => (
            <Item key={heifer.id_heifer} heifer={heifer} />
          ))
        ) : (
          <EmptyList text={`Il n’y a pas de retours pour l'instant.`} />
        )}
      </div>
    );
  };
}

HeiferReturnsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id_heifer: PropTypes.string.isRequired,
      breeder: PropTypes.shape({
        farm_name: PropTypes.string.isRequired,
      }),
      producer: PropTypes.shape({
        farm_name: PropTypes.string.isRequired,
      }),
      transfer_date: PropTypes.string,
      return_date: PropTypes.string,
    }),
  ).isRequired,
};

export default HeiferReturnsList;
