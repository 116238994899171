import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { navigate } from '@reach/router';

import UserContext from '../context';
import { ReactComponent as Profile } from '../statics/ico/profile.svg';

const ProfileLogo = styled.div`
  align-self: center;
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .profile__picto {
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.grey300};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile__logout {
    z-index: 2;
    cursor: pointer;
    visibility: hidden;
    background: #fff;
    margin-top: 10px;
    padding: 10px 18px;
    font-size: 14px;
    border: solid 1px ${({ theme }) => theme.grey300};
    color: ${({ theme }) => theme.grey700};

    &:hover {
      color: ${({ theme }) => theme.blue500};
    }
  }

  &:focus,
  &:hover {
    .profile__logout {
      visibility: visible;
    }
  }
`;

class Logout extends React.Component {
  signOut = onStateChange => async () => {
    navigate('/');
    await Auth.signOut();
    onStateChange('signedOut');
  };

  render = () => (
    <UserContext.Consumer>
      {({ onStateChange }) => (
        <ProfileLogo>
          <div className="profile__picto">
            <Profile />
          </div>
          <button
            className="profile__logout"
            onClick={this.signOut(onStateChange)}
          >
            Déconnexion
          </button>
        </ProfileLogo>
      )}
    </UserContext.Consumer>
  );
}

export default Logout;
