import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.grey300};
  padding: 6px 30px;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.grey300};
  }

  p {
    font-size: 12px;
  }

  .leftSide {
    display: flex;
  }

  .heiferId {
    color: ${({ theme }) => theme.grey500};
    font-weight: 700;
    margin-right: 10px;
  }
`;

const List = styled.div`
  .list {
    max-height: 40vh;
    overflow: auto;
  }
`;

const PoolSummaryList = ({ pool }) => (
  <List>
    <div className="list">
      {pool.heifers.map(heifer => (
        <Item key={heifer.id}>
          <div className="leftSide">
            <p className="heiferId">{heifer.id_heifer}</p>
            <p>{heifer.producer.farm_name}</p>
          </div>
          <p>
            née le{' '}
            {format(
              parseISO(heifer.events.find(({ type }) => type === 'BIRTH').date),
              'dd/MM/yyyy',
            )}
          </p>
        </Item>
      ))}
    </div>
  </List>
);

PoolSummaryList.propTypes = {
  pool: PropTypes.object.isRequired,
};

export default PoolSummaryList;
