import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import Calendar from '../../atoms/DatePicker/Calendar';

const CalendarStyle = styled.div`
  flex: 1 0 0%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 0 5px 5px 0;
`;
const AvailabilitiesCalendar = React.memo(
  ({ availabilities, handleOnChange }) => (
    <CalendarStyle>
      <Calendar
        date={null}
        highlightDay={day =>
          availabilities.find(a => format(parseISO(a), 'dd/MM/yyyy') === day)
        }
        onChangeDate={handleOnChange}
      />
    </CalendarStyle>
  ),
);

AvailabilitiesCalendar.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default AvailabilitiesCalendar;
