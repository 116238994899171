import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../../atoms/Button';
import PoolSummaryTitle from '../PoolSummaryTitle';
import PoolSummaryList from '../PoolSummaryList';

const FormStyle = styled(Form)`
  margin-top: 30px;

  .list {
    margin: 0 -30px;
  }
  .info {
    font-size: 14px;
    margin-top: 3px;
  }
  .first {
    margin-top: 21px;
  }
  .buttonsBar {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const StepTwo = ({
  carrier,
  pool,
  onRequestClose,
  onUpdatePoolStatusToAssigned,
}) => (
  <Formik
    initialValues={{}}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      try {
        await onUpdatePoolStatusToAssigned(values);
        setSubmitting(false);
        resetForm({});
        onRequestClose();
      } catch (err) {
        setSubmitting(false);
        console.error(err);
      }
    }}
  >
    {({ resetForm, isSubmitting }) => (
      <FormStyle>
        <PoolSummaryTitle title="Transfert" pool={pool} carrier={carrier} />
        <PoolSummaryList pool={pool} />
        <div className="info first">
          L’affectation de ce lot enverra une notification à l’éleveur, au
          naisseur et au transporteur.
        </div>
        <div className="info">
          La date de transfert reste modifiable jusqu’à la veille du transfert.
        </div>
        <div className="buttonsBar">
          <Button
            type="button"
            onClick={() => {
              resetForm({});
              onRequestClose();
            }}
            disabled={isSubmitting}
            secondary
          >
            Annuler
          </Button>

          <Button type="submit" disabled={isSubmitting}>
            Ajouter
          </Button>
        </div>
      </FormStyle>
    )}
  </Formik>
);

StepTwo.propTypes = {
  carrier: PropTypes.string.isRequired,
  pool: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onUpdatePoolStatusToAssigned: PropTypes.func.isRequired,
};

export default StepTwo;
