import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import ClosePoolForm from './ClosePoolForm';
import ContainerSmall from '../layouts/ContainerSmall';
import H2 from '../atoms/titles/H2';
import { GET_POOL } from '../graphql/queries';
import { ReactComponent as Heifer } from '../statics/ico/heifer.svg';
import { ReactComponent as Profile } from '../statics/ico/profile.svg';
import DelegationHeifersList from '../molecules/PoolDetailsHeifersList/DelegationHeifersList';
import IconTitle from '../atoms/titles/IconTitle';
import PoolDate from '../molecules/PoolDate';
import PoolFarmInfos from '../molecules/PoolFarmInfos';
import Loader from '../atoms/Loader';
import Button from '../atoms/Button';
import Modal from '../atoms/Modal';
import UserContext from '../context';

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TitleStyle = styled(H2)`
  margin: 0;
`;

const RecapStyle = styled.div`
  margin-top: 30px;

  .recap__section {
    padding: 10px 0;
  }
`;

class PoolDelegationDetails extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isClosePoolModalOpen: false,
    };
  }

  toggleClosePoolModal = () => {
    const { isClosePoolModalOpen } = this.state;

    this.setState({ isClosePoolModalOpen: !isClosePoolModalOpen });
  };

  render = () => {
    const { poolId, heifersByProducerId } = this.props;
    const { isClosePoolModalOpen } = this.state;
    const { userGroups } = this.context;

    if (!poolId) return <Loader />;

    return (
      <>
        <Query query={GET_POOL} variables={{ id: poolId, heifersByProducerId }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return `Error! ${error.message}`;

            const { pool } = data;

            return (
              <ContainerSmall displayHeader color="blue">
                <HeaderStyle>
                  <TitleStyle white>Lot {pool.id_pool}</TitleStyle>
                  {userGroups.includes('admin') && (
                    <>
                      {pool.status === 'INPROGRESS' && (
                        <Button onClick={this.toggleClosePoolModal} tertiary>
                          Clore le transfert
                        </Button>
                      )}
                    </>
                  )}
                </HeaderStyle>
                <PoolDate title="Date de départ" date={pool.transfer_date} />

                <RecapStyle>
                  <H2 grey>Récapitulatif</H2>

                  <div className="recap__section">
                    <IconTitle icon={<Profile />}>Éleveur</IconTitle>
                    {pool.breeder && <PoolFarmInfos farm={pool.breeder} />}
                  </div>

                  <div className="recap__section">
                    <IconTitle purple icon={<Heifer />}>
                      <span>{pool.heifers.length}</span>{' '}
                      {`génisse${pool.heifers.length > 1 ? 's' : ''}`}
                    </IconTitle>
                    {pool.heifers.length > 0 && (
                      <DelegationHeifersList heifers={pool.heifers} />
                    )}
                  </div>
                </RecapStyle>
              </ContainerSmall>
            );
          }}
        </Query>
        <Modal
          isOpen={isClosePoolModalOpen}
          onRequestClose={this.toggleClosePoolModal}
        >
          <ClosePoolForm
            onRequestClose={this.toggleClosePoolModal}
            poolId={poolId}
            type="DELEGATION"
          />
        </Modal>
      </>
    );
  };
}

PoolDelegationDetails.propTypes = {
  poolId: PropTypes.string,
  heifersByProducerId: PropTypes.string,
};

PoolDelegationDetails.defaultProps = {
  poolId: null,
  heifersByProducerId: null,
};

export default PoolDelegationDetails;
