import React from 'react';
import { Query } from 'react-apollo';

import { GET_ASSIGNED_DELEGATION_POOLS } from '../graphql/queries';
import ContainerLarge from '../layouts/ContainerLarge';
import PageHeader from '../molecules/PageHeader';
import DelegationPoolList from '../molecules/DelegationPoolList';
import { ReactComponent as RefreshIcon } from '../statics/ico/refresh.svg';
import Loader from '../atoms/Loader';

const ManagePoolsDelegation = () => (
  <ContainerLarge>
    <Query query={GET_ASSIGNED_DELEGATION_POOLS} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;

        const pools = data.poolSummaries;

        return (
          <>
            <PageHeader icon={<RefreshIcon />} title="Transferts de lots" />
            <DelegationPoolList list={pools} />
          </>
        );
      }}
    </Query>
  </ContainerLarge>
);

export default ManagePoolsDelegation;
