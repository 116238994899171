import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { getEventDate } from '../../commons/heiferEvents';

const ReturnHeifersListStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 12px 20px;
  margin-bottom: 1px;
  color: ${({ theme }) => theme.grey700};

  .heiferItem__details {
    flex: 0 0 80%;
    display: flex;

    .heiferId {
      flex: 1;
      font-weight: 700;
      color: ${({ theme }) => theme.grey500};
      font-size: 12px;
      text-decoration: none;
    }

    .farmName {
      font-size: 12px;
      flex: 2;
      padding: 0 10px;

      a {
        color: inherit;
      }
    }
  }

  .heiferItem__availability {
    flex: 0 0 20%;
    text-align: end;
    font-size: 12px;
  }

  .delete__picto {
    margin-left: 20px;
    visibility: hidden;
    transition: all 150ms;
  }

  &:focus,
  &:hover {
    .delete__picto {
      cursor: pointer;
      visibility: visible;
    }
  }
`;

const ReturnHeifersList = ({ heifers, breeder }) => (
  <div>
    {heifers.map(heifer => (
      <ReturnHeifersListStyle key={heifer.id}>
        <div className="heiferItem__details">
          <Link to={`/view/farms/heifer/${heifer.id}`} className="heiferId">
            {heifer.id_heifer}
          </Link>
          <p className="farmName">
            Éleveur{' '}
            <Link to={`/view/farms/${breeder.id}`}>{breeder.farm_name}</Link>
          </p>
          <p className="farmName">
            Naisseur{' '}
            <Link to={`/view/farms/${heifer.producer.id}`}>
              {heifer.producer.farm_name}
            </Link>
          </p>
        </div>
        <div className="heiferItem__availability">
          <span>
            dispo. à partir du {getEventDate(heifer.events, 'RETURN')}
          </span>
        </div>
      </ReturnHeifersListStyle>
    ))}
  </div>
);

ReturnHeifersList.propTypes = {
  heifers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
      producer: PropTypes.shape({ farm_name: PropTypes.string.isRequired }),
      events: PropTypes.array.isRequired,
    }),
  ).isRequired,
  breeder: PropTypes.shape({
    farm_name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReturnHeifersList;
