import { compareAsc, isPast, parseISO } from 'date-fns';
import { HEIFER_STATUS, heiferCurrentState } from './heiferEvents';

export const getPoolStatus = pool => {
  // Case closed if status is closed
  if (pool.status === 'CLOSED') return 'clos';

  // Case in progress if transfer date in past and not closed yet
  if (isPast(parseISO(pool.transfer_date))) return 'en cours';

  // Case waiting
  return 'en attente';
};

export default getPoolStatus;

/**
 * Retire les lots dont toutes les génisses sont sans retour.
 *
 * Trie les lots restants par date de transfert croissante.
 *
 * @param {array} pools
 * @return {array}
 */
export const filterPoolsWithReturnableHeifers = pools => {
  return pools
    .map(pool => {
      const { heifers } = pool;

      const filteredHeifers = !heifers
        ? []
        : heifers.filter(heifer => {
            return (
              heiferCurrentState(heifer.events) !== HEIFER_STATUS._7_NO_RETURN
            );
          });

      return {
        ...pool,
        heifers: filteredHeifers,
      };
    })
    .filter(({ heifers }) => heifers && heifers.length > 0)
    .sort((a, b) =>
      compareAsc(parseISO(a.transfer_date), parseISO(b.transfer_date)),
    );
};
