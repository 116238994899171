import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { formErrorMsgs } from '../../constants';
import { FormikInput } from '../../molecules/Formik';
import H3 from '../../atoms/titles/H3';
import Button from '../../atoms/Button';
import AvailabilitiesFormikFields from '../../molecules/AvailabilitiesFormikFields';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 0 0 180px;
      padding: 10px 10px;
    }
  }

  .informations {
    background-color: ${({ theme }) => theme.grey200};
    margin: 20px -30px;
    padding: 20px 30px 10px;
  }

  .buttonsBar {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const initialValues = {
  poolsPerYear: '',
  capacityPerPool: '',
  delegPerYear: '',
  availabilities: [],
};

const formSchema = role => {
  if (role === 'breeder') {
    return Yup.object().shape({
      poolsPerYear: Yup.number().required(formErrorMsgs.required),
      capacityPerPool: Yup.number().required(formErrorMsgs.required),
    });
  }

  return Yup.object().shape({
    delegPerYear: Yup.number().required(formErrorMsgs.required),
  });
};

const StepTwo = ({ onRequestClose, onCreateFarm, role }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={formSchema(role)}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      try {
        await onCreateFarm(values);
        setSubmitting(false);
        resetForm(initialValues);
        onRequestClose();
      } catch (err) {
        setSubmitting(false);
        console.log(err);
      }
    }}
  >
    {({ resetForm, isSubmitting }) => (
      <FormStyle>
        <div className="informations">
          <H3>Informations</H3>
          <div className="form__row">
            {role === 'producer' && (
              <Field
                type="number"
                name="delegPerYear"
                placeholder="Ex. 10"
                label="Délégations / an"
                component={FormikInput}
              />
            )}
            {role === 'breeder' && (
              <>
                <Field
                  type="number"
                  name="poolsPerYear"
                  placeholder="Ex. 10"
                  label="Nombre de lots / an"
                  component={FormikInput}
                />
                <Field
                  type="number"
                  name="capacityPerPool"
                  placeholder="Ex. 10"
                  label="Génisse(s) / lot"
                  component={FormikInput}
                />
              </>
            )}
          </div>
        </div>

        {role === 'breeder' && <AvailabilitiesFormikFields />}

        <div className="buttonsBar">
          <Button
            type="button"
            onClick={() => {
              resetForm(initialValues);
              onRequestClose();
            }}
            disabled={isSubmitting}
            secondary
          >
            Annuler
          </Button>

          <Button type="submit" disabled={isSubmitting}>
            Ajouter
          </Button>
        </div>
      </FormStyle>
    )}
  </Formik>
);

StepTwo.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onCreateFarm: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default StepTwo;
