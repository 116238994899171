import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';

import IconButton from '../atoms/IconButton';
import DatePicker from '../atoms/DatePicker';
import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: -5px 0 15px;
`;

const ItemStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  .label {
    font-weight: 700;
    color: ${({ theme }) => theme.grey500};
    margin-right: 10px;
  }

  .deleteIcon {
    margin-left: 10px;
  }
`;

class AvailabilitiesFormikFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilitiesLength: props.initialLength,
    };
  }

  render = () => {
    const { availabilitiesLength } = this.state;

    return (
      <ContainerStyle>
        <ListStyle>{this.renderDatePickers()}</ListStyle>
        <div>
          <Button
            type="button"
            disabled={availabilitiesLength >= 10}
            onClick={this.incrementAvailabilities}
            iconPlus
            data-cy="availabilitiesFormikFields__add"
          >
            Ajouter une disponibilité
          </Button>
        </div>
      </ContainerStyle>
    );
  };

  renderDatePickers = () => {
    const { availabilitiesLength } = this.state;

    const datePickers = [];
    for (let i = 0; i < availabilitiesLength; i += 1) {
      datePickers.push(
        <ItemStyle key={i}>
          <p className="label">Disponibilité</p>

          <Field name={`availabilities.${i}`}>
            {({ field, form }) => (
              <>
                <DatePicker
                  id={field.name}
                  onChange={newDate => form.setFieldValue(field.name, newDate)}
                  isModal
                  date={field.value}
                  info={<H2 blue>Date de disponibilité</H2>}
                />

                {i > 0 && (
                  <IconButton
                    className="deleteIcon"
                    icon="delete"
                    onClick={() => {
                      this.removeField(form, i);
                    }}
                  />
                )}
              </>
            )}
          </Field>
        </ItemStyle>,
      );
    }

    return datePickers;
  };

  removeField = (form, fieldIndex) => {
    // Copy of values to avoid reference
    const updatedValues = [...form.values.availabilities];

    updatedValues.splice(fieldIndex, 1);

    // Recalculate indexes
    updatedValues.forEach((date, index) => {
      form.setFieldValue(`availabilities.${index}`, date);
    });

    // The removed value is still in the form, until we undefined it
    form.setFieldValue(`availabilities.${updatedValues.length}`, undefined);

    this.decrementAvailabilities();
  };

  decrementAvailabilities = () => {
    const { availabilitiesLength } = this.state;
    this.setState({ availabilitiesLength: availabilitiesLength - 1 });
  };

  incrementAvailabilities = () => {
    const { availabilitiesLength } = this.state;
    this.setState({ availabilitiesLength: availabilitiesLength + 1 });
  };
}

AvailabilitiesFormikFields.defaultProps = {
  initialLength: 1,
};

AvailabilitiesFormikFields.propTypes = {
  initialLength: PropTypes.number,
};

export default AvailabilitiesFormikFields;
