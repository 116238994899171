import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';

import CellStyle from '../../../atoms/table/TableCell';
import { FormikInput } from '../../Formik';

const Container = styled(CellStyle)`
  flex: 1.2;
  padding-top: 0;
  padding-bottom: 0;

  > div {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: ${({ theme }) => theme.blue300};
    padding-right: 10px;
    width: 80px;
  }

  input {
    padding: 5px 10px;
  }

  .label.empty {
    display: none;
  }
`;

const Input = ({ name }) => (
  <Container>
    <div>
      <Field
        type="number"
        name={name}
        component={FormikInput}
        placeholder="Poids"
      />
    </div>
  </Container>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Input;
