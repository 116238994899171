import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonLinkIcon from '../atoms/ButtonLinkIcon';
import {
  getEventDate,
  getEventsByType,
  getHeiferAgeAtCalving,
} from '../commons/heiferEvents';
import Modal from '../atoms/Modal';
import H2 from '../atoms/titles/H2';
import Button from '../atoms/Button';
import DefinitionText from '../atoms/DefinitionText';
import SaveEventForm from '../organisms/SaveEventForm';
import InfoLabel from '../atoms/InfoLabel';
import HeiferAge from '../atoms/HeiferAge';
import UserContext from '../context';
import CreateEventBreedingTip from '../organisms/CreateEventBreedingTip';
import RemoveHeiferForm from '../organisms/RemoveHeiferForm';
import { EVENT_TYPES, WEIGHT_STATUSES } from '../constants';
import { getWeightStatus } from '../commons/heiferWeight';

const ContainerStyle = styled.div`
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.darkShadow};
  border-radius: 5px;
`;

const TopStyle = styled.div`
  padding: 30px;
`;

const HeaderStyle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

const ExtraStyle = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.grey500};

  .item-container {
    display: flex;
    margin-top: 10px;

    .item-group {
      width: 50%;
    }
    .item {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  .extra {
    font-weight: bold;
    color: ${({ theme }) => theme.blueberry500};
  }
`;

const BirthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const BottomStyle = styled.div`
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.grey200};

  .container {
    display: flex;
    margin: 0 -20px;

    > * {
      margin: 0 20px;
    }
  }
`;

const WarningStyle = styled.div`
  padding: 5px 30px;
  background-color: ${({ theme }) => theme.blue500};
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  b {
    font-weight: bold;
  }
`;

const HeiferInfos = ({ heifer, openEditModal, addNoReturnWarning }) => {
  const [isAddEventModalOpen, setAddEventModalOpen] = useState(false);
  const [isDeleteHeiferModalOpen, setDeleteHeiferModalOpen] = useState(false);
  const [sourceDelete, setSourceDelete] = useState(null);
  const { userGroups } = useContext(UserContext);

  const toggleDeleteHeiferModal = source => {
    setDeleteHeiferModalOpen(!isDeleteHeiferModalOpen);
    setSourceDelete(source);
  };

  const birthdate = getEventDate(heifer.events, EVENT_TYPES.BIRTH);

  const hasLabelDead = heifer.is_dead;
  const hasLabelDelegationFinished = heifer.delegation_status === 'RETURNED';
  const hasLabelWeight = heifer.return_estimated_weight;
  const weightStatus = getWeightStatus(heifer.return_estimated_weight);
  const ageAtCalving = getHeiferAgeAtCalving(heifer.events);

  return (
    <>
      <ContainerStyle>
        <TopStyle>
          <HeaderStyle>
            <TitleContainer>
              <H2 purple>Génisse {heifer.id_heifer}</H2>
              {userGroups.includes('admin') && (
                <div data-tip data-for="updateHeiferAlert">
                  <Button
                    secondary
                    small
                    onClick={openEditModal}
                    disabled={heifer.delegation_status !== 'WAITING'}
                  >
                    Modifier
                  </Button>
                </div>
              )}
            </TitleContainer>
            {userGroups.includes('admin') && (
              <div data-tip data-for="deleteHeiferAlert">
                <ButtonLinkIcon
                  icon="delete"
                  disabled={heifer.delegation_status !== 'WAITING'}
                  onClick={() => toggleDeleteHeiferModal(null)}
                >
                  Supprimer la génisse
                </ButtonLinkIcon>
              </div>
            )}
          </HeaderStyle>

          <ExtraStyle>
            <div className="label-container">
              {hasLabelDead && (
                <div>
                  <InfoLabel>
                    Génisse morte le{' '}
                    {getEventDate(heifer.events, EVENT_TYPES.DEATH)}
                  </InfoLabel>
                </div>
              )}
              {hasLabelDelegationFinished && (
                <div>
                  <InfoLabel valid>Délégation terminée</InfoLabel>
                </div>
              )}
              {hasLabelWeight && (
                <div>
                  {weightStatus === WEIGHT_STATUSES.VERY_LOW && (
                    <InfoLabel alert>
                      Poids de retour très insuffisant
                    </InfoLabel>
                  )}
                  {weightStatus === WEIGHT_STATUSES.LOW && (
                    <InfoLabel warning>Poids de retour insuffisant</InfoLabel>
                  )}
                  {weightStatus === WEIGHT_STATUSES.OK && (
                    <InfoLabel great>Poids de retour suffisant</InfoLabel>
                  )}
                </div>
              )}
            </div>

            <div className="item-container">
              <div className="item-group">
                <p className="item">
                  Âge :{' '}
                  <span className="extra">
                    <HeiferAge
                      birthDate={
                        getEventsByType(heifer.events, EVENT_TYPES.BIRTH)[0]
                          .date
                      }
                    />
                  </span>
                </p>
                <p className="item">
                  Née le <span className="extra">{birthdate}</span>
                </p>
              </div>

              <div className="item-group">
                {ageAtCalving && (
                  <p className="item">
                    Âge au vêlage :{' '}
                    <span className="extra">{ageAtCalving} mois</span>
                  </p>
                )}
              </div>
            </div>
          </ExtraStyle>

          <BirthContainer>
            <DefinitionText term="Naisseur" desc={heifer.producer.farm_name} />
            <div>
              <Button
                iconPlus
                disabled={
                  heifer.is_dead || heifer.delegation_status === 'RETURNED'
                }
                onClick={() => setAddEventModalOpen(!isAddEventModalOpen)}
                data-cy="heiferInfos__addEvent"
              >
                {userGroups.includes('admin') || userGroups.includes('breeder')
                  ? 'Ajouter un événement'
                  : 'Conseil accouplement'}
              </Button>
            </div>
          </BirthContainer>
        </TopStyle>

        {heifer.breeder && (
          <BottomStyle>
            <div className="container">
              {getEventsByType(heifer.events, EVENT_TYPES.DELEGATION).length ===
                1 && (
                <DefinitionText
                  term="Date de mise en délégation"
                  desc={getEventDate(heifer.events, EVENT_TYPES.DELEGATION)}
                />
              )}

              <DefinitionText
                term="Délégation"
                desc={heifer.breeder.farm_name}
                extra={
                  heifer.breeder.address &&
                  `${heifer.breeder.address.street}, ${heifer.breeder.address.zip_code} ${heifer.breeder.address.city}`
                }
              />
            </div>
          </BottomStyle>
        )}

        {addNoReturnWarning &&
          (userGroups.includes('breeder') ? (
            <WarningStyle>
              La génisse ne sera pas récupérée par le naisseur :{' '}
              <b>
                vous devez rembourser les acomptes versés dans un délai de 30
                jours
              </b>
            </WarningStyle>
          ) : (
            <WarningStyle>
              La génisse ne sera pas récupérée par le naisseur
            </WarningStyle>
          ))}

        <Modal
          isOpen={isAddEventModalOpen}
          onRequestClose={() => setAddEventModalOpen(!isAddEventModalOpen)}
        >
          {userGroups.includes('admin') || userGroups.includes('breeder') ? (
            <SaveEventForm
              closeModal={() => setAddEventModalOpen(false)}
              heiferId={heifer.id}
              allowRemoveHeiferIfDeath={
                !(heifer.delegation_status !== 'WAITING')
              }
              handleDelete={() => toggleDeleteHeiferModal('edit')}
            />
          ) : (
            <CreateEventBreedingTip
              toggleModal={() => setAddEventModalOpen(!isAddEventModalOpen)}
              heiferId={heifer.id}
            />
          )}
        </Modal>

        <Modal
          isOpen={isDeleteHeiferModalOpen}
          onRequestClose={() => toggleDeleteHeiferModal(null)}
        >
          <RemoveHeiferForm
            toggleModal={() => toggleDeleteHeiferModal(null)}
            heiferId={heifer.id}
            source={sourceDelete}
          />
        </Modal>
      </ContainerStyle>
    </>
  );
};

HeiferInfos.propTypes = {
  openEditModal: PropTypes.func.isRequired,
  heifer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_heifer: PropTypes.string.isRequired,
    producer: PropTypes.shape({ farm_name: PropTypes.string.isRequired }),
    breeder: PropTypes.shape({
      farm_name: PropTypes.string.isRequired,
      address: PropTypes.shape({
        city: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        zip_code: PropTypes.number.isRequired,
      }),
    }),
    events: PropTypes.array,
    is_dead: PropTypes.bool.isRequired,
    delegation_status: PropTypes.string,
    return_estimated_weight: PropTypes.number,
  }).isRequired,
  addNoReturnWarning: PropTypes.bool.isRequired,
};

export default HeiferInfos;
