import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeaderRowStyle from '../../atoms/table/TableHeaderRow';
import CellStyle from '../../atoms/table/TableCell';
import EmptyList from '../EmptyList';
import Item from './Item';

export const TestCell = styled(CellStyle)`
  flex: 3;
  > * {
    font-weight: 700;
  }
`;

const SmallCellStyle = styled(CellStyle)`
  flex: 1;

  > * {
    font-weight: 700;
  }
`;

const TestList = ({ tests, mode }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  return (
    <div>
      <HeaderRowStyle>
        <SmallCellStyle>
          <p>Id</p>
        </SmallCellStyle>
        <SmallCellStyle>
          <p>Label</p>
        </SmallCellStyle>
        <TestCell>
          <p>Description</p>
        </TestCell>
        <TestCell>
          <p>Import</p>
        </TestCell>
      </HeaderRowStyle>
      {tests.length > 0 ? (
        tests.map(test => (
          <Item
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            key={test.id}
            test={test}
            mode={mode}
          />
        ))
      ) : (
        <EmptyList text="Aucun test disponible pour l’instant." />
      )}
    </div>
  );
};

TestList.propTypes = {
  tests: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
};

export default TestList;
