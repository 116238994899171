import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { CREATE_FARM } from '../../graphql/mutations';
import { GET_FARMS } from '../../graphql/queries';
import H2 from '../../atoms/titles/H2';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import chevron from '../../statics/ico/chevron__grey.svg';

export const getInternationalPhoneNumber = phoneNumber =>
  `+33${phoneNumber.toString().slice(-9)}`;

const SubTitleStyle = styled.div`
  display: flex;
  margin: 0 -12px 26px;

  > p {
    position: relative;
    padding: 0px 12px;
    font-size: 12px;
    color: ${({ theme }) => theme.grey100};

    &.active {
      color: ${({ theme }) => theme.grey700};
      font-weight: 700;
    }

    &:first-child:after {
      content: '';
      position: absolute;
      right: -6px;
      background: url(${chevron}) no-repeat center center transparent;
      width: 10px;
      height: 10px;
      transform: rotate(-90deg);
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: 100%;
    }
  }
`;

class CreateFarmForm extends Component {
  state = {
    step: 1,
    firstStepValues: {},
  };

  getMutationVariables = values => {
    const {
      addressCity,
      addressZipCode,
      addressStreet,
      capacityPerPool,
      poolsPerYear,
      delegPerYear,
      role,
      phoneNumber,
      email,
      tagId,
      ...data
    } = values;

    return {
      farm: {
        address: {
          city: addressCity,
          street: addressStreet,
          zip_code: addressZipCode,
        },
        role,
        phone_number: getInternationalPhoneNumber(phoneNumber),
        ...(role === 'breeder' && {
          capacity_per_pool: capacityPerPool,
          pools_per_year: poolsPerYear,
        }),
        ...(role === 'producer' && { deleg_per_year: delegPerYear }),
        ...(email && { email }),
        ...(tagId && { tag_id: tagId }),
        ...data,
      },
    };
  };

  handleOnSubmitFirstStep = values => {
    this.setState({
      firstStepValues: values,
      step: 2,
    });
  };

  // Return the list of availabilities without empty items in array
  // due to a datepicker input without value in the list of availabilities
  getAvailabilities = availabilities =>
    availabilities.filter(e => typeof e === 'string');

  handleCreateFarm = createFarm => async values => {
    const { firstStepValues } = this.state;

    const variables = this.getMutationVariables({
      ...firstStepValues,
      ...values,
      ...('availabilities' in values && {
        availabilities: this.getAvailabilities(values.availabilities),
      }),
    });

    try {
      await createFarm({ variables });
      toast.success("L'exploitation a été créée");
    } catch (err) {
      toast.error(
        "L'exploitation n'a pas été créée à cause d'une erreur technique",
      );
      throw err;
    }
  };

  render = () => {
    const { onRequestClose } = this.props;
    const { step, firstStepValues } = this.state;

    return (
      <Mutation
        mutation={CREATE_FARM}
        update={(cache, { data: { createFarm } }) => {
          const { farms } = cache.readQuery({ query: GET_FARMS });
          cache.writeQuery({
            query: GET_FARMS,
            data: { farms: farms.concat([createFarm]) },
          });
        }}
      >
        {createFarm => (
          <div>
            <H2 blue>Ajouter une exploitation</H2>

            <SubTitleStyle>
              <p className="active">Informations générales</p>
              <p className={step === 2 ? 'active' : ''}>Contrat Délegénisse</p>
            </SubTitleStyle>

            {step === 1 && (
              <StepOne
                onRequestClose={onRequestClose}
                onSubmit={this.handleOnSubmitFirstStep}
              />
            )}

            {step === 2 && (
              <StepTwo
                onRequestClose={onRequestClose}
                onCreateFarm={this.handleCreateFarm(createFarm)}
                role={firstStepValues.role}
              />
            )}
          </div>
        )}
      </Mutation>
    );
  };
}

CreateFarmForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default CreateFarmForm;
