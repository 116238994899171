import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { CHECK_POOL_AVAILABILITIES } from '../graphql/mutations';

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  & > * {
    margin: 0 10px;
  }
`;

class AssignedPoolConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleOnConfirm = async () => {
    const { client, id, toggleModal } = this.props;
    this.setState({ loading: true });
    try {
      const { data } = await client.mutate({
        mutation: CHECK_POOL_AVAILABILITIES,
        variables: { poolId: id },
      });

      if (data.checkPoolAvailabilities) {
        toast.success('La demande de disponibilités a été envoyée');
      } else {
        toast.error(
          "La demande de disponibilités n'a pas été envoyée à cause d'une erreur technique",
        );
      }
      toggleModal();
    } catch (err) {
      this.setState({ loading: false });
      throw err;
    }
  };

  render = () => {
    const { toggleModal } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <H2 blue>Confirmation</H2>
        <p>
          La vérification des disponibilités enverra un sms à l’éleveur et au
          naisseur.
        </p>

        <ButtonBar>
          <Button
            type="button"
            onClick={toggleModal}
            disabled={loading}
            secondary
          >
            Annuler
          </Button>

          <Button
            type="button"
            onClick={this.handleOnConfirm}
            disabled={loading}
          >
            Vérifier les disponibilités
          </Button>
        </ButtonBar>
      </div>
    );
  };
}

AssignedPoolConfirm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
};

export default withApollo(AssignedPoolConfirm);
