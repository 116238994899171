import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Cross from '../statics/ico/cross__red.svg';

const TextAreaStyle = styled.label`
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 7px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
    font-size: 12px;

    &.empty {
      margin-top: 12px;
    }
  }

  textarea {
    color: ${({ theme }) => theme.grey500};
    border: 1px solid ${({ theme }) => theme.grey300};
    padding: 5px 20px 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    transition: ${({ theme }) => theme.transition};
    width: 100%;
    resize: none;

    &:focus {
      border-color: ${({ theme }) => theme.blue500};
      outline: 0;
    }

    &:disabled {
      opacity: 0.4;
    }

    &::placeholder {
      color: ${({ theme }) => theme.grey100};
    }
  }

  .inputContainer {
    display: inline-block;
    width: 100%;
  }

  .withError {
    .inputContainer {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 10px;
        height: 8px;
        width: 8px;
        background: url(${Cross}) no-repeat center center / contain transparent;
      }
    }

    textarea {
      border-color: ${({ theme }) => theme.red600};
    }
  }

  .error {
    font-size: 11px;
    color: ${({ theme }) => theme.red900};
    margin-top: 5px;
    display: block;
  }
`;

const TextArea = ({ name, label, error, className, ...props }) => (
  <TextAreaStyle htmlFor={name} className={className}>
    <span className={`label ${!label ? 'empty' : ''}`}>{label || null}</span>
    <div className={error ? 'withError' : ''}>
      <div className="inputContainer">
        <textarea id={name} name={name} rows="4" {...props} />
      </div>
    </div>
    {error && <span className="error">{error}</span>}
  </TextAreaStyle>
);

TextArea.defaultProps = {
  onChange: () => {},
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default TextArea;
