import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  isPast,
  parseISO,
  setDay,
  formatISO,
  format,
  getYear,
  getMonth,
} from 'date-fns';
import locale from 'date-fns/locale/fr';
import { withApollo } from 'react-apollo';

import Modal from '../../atoms/Modal';
import EmptyList from '../EmptyList';
import ReturnHeifersList from './HeifersList';
import Item from '../PoolWaitingItem';
import Button from '../../atoms/Button';
import MergePoolsConfirm from '../../organisms/MergePoolsConfirm';
import Accordion from '../../atoms/Accordion';
import { stringAttributeComparator } from '../../commons/orderList';
import Select from '../../atoms/Select';
import { GET_RETURNS_DATES } from '../../graphql/queries';

const ListStyle = styled.div`
  overflow: hidden;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
`;

const TopListStyle = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  select {
    margin-right: 20px;
  }
`;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: [],
      isConfirmMergeModalOpen: false,
      returnDateList: [],
    };
  }

  componentDidMount = async () => {
    const { client } = this.props;

    const { data } = await client.query({
      query: GET_RETURNS_DATES,
      fetchPolicy: 'network-only',
      variables: {},
    });
    this.setState({
      returnDateList: this.addCurrentDateIfMissing(data.returnDates),
    });
  };

  addCurrentDateIfMissing = returnDates => {
    const currentYear = getYear(new Date());
    const currentMonth = getMonth(new Date());

    const isContainingCurrentMonth = returnDates.some(({ year, month }) => {
      return year === currentYear && month === currentMonth;
    });
    if (!isContainingCurrentMonth) {
      returnDates.push({ year: currentYear, month: currentMonth });
    }

    return returnDates;
  };

  handleSelectedPool = id => {
    const { selectedList } = this.state;

    if (selectedList.includes(id))
      return this.setState({
        selectedList: selectedList.filter(idOpened => idOpened !== id),
      });

    return this.setState({ selectedList: [...selectedList, id] });
  };

  toggleConfirmMergeModal = () => {
    this.setState(prevState => ({
      isConfirmMergeModalOpen: !prevState.isConfirmMergeModalOpen,
    }));
  };

  successMergePools = () => {
    this.toggleConfirmMergeModal();
    return this.setState({ selectedList: [] });
  };

  getTransferDate = (date, dayOfWeek) => {
    let resultDate = new Date(parseISO(date));

    resultDate = setDay(resultDate, dayOfWeek);

    return formatISO(resultDate);
  };

  buildOptions = choices => {
    return choices.map(({ year, month }) => {
      const date = new Date(year, month);

      return {
        value: `${month} ${year}`,
        label: format(date, 'MMMM yyyy', { locale }),
      };
    });
  };

  render = () => {
    const { pools, onChange, selectedMonth, selectedYear } = this.props;
    const {
      selectedList,
      isConfirmMergeModalOpen,
      returnDateList,
    } = this.state;

    const sortedPools = pools.sort((pool1, pool2) => {
      return stringAttributeComparator('farm_name')(
        pool1.breeder,
        pool2.breeder,
      );
    });

    return (
      <>
        <TopListStyle>
          <Select
            name="heiferReturnDate"
            onChange={onChange}
            value={`${selectedMonth} ${selectedYear}`}
          >
            {this.buildOptions(returnDateList).map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <Button
            onClick={this.toggleConfirmMergeModal}
            disabled={selectedList.length < 2}
          >
            Fusionner les {selectedList.length > 1 ? selectedList.length : ''}{' '}
            lots
          </Button>
        </TopListStyle>

        {sortedPools.length > 0 ? (
          sortedPools.map(pool => {
            const isSelected = !!selectedList.includes(pool.id);
            const activeButtons =
              pool.heifers.length > 0 && !isPast(parseISO(pool.transfer_date));

            return (
              <ListStyle key={pool.id}>
                <Accordion
                  visible={({ toggleAccordion, isOpened }) => (
                    <Item
                      onShow={toggleAccordion}
                      opened={isOpened}
                      selected={isSelected}
                      poolId={pool.id}
                      poolIdToShow={pool.id_pool}
                      transferDate={this.getTransferDate(pool.transfer_date, 4)}
                      farmName={pool.breeder.farm_name}
                      farmId={pool.breeder.id}
                      heifersLength={pool.heifers.length}
                      poolType="RETURN"
                      activateButtons={activeButtons}
                      onSelectPool={() => this.handleSelectedPool(pool.id)}
                      lastPoolAvailabilitiesNotification={
                        pool.last_pool_availabilities_notification
                      }
                    />
                  )}
                  expandable={
                    <ReturnHeifersList
                      heifers={pool.heifers}
                      breeder={pool.breeder}
                    />
                  }
                />
              </ListStyle>
            );
          })
        ) : (
          <EmptyList text="Il n’y a pas de lots à affecter pour l’instant." />
        )}
        <Modal
          isOpen={isConfirmMergeModalOpen}
          onRequestClose={this.toggleConfirmMergeModal}
        >
          <MergePoolsConfirm
            ids={selectedList}
            toggleModal={this.toggleConfirmMergeModal}
            successCallback={this.successMergePools}
          />
        </Modal>
      </>
    );
  };
}

List.propTypes = {
  pools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
};

export default withApollo(List);
