import { formErrorMsgs } from '../constants';

// Translate a GraphQL error code to a message
export const getCustomError = (errors, value) => {
  if (errors) {
    const error = errors.graphQLErrors.find(err => err.message === value);
    if (error)
      switch (value) {
        case 'EXISTING_HEIFER_ID':
          return formErrorMsgs.exitingHeiferId;
        case 'EXISTING_HEIFER_NEW_ID':
          return formErrorMsgs.exitingHeiferId;
        case 'EXISTING_EDE_NUMBER':
          return formErrorMsgs.exitingEdeNumber;
        case 'HEIFER_DEAD':
          return 'morte';
        default:
          return null;
      }
  }
  return null;
};

export default getCustomError;
