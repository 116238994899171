import React, { memo } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CellStyle from '../../atoms/table/TableCell';
import ContentRowStyle from '../../atoms/table/TableContentRow';
import { ReactComponent as Chevron } from '../../statics/ico/chevron__grey.svg';
import { getPoolStatus } from '../../commons/pools';
import InfoLabel from '../../atoms/InfoLabel';

const IdCellStyle = styled(CellStyle)`
  flex: 1;

  > * {
    font-weight: 700;
  }
`;

const SmallCellStyle = styled(CellStyle)`
  flex: 1;
`;

const BreederCellStyle = styled(CellStyle)`
  flex: 4;
`;

const HeiferButton = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    transform: rotate(${({ isOpened }) => (isOpened ? '180deg' : '0deg')});
    transition: ${({ theme }) => theme.transition};
  }
`;

const Item = ({ pool, isOpened, toggleAccordion }) => {
  const status = getPoolStatus(pool);

  return (
    <ContentRowStyle to={`/transfer/returns/${pool.id}`}>
      <IdCellStyle>
        <span>{pool.id_pool}</span>
      </IdCellStyle>
      <SmallCellStyle>
        <span>
          {pool.heifers.length} génisse
          {pool.heifers.length > 1 ? 's' : ''}
        </span>
      </SmallCellStyle>
      <BreederCellStyle>
        <span>{pool.breeder.farm_name}</span>
      </BreederCellStyle>
      <SmallCellStyle>
        {pool.transfer_date && (
          <span>{format(parseISO(pool.transfer_date), 'dd/MM/yyyy')}</span>
        )}
      </SmallCellStyle>
      <SmallCellStyle>
        {pool.heifers && (
          <HeiferButton
            isOpened={isOpened}
            onClick={e => {
              e.preventDefault();
              toggleAccordion();
            }}
          >
            <InfoLabel
              warning={status === 'en attente'}
              valid={status === 'en cours'}
              noMargin
            >
              {status}
            </InfoLabel>
            <Chevron />
          </HeiferButton>
        )}
      </SmallCellStyle>
    </ContentRowStyle>
  );
};

Item.propTypes = {
  pool: PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_pool: PropTypes.string.isRequired,
    heifers: PropTypes.array.isRequired,
    transfer_date: PropTypes.string.isRequired,
    breeder: PropTypes.shape({
      farm_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isOpened: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
};

export default memo(Item);
