import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { getMonth, getYear } from 'date-fns';
import styled from 'styled-components';
import Loader from '../atoms/Loader';
import { filterPoolsWithReturnableHeifers } from '../commons/pools';

import { GET_POOLS } from '../graphql/queries';
import ContainerLarge from '../layouts/ContainerLarge';
import PageHeader from '../molecules/PageHeader';
import List from '../molecules/ReturnPoolWaitingList';
import { ReactComponent as MapPinIcon } from '../statics/ico/map-pin.svg';

const HomeReturnStyle = styled.div`
  display: flex;
  height: 100vh;

  > section {
    width: 100%;
  }
`;

const ReturnPoolsDashboard = () => {
  const [state, setState] = useState({
    selectedMonth: getMonth(new Date()),
    selectedYear: getYear(new Date()),
    returnDateList: [],
  });

  const handleChangeSelectedDate = event => {
    const values = event.target.value.split(' ');
    setState({
      selectedMonth: parseInt(values[0], 10),
      selectedYear: parseInt(values[1], 10),
    });
  };
  return (
    <Query
      query={GET_POOLS}
      fetchPolicy="network-only"
      variables={{
        type: 'RETURN',
        status: ['CREATED'],
        month: state.selectedMonth,
        year: state.selectedYear,
      }}
    >
      {({ loading, error, data }) => {
        if (loading && Object.keys(data).length === 0) return <Loader />;
        if (error) throw error;

        const pools = filterPoolsWithReturnableHeifers(data.pools);

        return (
          <HomeReturnStyle>
            <ContainerLarge>
              <PageHeader title="Retours à programmer" icon={<MapPinIcon />} />

              <List
                pools={pools}
                selectedMonth={state.selectedMonth}
                selectedYear={state.selectedYear}
                onChange={handleChangeSelectedDate}
              />
            </ContainerLarge>
          </HomeReturnStyle>
        );
      }}
    </Query>
  );
};

export default ReturnPoolsDashboard;
