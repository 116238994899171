import { differenceInDays, isFuture, parseISO } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Loader from '../atoms/Loader';
import H2 from '../atoms/titles/H2';

import { formatDate } from '../commons/dates';
import { getCustomError } from '../commons/graphqlError';
import { formErrorMsgs } from '../constants';
import { CREATE_HEIFER, UPDATE_HEIFER } from '../graphql/mutations';
import {
  GET_DELEGATION_DASHBOARD,
  GET_FARM,
  GET_FARM_ADMIN,
  GET_FARMS_BY_ROLE,
  GET_HEIFERS,
} from '../graphql/queries';
import {
  FormikDatePickerArea,
  FormikInput,
  FormikSelect,
} from '../molecules/Formik';

const CreateHeiferModalStyle = styled.div`
  .farmName {
    font-size: 16px;
    margin-bottom: 12px;
    text-transform: capitalize;
  }
`;

const FormStyle = styled(Form)`
  .form__row {
    display: flex;

    > * {
      flex: 1 0 20%;
      margin: 10px 0;
    }
  }

  .form__row--small {
    max-width: 200px;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }

  .form__row--optionnal {
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px;
  }

  .form__item {
    max-height: auto;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    &--hidden {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
`;

const FormInputIdContainerStyle = styled.div`
  display: flex;
  align-items: flex-start;

  .idHeifer {
    transform: translateX(-5px);

    .inputContainer {
      width: auto;
    }
  }

  input {
    max-width: 90px;
  }
`;

const formSchema = farmId =>
  Yup.object().shape({
    id: Yup.string().test(
      'composed of digits and length of 4',
      formErrorMsgs.lengthFourDigits,
      val => val && /^\d+$/.test(val) && val.length === 4,
    ),
    birthdate: Yup.date().required(formErrorMsgs.required),
    producer: farmId
      ? Yup.string()
      : Yup.string().required(formErrorMsgs.required),
  });

class CreateHeiferForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorHeiferId: null,
      tagId: null,
    };
  }

  componentDidMount = () => {
    const { tagId } = this.props;

    if (tagId) {
      this.setState({ tagId });
    }
  };

  getInitialValues = () => {
    const { initialValues } = this.props;
    return {
      ...initialValues,
      id: initialValues.id.slice(6, 10),
    };
  };

  render = () => {
    const {
      toggleModal,
      initialValues,
      farmId,
      farmName,
      isUpdate,
      source,
    } = this.props;
    const { errorHeiferId, tagId } = this.state;

    return (
      <Mutation mutation={UPDATE_HEIFER}>
        {(updateHeifer, updateHeiferData) => (
          <Mutation
            mutation={CREATE_HEIFER}
            refetchQueries={[
              farmId && {
                query: source === 'admin' ? GET_FARM_ADMIN : GET_FARM,
                variables: { id: farmId },
              },
              !farmId && {
                query: GET_HEIFERS,
                variables: { inDelegation: false },
              },
            ].filter(f => f)}
          >
            {(createHeifer, { error, client }) => (
              <CreateHeiferModalStyle>
                {farmName && <p className="farmName">{farmName}</p>}
                <H2 blue>{isUpdate ? 'Modifier' : 'Ajouter'} une génisse</H2>

                <Formik
                  initialValues={this.getInitialValues()}
                  validationSchema={formSchema(farmId)}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      const { id, producer, birthdate } = values; // birthdate is a moment date

                      if (!isUpdate) {
                        await createHeifer({
                          variables: {
                            heifer: {
                              id_heifer: `${tagId}${id}`,
                              id_producer: farmId || producer,
                              birthdate: formatDate(parseISO(birthdate)),
                            },
                          },
                        });
                      } else {
                        await updateHeifer({
                          variables: {
                            heifer: {
                              prev_id_heifer: initialValues.id,
                              id_heifer: `${tagId}${id}`,
                              id_producer: farmId || producer,
                              birthdate: formatDate(parseISO(birthdate)),
                            },
                          },
                        });
                      }

                      // Hacky way to synchronously wait 4 seconds if on the dashboard
                      // (i.e. farmId props not defined) before performing an update of
                      // the delegation query in order to check if a new delegation has been created
                      if (!farmId) {
                        await new Promise(resolve =>
                          setTimeout(async () => {
                            await client.query({
                              query: GET_DELEGATION_DASHBOARD,
                              fetchPolicy: 'network-only',
                            });
                            resolve();
                          }, 4000),
                        );
                      }

                      resetForm(this.getInitialValues());
                      toggleModal();

                      toast.success(
                        `La génisse a été ${isUpdate ? 'modifiée' : 'ajoutée'}`,
                      );
                    } catch (err) {
                      if (getCustomError(err, 'EXISTING_HEIFER_ID')) {
                        this.setState({ errorHeiferId: values.id });
                      } else {
                        toast.error(
                          `La génisse n'a pas été ${
                            isUpdate ? 'modifiée' : 'ajoutée'
                          } à cause d'une erreur technique`,
                        );
                        throw err;
                      }
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ resetForm, isSubmitting, values, setFieldValue }) => (
                    <FormStyle>
                      {(!farmId || isUpdate) && (
                        <div className="form__row">
                          <Query
                            query={GET_FARMS_BY_ROLE}
                            variables={{ role: 'producer' }}
                          >
                            {producers => {
                              if (producers.loading) return <Loader inline />;
                              if (producers.error)
                                return `Error! ${producers.error.message}`;

                              return (
                                <Field
                                  name="producer"
                                  placeholder="Naisseur"
                                  label="Naisseur"
                                  component={FormikSelect}
                                  onChange={({ target }) => {
                                    setFieldValue('producer', target.value);
                                    this.setState({
                                      tagId: producers.data.farms.find(
                                        farm => farm.id === target.value,
                                      ).tag_id,
                                    });
                                  }}
                                >
                                  {producers.data.farms
                                    .sort((farm1, farm2) => {
                                      return farm1.farm_name.localeCompare(
                                        farm2.farm_name,
                                      );
                                    })
                                    .map(farm => (
                                      <option key={farm.id} value={farm.id}>
                                        {farm.farm_name}
                                      </option>
                                    ))}
                                </Field>
                              );
                            }}
                          </Query>
                        </div>
                      )}

                      {tagId && (
                        <>
                          <div
                            className="form__row form__row--small"
                            data-cy="createHeiferForm__birthdate"
                          >
                            <Field
                              name="birthdate"
                              label="Date de naissance"
                              outsideRange={day => this.isDayOutsideRange(day)}
                              component={FormikDatePickerArea}
                            />
                          </div>
                          <FormInputIdContainerStyle>
                            <Input
                              label="Numéro de génisse"
                              name="tagId"
                              type="text"
                              value={tagId}
                              disabled
                            />
                            <Field
                              type="text"
                              name="id"
                              component={FormikInput}
                              className="idHeifer"
                              customError={
                                values.id === errorHeiferId
                                  ? getCustomError(
                                    error || updateHeiferData.error,
                                    'EXISTING_HEIFER_ID',
                                  )
                                  : null
                              }
                            />
                          </FormInputIdContainerStyle>
                        </>
                      )}

                      <div className="buttonBar">
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            resetForm(this.getInitialValues());
                            toggleModal();
                          }}
                          secondary
                        >
                          Annuler
                        </Button>
                        <Button
                          type="submit"
                          data-cy="CreateHeiferForm__submit"
                          disabled={isSubmitting}
                        >
                          {isUpdate ? 'Modifier' : 'Ajouter'}
                        </Button>
                      </div>
                    </FormStyle>
                  )}
                </Formik>
              </CreateHeiferModalStyle>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  };

  isDayOutsideRange = day => {
    const dayAsDate = day.toDate();

    const { isAdmin, isBio } = this.props;
    const isDayInFuture = isFuture(dayAsDate);
    const isDayTooOld = this.isDateMoreThanXMonthsAgo(dayAsDate, isBio ? 5 : 2);

    return isDayInFuture || (!isAdmin && isDayTooOld);
  };

  isDateMoreThanXMonthsAgo = (date, nbOfMonths) => {
    const nbDaysFromToday = differenceInDays(new Date(), date);

    return nbDaysFromToday > nbOfMonths * 30;
  };
}

CreateHeiferForm.defaultProps = {
  farmId: null,
  farmName: null,
  tagId: null,
  isAdmin: false,
  isUpdate: false,
  isBio: false,
  initialValues: {
    id: '',
    birthdate: '',
    producer: '',
  },
  source: 'admin',
};

CreateHeiferForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  farmId: PropTypes.string,
  farmName: PropTypes.string,
  tagId: PropTypes.number,
  isUpdate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isBio: PropTypes.bool,
  source: PropTypes.oneOf(['producer', 'admin']),
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    producer: PropTypes.string.isRequired,
  }),
};

export default CreateHeiferForm;
