import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import { parse, parseISO } from 'date-fns';

import { getEventsByType, heiferCurrentStatus } from '../commons/heiferEvents';
import Modal from '../atoms/Modal';
import CreateHeiferForm from './CreateHeiferForm';
import { GET_HEIFER, GET_POOLS } from '../graphql/queries';
import ContainerSmall from '../layouts/ContainerSmall';
import HeiferEvents from '../molecules/HeiferEvents';
import AskForReturn from '../molecules/AskForReturn';
import HeiferInfos from '../molecules/HeiferInfos';
import Loader from '../atoms/Loader';
import UserContext from '../context';
import { EVENT_TYPES } from '../constants';

const HeiferInfosStyle = styled.section`
  margin-bottom: 40px;
`;

const HeiferDetails = ({ heiferId, client }) => {
  const [isEditHeiferModalOpen, setEditHeiferModal] = useState(false);
  const [pool, setPool] = useState(null);

  useEffect(() => {
    async function getPool() {
      const { data } = await client.query({
        query: GET_POOLS,
        variables: { type: 'DELEGATION' },
      });
      const pool = data.pools.find(({ heifers }) => {
        return heifers.some(h => h && h.id === heiferId);
      });
      if (pool) setPool(pool);
    }
    getPool();
  }, [heiferId, client]);

  const { userGroups } = useContext(UserContext);
  const isAdmin = userGroups.includes('admin');

  if (!heiferId) return <Loader />;

  return (
    <Query query={GET_HEIFER} variables={{ id: heiferId }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(`Error! ${error.message}`);
        const { heifer, billings } = data;

        if (heifer === null) {
          throw new Error(`Error! Unknown heifer "${heiferId}"!`);
        }

        const billingType =
          heifer.producer.farm_type === 'BIO' ? 'BIO' : 'CONV';

        const date = pool !== null ? parseISO(pool.transfer_date) : new Date();

        const billing = billings.filter(
          billing =>
            billing.billing_type === billingType &&
            parse(billing.start_date, 'dd/MM/yyyy', new Date()) < date &&
            parse(billing.end_date, 'dd/MM/yyyy', new Date()) > date,
        );
        const ageMiniNoReturn = billing ? billing[0].no_return : 30;

        const status = heiferCurrentStatus(heifer.events, ageMiniNoReturn);

        const birthDate = getEventsByType(heifer.events, EVENT_TYPES.BIRTH)[0]
          .date;
        const disableEvents =
          heifer.is_dead || heifer.delegation_status === 'RETURNED';

        const shouldRenderAskForReturn = () => {
          const canHeiferBeReturned = !status.dead && status.noReturn === null;
          const isUserAllowedToAskForIt =
            userGroups.includes('producer') || isAdmin;
          const isHeiferReady =
            status.oldHeiferAtCalving ||
            (!status.inseminated && status.ageInMonths > 21);

          return (
            canHeiferBeReturned && isUserAllowedToAskForIt && isHeiferReady
          );
        };

        return (
          <div>
            <ContainerSmall displayHeader color="purple">
              <HeiferInfosStyle>
                <HeiferInfos
                  openEditModal={() => setEditHeiferModal(true)}
                  heifer={heifer}
                  addNoReturnWarning={!!status.noReturn}
                />
              </HeiferInfosStyle>

              {shouldRenderAskForReturn() && (
                <AskForReturn
                  heiferId={heiferId}
                  ageMiniNoReturn={ageMiniNoReturn}
                />
              )}

              <HeiferEvents
                idHeifer={heifer.id}
                isDisabled={disableEvents}
                returnEstimatedWeight={heifer.return_estimated_weight}
                events={heifer.events}
              />
            </ContainerSmall>

            <Modal
              isOpen={isEditHeiferModalOpen}
              onRequestClose={() => setEditHeiferModal(false)}
            >
              <CreateHeiferForm
                toggleModal={() => setEditHeiferModal(false)}
                farmId={heifer.producer.id}
                tagId={heifer.producer.tag_id}
                initialValues={{
                  id: heifer.id_heifer,
                  birthdate: birthDate,
                  producer: heifer.producer.id,
                }}
                isUpdate
                isAdmin={isAdmin}
              />
            </Modal>
          </div>
        );
      }}
    </Query>
  );
};

HeiferDetails.propTypes = {
  heiferId: PropTypes.string,
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
};

HeiferDetails.defaultProps = {
  heiferId: null,
};

export default withApollo(HeiferDetails);
