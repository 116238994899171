import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from '../statics/ico/plus.svg';

const ButtonStyle = styled.button`
  display: flex;
  align-items: center;
  font-size: ${({ small }) => (small && '12px') || '14px'};
  font-weight: 700;
  color: ${({ secondary, tertiary, theme }) =>
    ((secondary || tertiary) && theme.blue500) || '#fff'};
  background-color: ${({ theme, secondary, tertiary }) =>
    (secondary && theme.blue300) || (tertiary && '#fff') || theme.blue500};
  cursor: pointer;
  padding: ${({ small }) => (small && '1px 8px') || '6px 19px'};
  border-radius: 5px;
  transition: ${({ theme }) => theme.transition};
  border: ${({ tertiary, theme }) =>
    (tertiary && `1px solid ${theme.blue500}`) || 'none'};

  &:hover {
    background-color: ${({ secondary, tertiary, theme }) =>
      (secondary && theme.blue400) ||
      (tertiary && theme.blue500) ||
      theme.blue700};
    color: ${({ secondary, theme }) => (secondary && theme.blue500) || '#fff'};
    border: ${({ tertiary }) => (tertiary && `1px solid #fff`) || 'none'};
  }

  &:disabled {
    background-color: ${({ theme, tertiary }) =>
      (tertiary && theme.blue500) || theme.grey300};
    color: ${({ tertiary }) =>
      (tertiary && '#fff') || 'rgba(255, 255, 255, 0.6)'};
    cursor: default;
  }

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: -2px;

    * {
      stroke: #fff;
    }
  }
`;

const Button = ({ children, iconPlus, ...props }) => (
  <ButtonStyle {...props}>
    {iconPlus ? (
      <>
        <PlusIcon className="icon" />
        <span>{children}</span>
      </>
    ) : (
      <>{children}</>
    )}
  </ButtonStyle>
);

Button.defaultProps = {
  iconPlus: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  iconPlus: PropTypes.bool,
};

export default Button;
