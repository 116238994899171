import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as Infos } from '../statics/ico/info.svg';

const EmptyListStyle = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .emptyList__picto {
    > svg {
      width: 20px;
      height: 20px;

      * {
        fill: ${({ theme }) => theme.grey100};
      }
    }
  }

  .emptyList__text {
    margin-top: 15px;
    color: ${({ theme }) => theme.grey100};
  }
`;

const EmptyList = ({ text }) => (
  <EmptyListStyle>
    <div className="emptyList__picto">
      <Infos />
    </div>
    <p className="emptyList__text">{text}</p>
  </EmptyListStyle>
);

EmptyList.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EmptyList;
