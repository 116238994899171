import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeiferWaitingItem from './Item';
import EmptyList from '../EmptyList';
import CellStyle from '../../atoms/table/TableCell';
import {
  orderByEventDate,
  orderByAttribut,
  orderByDate,
} from '../../commons/orderList';

const HeiferCellStyle = styled(CellStyle)`
  flex: 3;
`;

const DateCellStyle = styled(CellStyle)`
  flex: 1;
  text-align: end;
`;

const TypeCellStyle = styled(CellStyle)`
  flex: 2;
`;

const HeifersWaitingListStyle = styled.div`
  display: flex;
  flex-direction: column;

  .headerRow {
    display: flex;
    margin-right: 12px;
    color: ${({ theme }) => theme.grey700};
  }
`;

class HeifersWaitingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDirection: 'asc',
      orderValue: 'delegation_availability',
    };
  }

  getOrderedList = () => {
    const { orderValue, orderDirection } = this.state;
    const { heifers } = this.props;
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'birth':
        return orderByEventDate(heifers, orderStatement, 'BIRTH');
      case 'delegation_availability':
        return orderByDate(heifers, orderValue, orderStatement);
      default:
        return orderByAttribut(heifers, orderValue, orderStatement);
    }
  };

  toggleOrder = orderValue => {
    const { orderDirection } = this.state;

    this.setState({
      orderValue,
      orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  render = () => {
    const { heifers, onHeiferSelected, heifersSelected } = this.props;
    const { orderValue, orderDirection } = this.state;

    return (
      <HeifersWaitingListStyle>
        <div className="headerRow">
          <CellStyle />
          <HeiferCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'id_heifer' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('id_heifer')}
            >
              Génisse
            </button>
          </HeiferCellStyle>
          <TypeCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'farm_type' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('farm_type')}
            >
              Type
            </button>
          </TypeCellStyle>
          <DateCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'birth' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('birth')}
            >
              Naissance
            </button>
          </DateCellStyle>
          <DateCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'delegation_availability'
                  ? `active ${orderDirection}`
                  : ''
              }`}
              onClick={() => this.toggleOrder('delegation_availability')}
            >
              Dispo.
            </button>
          </DateCellStyle>
        </div>
        <div>
          {heifers.length > 0 ? (
            this.getOrderedList().map(heifer => (
              <HeiferWaitingItem
                key={heifer.id}
                heifer={heifer}
                onSelected={() => onHeiferSelected(heifer.id)}
                isSelected={heifersSelected.includes(heifer.id)}
              />
            ))
          ) : (
            <EmptyList text="Il n’y a pas de génisses en attente pour l’instant. " />
          )}
        </div>
      </HeifersWaitingListStyle>
    );
  };
}

HeifersWaitingList.propTypes = {
  onHeiferSelected: PropTypes.func.isRequired,
  heifers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      id_heifer: PropTypes.string,
      producer: PropTypes.shape({ farm_name: PropTypes.string }),
      events: PropTypes.array,
      delegation_availability: PropTypes.string.isRequired,
    }),
  ).isRequired,
  heifersSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default HeifersWaitingList;
