import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { compareAsc, format, parseISO } from 'date-fns';

import { FormikSelect } from './Formik';
import Button from '../atoms/Button';

const HeifersWaitingHeaderStyle = styled.section`
  margin-bottom: 10px;
`;

const FormStyle = styled(Form)`
  display: flex;

  button[type='submit'] {
    margin-left: 10px;
  }
`;

const HeifersWaitingHeader = ({
  pools,
  onFormSubmit,
  lengthHeifersSelected,
}) => (
  <HeifersWaitingHeaderStyle>
    <Formik
      initialValues={{
        poolId: '',
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await onFormSubmit(values.poolId);

        setSubmitting(false);
        resetForm({ poolId: '' });
      }}
    >
      {({ isSubmitting, values }) => (
        <FormStyle>
          <Field
            label="Ajouter à"
            name="poolId"
            placeholder="Lot"
            component={FormikSelect}
            labelNextTo
          >
            {pools
              .filter(
                pool => pool.heifers.length < pool.breeder.capacity_per_pool,
              )
              .sort((a, b) =>
                compareAsc(
                  parseISO(a.transfer_date),
                  parseISO(b.transfer_date),
                ),
              )
              .map(pool => (
                <option key={pool.id} value={pool.id}>
                  {`${pool.breeder.farm_name} - ${format(
                    parseISO(pool.transfer_date),
                    'dd/MM/yyyy',
                  )}`}
                </option>
              ))}
          </Field>
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              values.poolId === '' ||
              lengthHeifersSelected === 0
            }
          >
            Ajouter
          </Button>
        </FormStyle>
      )}
    </Formik>
  </HeifersWaitingHeaderStyle>
);

HeifersWaitingHeader.defaultProps = {
  pools: null,
};

HeifersWaitingHeader.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  lengthHeifersSelected: PropTypes.number.isRequired,
  pools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      heifers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};

export default HeifersWaitingHeader;
