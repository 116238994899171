import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { IS_EDE_NUMBER_EXISTING } from '../../graphql/mutations';
import { formErrorMsgs } from '../../constants';
import { FormikInput, FormikSelect } from '../../molecules/Formik';
import Button from '../../atoms/Button';
import Tooltip from '../../atoms/Tooltip';
import { ReactComponent as InfoIcon } from '../../statics/ico/info_grey.svg';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .form__section:not(:last-child) {
    margin-bottom: 20px;
  }

  .form__item--extrasmall {
    flex: 0 0 20%;
  }

  .form__item--small {
    flex: 0 0 30%;
  }

  .buttonsBar {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > * {
      margin: 0 10px;
    }
  }

  .form__item--email {
    display: flex;
    align-items: flex-start;

    > label {
      flex: 1 0 0%;
    }

    .infoIcon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: 25px;
    }
  }
`;

const initialValues = {
  name: '',
  farm_name: '',
  role: '',
  addressStreet: '',
  addressCity: '',
  addressZipCode: '',
  email: '',
  phoneNumber: '',
  farm_type: 'CONVENTIONNEL',
  ede_number: '',
  intracommunity_number: '',
  tagId: '',
};

const formSchema = Yup.object().shape({
  farm_name: Yup.string().required(formErrorMsgs.required),
  name: Yup.string().required(formErrorMsgs.required),
  role: Yup.string().required(formErrorMsgs.required),
  addressStreet: Yup.string().required(formErrorMsgs.required),
  addressCity: Yup.string().required(formErrorMsgs.required),
  addressZipCode: Yup.number()
    .min(10000, formErrorMsgs.lengthFiveDigits)
    .max(99999, formErrorMsgs.lengthFiveDigits)
    .required(formErrorMsgs.required),
  phoneNumber: Yup.string().required(formErrorMsgs.required),
  farm_type: Yup.string(),
  email: Yup.string(),
  ede_number: Yup.number()
    .min(10000000, formErrorMsgs.lengthHeightDigits)
    .max(99999999, formErrorMsgs.lengthHeightDigits)
    .required(formErrorMsgs.required),
  intracommunity_number: Yup.string().required(formErrorMsgs.required),
  tagId: Yup.number().when('role', {
    is: 'producer',
    then: Yup.number()
      .min(100000, formErrorMsgs.lengthSixDigits)
      .max(999999, formErrorMsgs.lengthSixDigits)
      .required(formErrorMsgs.required),
  }),
});

class StepOne extends Component {
  state = {
    errorEdeNumber: null,
  };

  render = () => {
    const { onRequestClose, onSubmit } = this.props;
    const { errorEdeNumber } = this.state;

    return (
      <Mutation mutation={IS_EDE_NUMBER_EXISTING}>
        {(isExistingEdeNumber, { data }) => (
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const res = await isExistingEdeNumber({
                variables: { ede_number: values.ede_number },
              });
              if (!res.data.isExistingEdeNumber) {
                setSubmitting(false);
                resetForm(initialValues);
                onSubmit(values);
              } else {
                this.setState({ errorEdeNumber: values.ede_number });
                setSubmitting(false);
              }
            }}
          >
            {({ resetForm, isSubmitting, values }) => (
              <FormStyle>
                <div className="form__section">
                  <div className="form__row">
                    <Field
                      type="text"
                      name="farm_name"
                      placeholder="Nom de l'exploitation"
                      label="Exploitation"
                      component={FormikInput}
                    />
                  </div>
                  <div className="form__row">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Nom de l'exploitant"
                      label="Exploitant"
                      component={FormikInput}
                      className="form__item"
                    />

                    <div className="form__item form__item--small">
                      <Field
                        name="role"
                        placeholder="Rôle"
                        component={FormikSelect}
                        label="Rôle"
                      >
                        <option value="producer">Naisseur</option>
                        <option value="breeder">Éleveur</option>
                      </Field>
                    </div>
                  </div>
                  <div className="form__row">
                    <Field
                      type="text"
                      name="farm_type"
                      placeholder="Choisissez"
                      label="Type d'élevage"
                      component={FormikSelect}
                      className="form__item"
                    >
                      <option value="BIO">BIO</option>
                      <option value="CONVENTIONNEL">CONVENTIONNEL</option>
                    </Field>
                  </div>
                </div>

                <div className="form__section">
                  <div className="form__row">
                    <Field
                      type="text"
                      name="addressStreet"
                      placeholder="Rue, Lieu-dit"
                      label="Adresse"
                      component={FormikInput}
                    />
                    <div className="form__item--extrasmall">
                      <Field
                        type="number"
                        name="addressZipCode"
                        placeholder="Code postal"
                        label="Code postal"
                        component={FormikInput}
                      />
                    </div>
                    <Field
                      type="text"
                      name="addressCity"
                      placeholder="Ville"
                      label="Ville"
                      component={FormikInput}
                    />
                  </div>

                  <div className="form__row">
                    <Field
                      type="number"
                      name="phoneNumber"
                      placeholder="N° de téléphone mobile"
                      label="N° de téléphone mobile"
                      component={FormikInput}
                    />
                    <div className="form__item--email">
                      <InfoIcon
                        data-tip
                        data-for="emailInfos"
                        className="infoIcon"
                      />
                      <Field
                        type="text"
                        name="email"
                        placeholder="Adresse email"
                        label="Adresse email"
                        component={FormikInput}
                      />
                      <Tooltip id="emailInfos">
                        L’adresse mail est conseillée pour la réception de
                        <br />
                        toutes les informations relatives au service pour
                        <br />
                        l’exploitant.
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="form__section">
                  <div className="form__row">
                    <Field
                      type="text"
                      name="intracommunity_number"
                      placeholder="N° Intracommunautaire"
                      label="N° Intracommunautaire"
                      component={FormikInput}
                    />
                    <Field
                      type="number"
                      name="ede_number"
                      placeholder="N° EDE"
                      label="N° EDE"
                      component={FormikInput}
                      customError={
                        data &&
                        data.isExistingEdeNumber &&
                        values.ede_number === errorEdeNumber
                          ? formErrorMsgs.exitingEdeNumber
                          : null
                      }
                    />
                    {values.role === 'producer' && (
                      <Field
                        type="number"
                        name="tagId"
                        placeholder="N° boucle"
                        label="N° boucle"
                        component={FormikInput}
                      />
                    )}
                  </div>
                </div>

                <div className="buttonsBar">
                  <Button
                    type="button"
                    onClick={() => {
                      resetForm(initialValues);
                      onRequestClose();
                    }}
                    disabled={isSubmitting}
                    secondary
                  >
                    Annuler
                  </Button>

                  <Button type="submit" disabled={isSubmitting}>
                    Suivant
                  </Button>
                </div>
              </FormStyle>
            )}
          </Formik>
        )}
      </Mutation>
    );
  };
}

StepOne.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StepOne;
