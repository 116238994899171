import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import ContainerSmall from '../layouts/ContainerSmall';
import { GET_FARM_DETAILS } from '../graphql/queries';
import FarmDelegationInfos from '../molecules/FarmDelegationInfos';
import FarmInfos from '../molecules/FarmInfos';
import FarmAvailabilities from '../molecules/FarmAvailabilities';
import PageHeader from '../molecules/PageHeader';
import HeifersList from '../molecules/HeifersList';
import Loader from '../atoms/Loader';

const FarmDetailsStyle = styled.div`
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
`;

const FarmAvailabilitiesStyle = styled.section`
  margin-bottom: 30px;
`;

const FarmDetails = ({ farmId }) => (
  <Query
    query={GET_FARM_DETAILS}
    fetchPolicy="network-only"
    variables={{ id: farmId }}
    skip={!farmId}
  >
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error) throw new Error(error.message);

      const { farm } = data;

      return (
        <ContainerSmall displayHeader color="grey">
          <FarmDetailsStyle>
            <FarmInfos farm={farm} />

            <FarmDelegationInfos
              delegPerYear={farm.deleg_per_year}
              delegationProgress={farm.deleg_in_progress}
              poolsPerYear={farm.pools_per_year}
              capacityPerPool={farm.capacity_per_pool}
            />
          </FarmDetailsStyle>

          {farm.role === 'breeder' && (
            <FarmAvailabilitiesStyle>
              <FarmAvailabilities
                farmId={farm.id}
                availabilities={farm.availabilities || []}
              />
            </FarmAvailabilitiesStyle>
          )}
          {farm.heifers.length > 0 && (
            <section>
              <PageHeader title="Génisses" />
              <HeifersList heifers={farm.heifers} role={farm.role} />
            </section>
          )}
        </ContainerSmall>
      );
    }}
  </Query>
);

FarmDetails.propTypes = {
  farmId: PropTypes.string,
};

FarmDetails.defaultProps = {
  farmId: null,
};

export default FarmDetails;
