import React from 'react';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';

import ArrowIcon from './ArrowIcon';
import 'react-dates/lib/css/_datepicker.css';
import './index.css';

moment.locale('fr');

const Calendar = ({ onChangeDate, highlightDay, date }) => (
  <DayPickerSingleDateController
    date={date}
    onDateChange={newDate => onChangeDate(moment(newDate).format())}
    isOutsideRange={() => false}
    navPrev={<ArrowIcon direction="left" />}
    navNext={<ArrowIcon direction="right" />}
    isDayHighlighted={day => highlightDay(moment(day).format('dd/MM/yyyy'))}
    hideKeyboardShortcutsPanel
    numberOfMonths={2}
    daySize={30}
    noBorder
  />
);

Calendar.defaultProps = {
  highlightDay: () => {},
  onChangeDate: () => {},
  date: null,
};

Calendar.propTypes = {
  highlightDay: PropTypes.func,
  onChangeDate: PropTypes.func,
  date: PropTypes.string,
};

export default Calendar;
