import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { farmRoles } from '../constants';
import { heiferCurrentState } from './heiferEvents';
import { getPoolStatus } from './pools';

// Specific case for heifers where list is ordered by status: "pleine", "en cours" or "inséminée"
export const orderByHeiferState = (list, order) =>
  list.sort((a, b) => {
    if (!('events' in a)) return 1;
    if (!('events' in b)) return -1;
    const aState = `${heiferCurrentState(a.events)}_${a.id}`;
    const bState = `${heiferCurrentState(b.events)}_${b.id}`;
    return aState > bState ? order : -order;
  });

// Specific case for pools where list is ordered by delegation status: "en attente", "en cours" or "clos"
export const orderByPoolDelegationStatus = (list, order) =>
  list.sort((a, b) => {
    const aState = `${getPoolStatus(a)}_${a.id}`;
    const bState = `${getPoolStatus(b)}_${b.id}`;

    return aState.toLowerCase().normalize('NFD') >
      bState.toLowerCase().normalize('NFD')
      ? order
      : -order;
  });

export const orderByAttribut = (list, value, order) =>
  list.sort((a, b) => {
    if (!(value in a)) return 1;
    if (!(value in b)) return -1;

    return a[value].toLowerCase().normalize('NFD') >
      b[value].toLowerCase().normalize('NFD')
      ? order
      : -order;
  });

export const orderByValue = (list, getValue, order) =>
  list.sort((a, b) => {
    return getValue(a.events) > getValue(b.events) ? order : -order;
  });

export const orderByIdPool = (list, value, order) =>
  list.sort((a, b) => {
    if (!(value in a)) return 1;
    if (!(value in b)) return -1;

    return parseInt(a[value], 10) > parseInt(b[value], 10) ? order : -order;
  });

// In case the value to order by is "role", then use the displayed name (ex: naisseur)
// of the role to order with instead of the code (ex: producer)
export const orderByRole = (list, orderValue, order) =>
  list.sort((a, b) => {
    const aToOrder = farmRoles[a[orderValue]];
    const bToOrder = farmRoles[b[orderValue]];

    if (!aToOrder) return 1;
    if (!bToOrder) return -1;

    return aToOrder.toLowerCase().normalize('NFD') >
      bToOrder.toLowerCase().normalize('NFD')
      ? order
      : -order;
  });

export const stringComparator = order => (string1, string2) => {
  if (!string1) return order;
  if (!string2) return -order;

  return (
    string1
      .toLowerCase()
      .normalize('NFD')
      .localeCompare(string2.toLowerCase().normalize('NFD')) * order
  );
};

export const stringAttributeComparator = (attributeName, order = 1) => (
  object1,
  object2,
) => {
  if (!object1) return order;
  if (!object2) return -order;

  return stringComparator(order)(
    object1[attributeName],
    object2[attributeName],
  );
};

export const byFarmNameHeiferComparator = (role, order = 1) => (a, b) => {
  const aToOrder = a[role];
  const bToOrder = b[role];

  return stringAttributeComparator('farm_name', order)(aToOrder, bToOrder);
};

export const orderByFarmName = (list, role, order) =>
  list.sort(byFarmNameHeiferComparator(role, order));

export const orderByPoolProducer = (list, order) =>
  list.sort((a, b) => {
    const aToOrder = a.heifers.length > 0 ? a.heifers[0].producer_summary : '';
    const bToOrder = b.heifers.length > 0 ? b.heifers[0].producer_summary : '';

    if (!aToOrder) return -order;
    if (!bToOrder) return order;

    return aToOrder.farm_name.toLowerCase().normalize('NFD') >
      bToOrder.farm_name.toLowerCase().normalize('NFD')
      ? order
      : -order;
  });

const orderDate = (value, order) => (a, b) => {
  if (!a) return order;
  if (!b) return -order;

  const minDate = '0000-01-01T00:00:00Z';

  const aDate = parseISO(a[value] || minDate);
  const bDate = parseISO(b[value] || minDate);
  return order === 1 ? compareAsc(aDate, bDate) : compareDesc(aDate, bDate);
};

export const orderByDate = (list, value, order) =>
  list.sort(orderDate(value, order));

export const orderByEventDate = (list, order, eventType) => {
  const fn = orderDate('date', order);
  return list.sort((a, b) => {
    const aToOrder = a.events.find(event => event.type === eventType);
    const bToOrder = b.events.find(event => event.type === eventType);
    return fn(aToOrder, bToOrder);
  });
};
