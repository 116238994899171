import styled from 'styled-components';

const HeaderRowStyle = styled.div`
  display: flex;
  background-color: #fff;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  color: ${({ theme }) => theme.grey700};
  margin-bottom: 3px;
`;

export default HeaderRowStyle;
