import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import ContainerLarge from '../layouts/ContainerLarge';
import PageHeader from '../molecules/PageHeader';
import { GET_BREEDER_POOLS } from '../graphql/queries';
import Loader from '../atoms/Loader';
import { ReactComponent as RefreshIcon } from '../statics/ico/refresh.svg';
import BreederPoolsList from '../molecules/BreederPoolsList';

const BreederTransfers = ({ farmId, type }) => (
  <Query
    query={GET_BREEDER_POOLS}
    fetchPolicy="network-only"
    variables={{ breederId: farmId, type }}
    skip={!farmId}
  >
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error) throw new Error(error.message);

      return (
        <ContainerLarge>
          <PageHeader
            icon={<RefreshIcon />}
            title={
              type === 'RETURN' ? 'Retours de génisse' : 'Délégations de lots'
            }
          />
          <BreederPoolsList list={data.pools} type={type} />
        </ContainerLarge>
      );
    }}
  </Query>
);

BreederTransfers.propTypes = {
  farmId: PropTypes.string,
  type: PropTypes.oneOf(['RETURN', 'DELEGATION']).isRequired,
};

BreederTransfers.defaultProps = {
  farmId: null,
};

export default BreederTransfers;
