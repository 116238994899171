import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { orderByAttribut } from '../../commons/orderList';
import { getDelegationAvailabilityFromBirthEvent } from '../../commons/heiferEvents';
import IconButton from '../../atoms/IconButton';

const ContainerStyle = styled.div`
  overflow-y: scroll;
`;

const ItemStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 7px 10px;
  margin-bottom: 1px;
  font-size: 12px;
  color: ${({ theme }) => theme.grey700};

  .heiferItem__details {
    flex: 2;
    display: flex;
    align-items: center;

    .heiferId {
      font-weight: 700;
      color: ${({ theme }) => theme.grey500};
      font-size: 12px;
      text-decoration: none;
    }

    .farmName {
      padding: 0 10px;
      font-size: 12px;
      color: inherit;
    }
  }

  .heiferItem__availability {
    flex: 1;
  }

  .delete__picto {
    margin-right: 10px;
    visibility: hidden;
    transition: ${({ theme }) => theme.transition};
  }

  &:focus,
  &:hover {
    .delete__picto {
      cursor: pointer;
      visibility: visible;
    }
  }
`;

const DelegationHeifersList = ({ heifers, onDeletePoolHeifer }) => (
  <ContainerStyle>
    {orderByAttribut(heifers, 'id_heifer', 1).map(heifer => (
      <ItemStyle key={heifer.id}>
        <div className="delete__picto">
          <IconButton
            color="blue"
            size="small"
            icon="minus"
            onClick={() => onDeletePoolHeifer(heifer.id)}
          />
        </div>
        <div className="heiferItem__details">
          <Link to={`/view/farms/heifer/${heifer.id}`} className="heiferId">
            {heifer.id_heifer}
          </Link>
          <Link to={`/view/farms/${heifer.producer.id}`} className="farmName">
            {heifer.producer.farm_name}
          </Link>
        </div>
        <div>
          <span className="heiferItem__availability">
            dispo. à partir du{' '}
            {getDelegationAvailabilityFromBirthEvent(heifer.events)}
          </span>
        </div>
      </ItemStyle>
    ))}
  </ContainerStyle>
);

DelegationHeifersList.propTypes = {
  heifers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
      producer: PropTypes.shape({ farm_name: PropTypes.string.isRequired }),
    }),
  ).isRequired,
  onDeletePoolHeifer: PropTypes.func.isRequired,
};

export default DelegationHeifersList;
