import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DeleteIcon from '../statics/ico/delete.svg';
import EditFileIcon from '../statics/ico/editFile.svg';
import { ReactComponent as ChevronIco } from '../statics/ico/chevron__grey.svg';

const ActionButton = styled.button`
  cursor: pointer;
  width: ${({ size }) =>
    (size === 'small' && '18px') || (size === 'large' && '30px') || '24px'};
  height: ${({ size }) =>
    (size === 'small' && '18px') || (size === 'large' && '30px') || '24px'};
  border: none;
  background-color: ${({ theme, color }) =>
    (color === 'white' && '#fff') ||
    (color === 'blue' && theme.blue300) ||
    theme.grey300};
  border-radius: 50%;
  position: relative;
  opacity: 0.7;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: 1;
  }

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 14px;
    max-height: 15px;
  }

  .chevron {
    * {
      stroke: ${({ theme }) => theme.blue500};
    }
  }

  > span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: ${({ size }) => (size === 'small' && '23px') || '31px'};
    line-height: 70%;
    color: ${({ color, theme }) =>
      (color === 'blue' && theme.blue500) || 'inherit'};
  }
`;

const IconButton = ({ icon, onClick, ...props }) => (
  <ActionButton
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    {...props}
  >
    {icon === 'delete' && <img src={DeleteIcon} alt="Suppression" />}
    {icon === 'edit' && <img src={EditFileIcon} alt="Edition" />}
    {icon === 'minus' && <span>-</span>}
    {icon === 'chevron' && <ChevronIco className="chevron" />}
  </ActionButton>
);

IconButton.defaultProps = {
  color: 'grey',
  size: 'medium',
};

IconButton.propTypes = {
  icon: PropTypes.oneOf(['delete', 'edit', 'minus', 'chevron']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['blue', 'grey', 'white']),
  onClick: PropTypes.func.isRequired,
};

export default IconButton;
