import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import CellStyle from '../atoms/table/TableCell';
import HeaderRowStyle from '../atoms/table/TableHeaderRow';
import InfoLabel from '../atoms/InfoLabel';
import ContentRowStyle from '../atoms/table/TableContentRow';
import EmptyList from './EmptyList';
import {
  orderByAttribut,
  orderByDate,
  orderByPoolDelegationStatus,
  orderByIdPool,
} from '../commons/orderList';
import { getPoolStatus } from '../commons/pools';

export const SmallCellStyle = styled(CellStyle)`
  flex: 0.5;

  &:first-child {
    font-weight: 700;
  }
`;

export const ClassicCellStyle = styled(CellStyle)`
  flex: 1;

  &:first-child {
    font-weight: 700;
  }

  &:nth-child(4),
  &:nth-child(5) {
    text-align: right;
  }
`;

export const LargeCellStyle = styled(CellStyle)`
  flex: 4;
`;

const ProducerDelegationListStyle = styled.div`
  box-shadow: ${({ theme }) => theme.darkShadow};
  border-radius: 5px;
`;

const ProducerDelegationList = ({ pools }) => {
  const [orderDirection, changeOrderDirection] = useState('desc');
  const [orderValue, changeOrderValue] = useState('state');

  function getOrderedList() {
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'state':
        return orderByPoolDelegationStatus(pools, orderStatement);
      case 'transfer_date':
        return orderByDate(pools, 'transfer_date', orderStatement);
      case 'id_pool':
        return orderByIdPool(pools, 'id_pool', orderStatement);
      default:
        return orderByAttribut(pools, 'id_heifer', orderStatement);
    }
  }

  function toggleOrder(newOrderValue) {
    changeOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    changeOrderValue(newOrderValue);
  }

  function headerClass(id) {
    return `sortButton ${orderValue === id ? `active ${orderDirection}` : ''}`;
  }

  return (
    <ProducerDelegationListStyle>
      <HeaderRowStyle>
        <SmallCellStyle>
          <button
            className={headerClass('id_pool')}
            onClick={() => toggleOrder('id_pool')}
          >
            Lot
          </button>
        </SmallCellStyle>

        <ClassicCellStyle>
          <button
            className={headerClass('id_heifer')}
            onClick={() => toggleOrder('id_heifer')}
          >
            Génisse
          </button>
        </ClassicCellStyle>

        <LargeCellStyle>
          <span>Éleveur</span>
        </LargeCellStyle>

        <ClassicCellStyle>
          <button
            className={headerClass('transfer_date')}
            onClick={() => toggleOrder('transfer_date')}
          >
            Date transfert
          </button>
        </ClassicCellStyle>

        <ClassicCellStyle>
          <button
            className={headerClass('state')}
            onClick={() => toggleOrder('state')}
          >
            Statut du transfert
          </button>
        </ClassicCellStyle>
      </HeaderRowStyle>

      {pools.length > 0 ? (
        getOrderedList().map(pool => {
          const status = getPoolStatus(pool);

          return (
            <ContentRowStyle
              key={pool.id_heifer}
              to={`/transfer/delegations/${pool.id}`}
            >
              <SmallCellStyle>
                <span>{pool.id_pool}</span>
              </SmallCellStyle>
              <ClassicCellStyle>
                <span>{pool.id_heifer}</span>
              </ClassicCellStyle>
              <LargeCellStyle>
                <span>{pool.breeder_name}</span>
              </LargeCellStyle>

              <ClassicCellStyle>
                {pool.transfer_date && (
                  <span>
                    {format(parseISO(pool.transfer_date), 'dd/MM/yyyy')}
                  </span>
                )}
              </ClassicCellStyle>

              <ClassicCellStyle>
                <div>
                  <InfoLabel
                    warning={status === 'en attente'}
                    valid={status === 'en cours'}
                    noMargin
                  >
                    {status}
                  </InfoLabel>
                </div>
              </ClassicCellStyle>
            </ContentRowStyle>
          );
        })
      ) : (
        <EmptyList text="Aucune génisse n’a été ajoutée pour l’instant." />
      )}
    </ProducerDelegationListStyle>
  );
};

ProducerDelegationList.propTypes = {
  pools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProducerDelegationList;
