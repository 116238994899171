import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { Link } from '@reach/router';

import Checkbox from '../../atoms/Checkbox';
import { getEventDate } from '../../commons/heiferEvents';
import CellStyle from '../../atoms/table/TableCell';

const HeiferCellStyle = styled(CellStyle)`
  display: flex;
  align-items: center;
  flex: 3;
`;

const DateCellStyle = styled(CellStyle)`
  flex: 1;
  text-align: end;
`;

const TypeCellStyle = styled(CellStyle)`
  flex: 2;
`;

const HeiferWaitingItemStyle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ selected, theme }) =>
    selected ? theme.blue100 : '#fff'};
  padding-left: 12px;
  margin: 5px 0;

  .heiferItem__heiferId {
    font-weight: 700;
    color: ${({ theme }) => theme.grey500};
    line-height: 14px;
    text-decoration: none;
  }

  .heiferItem__farmName {
    color: ${({ theme }) => theme.grey700};
    padding: 0 10px;
    line-height: 14px;
  }

  .heiferItem__date {
    color: ${({ theme }) => theme.grey700};
  }
`;

const HeiferWaitingItem = ({ heifer, isSelected, onSelected }) => (
  <HeiferWaitingItemStyle selected={isSelected}>
    <Checkbox
      name={`waiting_${heifer.id}`}
      onChange={onSelected}
      checked={isSelected}
    />
    <HeiferCellStyle>
      <Link
        className="heiferItem__heiferId"
        to={`/view/farms/heifer/${heifer.id}`}
        data-cy="heifersList__heiferId"
      >
        {heifer.id_heifer}
      </Link>
      <Link
        className="heiferItem__farmName"
        to={`/view/farms/${heifer.producer.id}`}
      >
        {heifer.producer.farm_name}
      </Link>
    </HeiferCellStyle>
    <TypeCellStyle>
      <span className="heiferItem__date">{heifer.producer.farm_type}</span>
    </TypeCellStyle>
    <DateCellStyle>
      <span className="heiferItem__date">
        {getEventDate(heifer.events, 'BIRTH')}
      </span>
    </DateCellStyle>
    <DateCellStyle>
      <span className="heiferItem__date">
        {format(parseISO(heifer.delegation_availability), 'dd/MM/yyyy')}
      </span>
    </DateCellStyle>
  </HeiferWaitingItemStyle>
);

HeiferWaitingItem.propTypes = {
  onSelected: PropTypes.func.isRequired,
  heifer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_heifer: PropTypes.string.isRequired,
    producer: PropTypes.shape({
      farm_name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      farm_type: PropTypes.string.isRequired,
    }).isRequired,
    events: PropTypes.array.isRequired,
    delegation_availability: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default HeiferWaitingItem;
