import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { RequireNewPassword } from 'aws-amplify-react';

import { ReactComponent as CheckIco } from '../../statics/ico/check.svg';
import { ReactComponent as CrossIco } from '../../statics/ico/cross__red.svg';
import Tooltip from '../../atoms/Tooltip';
import AuthLayout from '../../layouts/Auth';
import H1 from '../../atoms/titles/H1';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';

const FormStyle = styled.form`
  width: 100%;
  max-width: 400px;

  .formContainer {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    height: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;

    > * {
      width: 100%;
    }
  }

  #passwordHelperToolTip {
    svg {
      margin-right: 5px;
    }

    .valid {
      width: 11px;
      height: 8px;

      path {
        stroke: ${({ theme }) => theme.green500};
      }
    }
    .invalid {
      width: 11px;
      height: 8px;
    }
  }
`;

const Title = styled(H1)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  justify-content: center;
  display: flex;
`;

export default class NewPassword extends RequireNewPassword {
  static myRef = React.createRef();

  state = {
    password: '',
  };

  input = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  login = async event => {
    event.preventDefault();

    const user = this.props.authData;

    try {
      await Auth.completeNewPassword(user, this.state.password);

      // in order to get attributes of connected user
      const userWithData = await Auth.currentAuthenticatedUser();

      this.changeState('signedIn', userWithData);
    } catch (err) {
      toast.error(
        "Une erreur est survenue, veuillez contacter l'administrateur",
      );
    }
  };

  render() {
    const { password } = this.state;

    if (!this._validAuthStates.includes(this.props.authState)) {
      this._isHidden = true;
      return null;
    }

    return (
      <AuthLayout>
        <FormStyle onSubmit={this.login}>
          <Title>Veuillez saisir votre nouveau mot de passe</Title>

          <div className="formContainer">
            <div>
              <div>
                <Input
                  type="password"
                  name="password"
                  placeholder="Nouveau mot de passe"
                  label="Nouveau mot de passe"
                  onChange={this.input}
                  value={this.state.password}
                  required
                  data-tip
                  data-for="passwordHelperToolTip"
                />
              </div>
              <Tooltip id="passwordHelperToolTip">
                Votre mot de passe doit contenir :<br />
                <br />
                {password.length >= 8 ? (
                  <CheckIco className="icon valid" />
                ) : (
                  <CrossIco className="icon invalid" />
                )}
                Au moins <span>8 caractères</span>
              </Tooltip>
            </div>
            <ButtonContainer>
              <Button type="submit" disabled={!this.state.password}>
                Mettre à jour
              </Button>
            </ButtonContainer>
          </div>
        </FormStyle>
      </AuthLayout>
    );
  }
}

NewPassword.propTypes = {
  hide: PropTypes.array,
  authData: PropTypes.object,
};
