import React from 'react';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    color: ${({ theme }) => theme.grey500};
  }

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.blue500};
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.grey500};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const PoolSummaryTitle = ({ title, pool, carrier }) => (
  <>
    <Title>{title}</Title>
    <Meta className="metaContainer">
      <p>
        Eleveur <span>{pool.breeder.farm_name}</span>
      </p>
      <p>
        le <span>{format(parseISO(pool.transfer_date), 'dd/MM/yyyy')}</span>
      </p>
    </Meta>
    {carrier && (
      <Meta>
        <p>
          Transporteur <span>{carrier}</span>
        </p>
      </Meta>
    )}
  </>
);

PoolSummaryTitle.defaultProps = {
  carrier: null,
};

PoolSummaryTitle.propTypes = {
  carrier: PropTypes.string,
  title: PropTypes.string.isRequired,
  pool: PropTypes.object.isRequired,
};

export default PoolSummaryTitle;
