import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CellStyle from '../../atoms/table/TableCell';
import HeaderRowStyle from '../../atoms/table/TableHeaderRow';
import {
  orderByAttribut,
  orderByDate,
  orderByFarmName,
  orderByIdPool,
  orderByPoolProducer,
  stringAttributeComparator,
} from '../../commons/orderList';
import EmptyList from '../EmptyList';
import Accordion from '../../atoms/Accordion';
import Item from './Item';
import { GET_HEIFERS_SUMMARY } from '../../graphql/queries';
import HeifersList from './HeifersList';

export const IdCellStyle = styled(CellStyle)`
  flex: 1;
  > * {
    font-weight: 700;
  }
`;

export const SmallCellStyle = styled(CellStyle)`
  flex: 1;
`;

export const MediumCellStyle = styled(CellStyle)`
  flex: 2;
`;

export const BreederCellStyle = styled(CellStyle)`
  flex: 3;
`;

const HeiferCellStyle = styled(CellStyle)`
  flex: 0 0 120px;
`;

class DelegationPoolList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDirection: 'desc',
      orderValue: 'transfer_date',
    };
  }

  getOrderedList = () => {
    const { list } = this.props;
    const { orderValue, orderDirection } = this.state;
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'producer':
        return orderByPoolProducer(list, orderStatement);
      case 'breeder':
        return orderByFarmName(list, 'breeder', orderStatement);
      case 'transfer_date':
        return orderByDate(list, orderValue, orderStatement);
      case 'id_pool':
        return orderByIdPool(list, orderValue, orderStatement);
      default:
        return orderByAttribut(list, orderValue, orderStatement);
    }
  };

  getHeifersSortedByFarm = heifers => {
    return (
      heifers
        // Heifer ids have farm number as prefix
        // Sorting by heifer ids then implies sorting by farm
        .sort(stringAttributeComparator('id_heifer'))
    );
  };

  toggleOrder = orderValue => {
    const { orderDirection } = this.state;

    this.setState({
      orderValue,
      orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  isRowActive = name => {
    const { orderValue, orderDirection } = this.state;

    return orderValue === name ? `active ${orderDirection}` : '';
  };

  render = () => {
    const { list } = this.props;

    return (
      <div>
        <HeaderRowStyle>
          <IdCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('id_pool')}`}
              onClick={() => this.toggleOrder('id_pool')}
            >
              Lot
            </button>
          </IdCellStyle>

          <BreederCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('breeder')}`}
              onClick={() => this.toggleOrder('breeder')}
            >
              Éleveur
            </button>
          </BreederCellStyle>

          <MediumCellStyle>
            <button
              type="button"
              className={`sortButton ${this.isRowActive('transfer_date')}`}
              onClick={() => this.toggleOrder('transfer_date')}
            >
              Départ prévu
            </button>
          </MediumCellStyle>

          <HeiferCellStyle>
            <span>Génisses</span>
          </HeiferCellStyle>
        </HeaderRowStyle>

        {list.length > 0 ? (
          this.getOrderedList().map(pool => (
            <Accordion
              key={pool.id}
              expandable={
                // To avoid all heifers retrieval we use a subquery, called when the accordion is opened
                <Query
                  query={GET_HEIFERS_SUMMARY}
                  variables={{
                    ids: pool.heifers.map(heifer => heifer.id || heifer),
                  }}
                  fetchPolicy="network-only"
                  skip={pool.heifers.length === 0}
                >
                  {({ error, data }) => {
                    if (error) return `Error! ${error.message}`;

                    return (
                      <HeifersList
                        list={this.getHeifersSortedByFarm(
                          data.heiferByIds || [],
                        )}
                      />
                    );
                  }}
                </Query>
              }
              visible={({ toggleAccordion, isOpened }) => (
                <Item
                  toggleAccordion={toggleAccordion}
                  isOpened={isOpened}
                  pool={pool}
                  type="DELEGATION"
                />
              )}
            />
          ))
        ) : (
          <EmptyList text={`Il n’y a pas de lots pour l'instant.`} />
        )}
      </div>
    );
  };
}

DelegationPoolList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      breeder: PropTypes.shape({
        farm_name: PropTypes.string.isRequired,
      }),
      heifers: PropTypes.array.isRequired,
      transfer_date: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DelegationPoolList;
