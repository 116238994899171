import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { GET_POOLS } from '../graphql/queries';
import { MERGE_POOLS } from '../graphql/mutations';

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  & > * {
    margin: 0 10px;
  }
`;

class MergePoolsConfirm extends Component {
  state = {
    loading: false,
  };

  handleMergePools = async () => {
    const { client, ids, successCallback } = this.props;
    this.setState({ loading: true });
    try {
      await client.mutate({
        mutation: MERGE_POOLS,
        variables: { ids },
        refetchQueries: [
          {
            query: GET_POOLS,
            variables: { type: 'RETURN', status: ['CREATED'] },
          },
        ],
      });

      toast.success('Les lots ont été fusionnés.');
      return successCallback();
    } catch (err) {
      this.setState({ loading: false });
      switch (err.graphQLErrors[0].message) {
        case 'multiple-breeders':
          return toast.error("Les lots selectionnés n'ont pas le même éleveur");
        case 'multiple-producers':
          return toast.error(
            "Les lots selectionnés n'ont pas le même naisseur",
          );
        case 'undefined-pool':
          return toast.error(
            "Un des retours selectionnés n'est pas disponible",
          );
        default:
          return toast.error(err.message);
      }
    }
  };

  render = () => {
    const { toggleModal } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <H2 blue>Fusionner les lots</H2>
        <p>ëtes-vous sûr de vouloir fusionner les lots ?</p>
        <ButtonBar>
          <Button disabled={loading} secondary onClick={toggleModal}>
            Annuler
          </Button>
          <Button disabled={loading} onClick={this.handleMergePools}>
            Fusionner
          </Button>
        </ButtonBar>
      </div>
    );
  };
}

MergePoolsConfirm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
};

export default withApollo(MergePoolsConfirm);
