import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { DELETE_EVENT } from '../graphql/mutations';
import { GET_HEIFER } from '../graphql/queries';

const RemoveEventHeiferStyle = styled.div`
  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const RemoveHeiferEvent = ({ toggleModal, eventId, type, idHeifer }) => (
  <Mutation
    mutation={DELETE_EVENT}
    refetchQueries={[{ query: GET_HEIFER, variables: { id: idHeifer } }]}
    awaitRefetchQueries
  >
    {(deleteEvent, { loading }) => (
      <RemoveEventHeiferStyle>
        <H2 blue>Suppression d’un événement</H2>
        <p>
          Êtes-vous sûr de vouloir supprimer cet événement ? Cette action est
          irréversible.
        </p>
        <div className="buttonBar">
          <Button type="button" onClick={toggleModal} secondary>
            Annuler
          </Button>
          <Button
            type="submit"
            disabled={loading}
            onClick={async () => {
              try {
                await deleteEvent({
                  variables: { id: eventId, type, id_heifer: idHeifer },
                });
                toast.success("L'événement a été supprimé");
              } catch (err) {
                toast.error(
                  "L'événement n'a pas été supprimé à cause d'une erreur technique",
                );
              }
              toggleModal();
            }}
          >
            Supprimer
          </Button>
        </div>
      </RemoveEventHeiferStyle>
    )}
  </Mutation>
);

RemoveHeiferEvent.defaultProps = {
  eventId: null,
  type: null,
  idHeifer: null,
};

RemoveHeiferEvent.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  eventId: PropTypes.string,
  type: PropTypes.string,
  idHeifer: PropTypes.string,
};

export default RemoveHeiferEvent;
