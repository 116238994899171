import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../statics/ico/cross.svg';

const ReactModalAdapter = ({
  className,
  children,
  onRequestClose,
  ...props
}) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={150}
      onRequestClose={onRequestClose}
      {...props}
    >
      <CloseButtonStyle onClick={onRequestClose}>
        <CrossIcon />
      </CloseButtonStyle>
      {children}
    </Modal>
  );
};

const CloseButtonStyle = styled('button')`
  position: absolute;
  background: transparent;
  border: none;
  right: 25px;
  top: 30px;
  cursor: pointer;
`;

const StyledModal = styled(ReactModalAdapter)`
  .ReactModal__Content,
  .ReactModal__Overlay {
    opacity: 0;
  }

  .ReactModal__Content--after-open,
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 150ms;
  }

  .ReactModal__Content--before-close,
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${({ theme }) => theme.overlayBgColor};
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    padding: 30px;
    max-width: 650px;
    width: 100%;
    margin: auto;
    background: #fff;
    overflow: auto;
    border-radius: 5px;
    outline: none;
    transform: translateY(-50%);
  }
`;

// Mandatory by react-modal http://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default StyledModal;
