import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CellStyle from '../../atoms/table/TableCell';
import HeaderRowStyle from '../../atoms/table/TableHeaderRow';

import {
  orderByAttribut,
  orderByDate,
  orderByIdPool,
  orderByPoolDelegationStatus,
} from '../../commons/orderList';
import EmptyList from '../EmptyList';
import Accordion from '../../atoms/Accordion';
import Item from './Item';
import HeifersList from './HeifersList';

export const IdCellStyle = styled(CellStyle)`
  flex: 1;

  > * {
    font-weight: 700;
  }
`;

export const SmallCellStyle = styled(CellStyle)`
  flex: 1;
`;

const HeiferCellStyle = styled(CellStyle)`
  flex: 3;
`;

const BreederPoolsList = ({ list, type }) => {
  const [orderDirection, setOrderDirection] = useState('desc');
  const [orderValue, setOrderValue] = useState('status');

  const getListOrdered = useCallback(() => {
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'transfer_date':
        return orderByDate(list, orderValue, orderStatement);
      case 'id_pool':
        return orderByIdPool(list, orderValue, orderStatement);
      case 'status':
        return orderByPoolDelegationStatus(list, orderStatement);
      default:
        return orderByAttribut(list, orderValue, orderStatement);
    }
  }, [list, orderDirection, orderValue]);

  const toggleOrder = newOrderValue => {
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    setOrderValue(newOrderValue);
  };

  const isRowActive = name =>
    orderValue === name ? `active ${orderDirection}` : '';

  return (
    <div>
      <HeaderRowStyle>
        <IdCellStyle>
          <button
            type="button"
            className={`sortButton ${isRowActive('id_pool')}`}
            onClick={() => toggleOrder('id_pool')}
          >
            Lot
          </button>
        </IdCellStyle>

        <HeiferCellStyle>
          <span>Nombre de génisses</span>
        </HeiferCellStyle>

        <SmallCellStyle>
          <button
            type="button"
            className={`sortButton ${isRowActive('transfer_date')}`}
            onClick={() => toggleOrder('transfer_date')}
          >
            {type === 'RETURN' ? 'Date retour' : 'Date transfert'}
          </button>
        </SmallCellStyle>

        <SmallCellStyle>
          <button
            type="button"
            className={`sortButton ${isRowActive('status')}`}
            onClick={() => toggleOrder('status')}
          >
            Status
          </button>
        </SmallCellStyle>
      </HeaderRowStyle>

      {list.length > 0 ? (
        getListOrdered().map(elt => (
          <Accordion
            key={elt.id}
            expandable={<HeifersList list={elt.heifers} type={type} />}
            isOpened
            visible={({ toggleAccordion, isOpened }) => (
              <Item
                toggleAccordion={toggleAccordion}
                isOpened={isOpened}
                pool={elt}
                type={type}
              />
            )}
          />
        ))
      ) : (
        <EmptyList
          text={`Il n’y a pas de ${
            type === 'RETURN' ? 'retours' : 'lots'
          } pour l'instant.`}
        />
      )}
    </div>
  );
};

BreederPoolsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      heifers: PropTypes.array.isRequired,
      transfer_date: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['RETURN', 'DELEGATION']).isRequired,
};

export default BreederPoolsList;
