import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { addMonths, isWithinInterval, parseISO } from 'date-fns';

import { formatDate, NB_MILLI_SECONDS_PER_DAY } from '../commons/dates';
import {
  FormikCheckbox,
  FormikDatePickerArea,
  FormikInput,
  FormikSelect,
  FormikTextArea,
} from '../molecules/Formik';
import {
  EVENT_TYPES,
  EVENT_TYPES_BREEDERS_CAN_EDIT,
  formErrorMsgs,
} from '../constants';
import { getEventsByType, hasMeasureType } from '../commons/heiferEvents';
import Loader from '../atoms/Loader';
import { getCustomError } from '../commons/graphqlError';
import {
  allEventTypes,
  canHeiferHaveAnotherEventOfType,
  getEventTypeShortName,
} from '../commons/events';
import { CREATE_EVENT, UPDATE_EVENT } from '../graphql/mutations';
import H2 from '../atoms/titles/H2';
import Button from '../atoms/Button';
import { GET_HEIFER } from '../graphql/queries';
import { withNoDuplicates } from '../commons/misc';
import UserContext from '../context';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .form__item--small {
    flex: 0 1 130px;
  }

  .form__item--medium {
    flex: 0 1 250px;
  }

  .form__item--centered {
    display: flex;
    margin: 26px 0 0 10px;
    align-items: flex-start;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const emptyValues = {
  date: '',
  eventType: '',
  mesureType: 'OTHER',
  weight: '',
  disease: '',
  treatment: '',
  gmq: '',
  note: '',
  sexed: false,
  bull: '',
};

const formSchema = (birthDate, isEdit) =>
  Yup.object().shape({
    ...(isEdit && { id: Yup.string() }),

    date: Yup.date()
      .required(formErrorMsgs.required)
      .when('eventType', {
        is: value =>
          [
            EVENT_TYPES.INSEMINATION,
            EVENT_TYPES.SONOGRAM_1,
            EVENT_TYPES.SONOGRAM_2,
            EVENT_TYPES.SONOGRAM_EMPTY,
            EVENT_TYPES.SONOGRAM_OTHERS,
          ].includes(value),
        then: Yup.date()
          .required(formErrorMsgs.required)
          .min(
            addMonths(parseISO(birthDate), 8),
            "La date pour ce type d'événement n'est pas correcte",
          ),
      }),

    eventType: Yup.string().required(formErrorMsgs.required),
    weight: Yup.number().when('eventType', {
      is: EVENT_TYPES.MEASUREMENT,
      then: Yup.number()
        .moreThan(0, formErrorMsgs.greaterThanZero)
        .required(formErrorMsgs.required),
    }),
    disease: Yup.string().when('eventType', {
      is: EVENT_TYPES.DISEASE,
      then: Yup.string().required(formErrorMsgs.required),
    }),
    treatment: Yup.string().when('eventType', {
      is: EVENT_TYPES.DISEASE,
      then: Yup.string().required(formErrorMsgs.required),
    }),
    note: Yup.string().when('eventType', {
      is: value =>
        [EVENT_TYPES.OTHER, EVENT_TYPES.BREEDING_TIP].includes(value),
      then: Yup.string().required(formErrorMsgs.required),
    }),
  });

export const getMutationVariables = (values, isEdit) => ({
  event: {
    ...(isEdit && { id: values.id }),
    type: values.eventType,
    date: formatDate(parseISO(values.date)),
    ...(values.eventType === EVENT_TYPES.MEASUREMENT && {
      weight: values.weight,
      ...(values.gmq && { gmq: values.gmq }),
      weighingType: values.mesureType,
    }),
    ...(values.eventType === EVENT_TYPES.DISEASE && {
      disease: values.disease,
      treatment: values.treatment,
    }),
    ...((values.eventType === EVENT_TYPES.OTHER ||
      values.eventType === EVENT_TYPES.BREEDING_TIP) && {
      note: values.note,
    }),
    ...(values.eventType === EVENT_TYPES.ABATTOIR && {
      note: values.note || null,
    }),
    ...(values.eventType === EVENT_TYPES.INSEMINATION && {
      sexed: values.sexed,
      bull: values.bull,
    }),
  },
});

const handleEventSave = async (saveEvent, heiferId, values, isEdit = false) => {
  const actionLabel = isEdit ? 'mis à jour' : 'ajouté';
  const variables = {
    id_heifer: heiferId,
    ...getMutationVariables(values, isEdit),
  };

  try {
    await saveEvent({ variables });
    toast.success(`L'événement a été ${actionLabel}`);
  } catch (err) {
    if (getCustomError(err, 'HEIFER_DEAD')) {
      toast.error(
        `L'événement n'a pas été ${actionLabel} car la génisse est déclarée morte`,
      );
    } else {
      console.error(err);
      toast.error(
        `L'événement n'a pas été ${actionLabel} à cause d'une erreur technique`,
      );
    }
  }
};

const getEventValues = event => {
  return {
    ...emptyValues,
    ...(event.id && { id: event.id }),
    date: event.date,
    eventType: event.type,
    ...(event.type === EVENT_TYPES.MEASUREMENT && {
      weight: event.weight,
      ...(event.gmq && { gmq: event.gmq }),
      weighingType: event.mesureType,
    }),
    ...(event.type === EVENT_TYPES.DISEASE && {
      disease: event.disease,
      treatment: event.treatment,
    }),
    ...((event.type === EVENT_TYPES.OTHER ||
      event.type === EVENT_TYPES.BREEDING_TIP) && {
      note: event.note,
    }),
    ...(event.type === EVENT_TYPES.ABATTOIR && {
      note: event.note || null,
    }),
    ...(event.type === EVENT_TYPES.INSEMINATION && {
      sexed: event.sexed,
      bull: event.bull,
    }),
  };
};

const SaveEventForm = ({
  closeModal,
  heiferId,
  handleDelete,
  allowRemoveHeiferIfDeath,
  event = null,
  isEdit = false,
}) => {
  const onSubmit = saveEvent => async (
    values,
    { setSubmitting, resetForm },
  ) => {
    if (values.eventType === EVENT_TYPES.DEATH && allowRemoveHeiferIfDeath) {
      handleDelete();
    } else {
      await handleEventSave(saveEvent, heiferId, values, isEdit);
    }

    setSubmitting(false);
    resetForm(emptyValues);
    closeModal();
  };
  const { userGroups } = useContext(UserContext);
  const isAdmin = userGroups.includes('admin');

  const renderDateAndTypeFields = (heifer, values, isDateOutOfRange) => {
    const availableEventTypes = withNoDuplicates([
      ...(((isEdit || isAdmin) && allEventTypes()) || []),
      ...EVENT_TYPES_BREEDERS_CAN_EDIT,
      EVENT_TYPES.OTHER,
    ])
      .filter(type => isEdit || canHeiferHaveAnotherEventOfType(heifer, type))
      .sort((type1, type2) =>
        Intl.Collator().compare(
          getEventTypeShortName(type1),
          getEventTypeShortName(type2),
        ),
      );

    return (
      <div className="form__row">
        <div className="form__item--small">
          <Field
            name="date"
            label="Date"
            component={FormikDatePickerArea}
            outsideRange={isDateOutOfRange}
          />
        </div>

        <div className="form__item--medium">
          <Field
            name="eventType"
            placeholder="Type d'événement"
            component={FormikSelect}
            label="Type d'événement"
            disabled={isEdit}
          >
            {availableEventTypes.map(eventType => (
              <option value={eventType} key={eventType}>
                {getEventTypeShortName(eventType)}
              </option>
            ))}
          </Field>
        </div>
      </div>
    );
  };

  const renderInseminationFields = values => {
    return (
      values.eventType === EVENT_TYPES.INSEMINATION && (
        <div className="form__row">
          <div className="form__item--large">
            <Field
              type="text"
              name="bull"
              label="Taureau"
              component={FormikInput}
            />
          </div>

          <div className="form__item--medium form__item--centered">
            <Field
              name="sexed"
              label="Insémination sexée"
              checked={values.sexed}
              component={FormikCheckbox}
            />
          </div>
        </div>
      )
    );
  };

  const renderMeasurementFields = (heifer, values) => {
    return (
      values.eventType === EVENT_TYPES.MEASUREMENT && (
        <div className="form__row">
          <div className="form__item--medium">
            <Field
              name="mesureType"
              placeholder="Type de mesure"
              component={FormikSelect}
              label="Type de mesure"
            >
              <option value="OTHER">Mesure libre</option>
              <option value="P1" disabled={hasMeasureType(heifer.events, 'P1')}>
                Poids d&apos;arrivée (P1)
              </option>
              <option value="P2" disabled={hasMeasureType(heifer.events, 'P2')}>
                Poids d&apos;insémination (P2)
              </option>
              <option value="P3" disabled={hasMeasureType(heifer.events, 'P3')}>
                Poids de sortie (P3)
              </option>
            </Field>
          </div>

          <div className="form__item--small">
            <Field
              type="number"
              name="weight"
              label="Poids (kg)"
              component={FormikInput}
            />
          </div>

          <div className="form__item--small">
            <Field
              type="number"
              name="gmq"
              label="GMQ (kg/jour)"
              component={FormikInput}
            />
          </div>
        </div>
      )
    );
  };

  const renderDiseaseFields = values => {
    return (
      values.eventType === EVENT_TYPES.DISEASE && (
        <div className="form__row">
          <Field
            type="text"
            name="disease"
            label="Maladie"
            component={FormikInput}
          />
          <Field
            type="text"
            name="treatment"
            label="Traitement"
            component={FormikInput}
          />
        </div>
      )
    );
  };

  const renderAbattoirFields = values => {
    return (
      values.eventType === EVENT_TYPES.ABATTOIR && (
        <div className="form__row">
          <div className="form__item">
            <Field name="note" label="Note" component={FormikTextArea} />
          </div>
        </div>
      )
    );
  };

  const renderBreedingTipAndOtherFields = values => {
    return (
      (values.eventType === EVENT_TYPES.OTHER ||
        values.eventType === EVENT_TYPES.BREEDING_TIP) && (
        <div className="form__row">
          <div className="form__item">
            <Field name="note" label="Note" component={FormikTextArea} />
          </div>
        </div>
      )
    );
  };

  return (
    <Query query={GET_HEIFER} variables={{ id: heiferId }}>
      {({ loading, error, data: { heifer } }) => {
        if (loading) return <Loader inline />;
        if (error) return `Error! ${error.message}`;

        const birthDate = getEventsByType(heifer.events, EVENT_TYPES.BIRTH)[0]
          .date;
        const isDateOutOfRange = day => {
          const isBirthEvent =
            event !== null && event.type === EVENT_TYPES.BIRTH;
          if (isBirthEvent) {
            return false;
          }

          return !isWithinInterval(day.toDate(), {
            start: parseISO(birthDate),
            end: new Date(Date.now() + NB_MILLI_SECONDS_PER_DAY),
          });
        };

        return (
          <>
            <H2 blue>Évènement</H2>

            <Mutation mutation={isEdit ? UPDATE_EVENT : CREATE_EVENT}>
              {saveEvent => (
                <Formik
                  initialValues={
                    event !== null ? getEventValues(event) : emptyValues
                  }
                  validationSchema={formSchema(birthDate, isEdit)}
                  onSubmit={onSubmit(saveEvent)}
                >
                  {({ resetForm, isSubmitting, values }) => (
                    <FormStyle>
                      {renderDateAndTypeFields(
                        heifer,
                        values,
                        isDateOutOfRange,
                      )}

                      {renderInseminationFields(values)}
                      {renderMeasurementFields(heifer, values)}
                      {renderDiseaseFields(values)}
                      {renderAbattoirFields(values)}
                      {renderBreedingTipAndOtherFields(values)}

                      <div className="buttonBar">
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            resetForm(emptyValues);
                            closeModal();
                          }}
                          secondary
                        >
                          Annuler
                        </Button>

                        <Button type="submit" disabled={isSubmitting}>
                          {isEdit ? 'Modifier' : 'Ajouter'}
                        </Button>
                      </div>
                    </FormStyle>
                  )}
                </Formik>
              )}
            </Mutation>
          </>
        );
      }}
    </Query>
  );
};

SaveEventForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  heiferId: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  allowRemoveHeiferIfDeath: PropTypes.bool.isRequired,
  event: PropTypes.object,
  isEdit: PropTypes.bool,
};

SaveEventForm.defaultProps = {
  event: null,
  isEdit: false,
};

export default SaveEventForm;
