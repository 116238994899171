import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { UPDATE_POOL_STATUS_TO_ASSIGNED } from '../../graphql/mutations';
import { GET_CARRIERS, GET_POOL, GET_POOLS } from '../../graphql/queries';
import H2 from '../../atoms/titles/H2';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import chevron from '../../statics/ico/chevron__grey.svg';
import Loader from '../../atoms/Loader';

export const getInternationalPhoneNumber = phoneNumber =>
  `+33${phoneNumber.toString().slice(-9)}`;

const SubTitleStyle = styled.div`
  display: flex;
  margin: 0 -12px 26px;

  > p {
    position: relative;
    padding: 0px 12px;
    font-size: 12px;
    color: ${({ theme }) => theme.grey100};

    &.active {
      color: ${({ theme }) => theme.grey700};
      font-weight: 700;
    }

    &:first-child:after {
      content: '';
      position: absolute;
      right: -6px;
      background: url(${chevron}) no-repeat center center transparent;
      width: 10px;
      height: 10px;
      transform: rotate(-90deg);
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: 100%;
    }
  }
`;

const AssignedPoolConfirmation = ({ closeModal, id }) => {
  const [step, setStep] = useState(1);
  const [variables, setVariables] = useState({});

  const handleOnSubmitFirstStep = values => {
    setVariables({ ...values, id });
    setStep(2);
  };

  const handleConfirm = updatePoolStatusToAssigned => async () => {
    try {
      await updatePoolStatusToAssigned({ variables });
      toast.success('Le lot a été affecté');
    } catch (err) {
      toast.error(
        "Le lot n'a pas pu être affecter à cause d'une erreur technique",
      );
      throw err;
    }
  };

  return (
    <Query query={GET_CARRIERS}>
      {({
        loading: loadingCarriers,
        error: errorCarriers,
        data: dataCarriers,
      }) => {
        if (loadingCarriers) return <Loader inline />;
        if (errorCarriers) return `Error! ${errorCarriers.message}`;

        const { carriers } = dataCarriers;
        return (
          <Query query={GET_POOL} variables={{ id }}>
            {({ loading: loadingPool, error: errorPool, data: dataPool }) => {
              if (errorPool) throw errorPool;
              if (loadingPool || !dataPool || !dataPool.pool)
                return <Loader inline />;
              const { pool } = dataPool;

              return (
                <Mutation
                  mutation={UPDATE_POOL_STATUS_TO_ASSIGNED}
                  refetchQueries={[
                    {
                      query: GET_POOLS,
                      variables: { type: 'RETURN', status: ['CREATED'] },
                    },
                    {
                      query: GET_POOLS,
                      variables: { type: 'DELEGATION', status: ['CREATED'] },
                    },
                  ]}
                >
                  {updatePoolStatusToAssigned => (
                    <div>
                      <H2 blue>Affecter le lot</H2>

                      <SubTitleStyle>
                        <p className="active">Choix du transporteur</p>
                        <p className={step === 2 ? 'active' : ''}>Transfert</p>
                      </SubTitleStyle>

                      {step === 1 && (
                        <StepOne
                          onRequestClose={closeModal}
                          onSubmit={handleOnSubmitFirstStep}
                          pool={pool}
                          carriers={carriers}
                        />
                      )}

                      {step === 2 && (
                        <StepTwo
                          carrier={
                            carriers.find(c => variables.carrier === c.id).name
                          }
                          pool={pool}
                          onRequestClose={closeModal}
                          onUpdatePoolStatusToAssigned={handleConfirm(
                            updatePoolStatusToAssigned,
                          )}
                        />
                      )}
                    </div>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

AssignedPoolConfirmation.propTypes = {
  id: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AssignedPoolConfirmation;
