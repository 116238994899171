import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

import H1 from '../../atoms/titles/H1';
import Input from '../../atoms/Input';
import ButtonLink from '../../atoms/ButtonLink';
import Button from '../../atoms/Button';
import AuthLayout from '../../layouts/Auth';

const FormStyle = styled.form`
  width: 300px;
  height: 340px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Title = styled(H1)`
  text-align: center;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 70px;
`;

export default class MySignIn extends SignIn {
  state = {
    username: '',
    password: '',
    error: null,
  };

  input = event => {
    this.setState({ [event.target.name]: event.target.value, error: null });
  };

  login = async event => {
    const { username, password } = this.state;
    event.preventDefault();

    // If it's a phone number with 10 digits, then add the international prefix for Cognito
    const login = /^\d{10}$/.test(username)
      ? `+33${username.toString().slice(-9)}`
      : username;

    try {
      const user = await Auth.signIn(login, password);

      if (
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        this.changeState('requireNewPassword', user);
      } else {
        const userWithData = await Auth.currentAuthenticatedUser();
        this.changeState('signedIn', userWithData);
      }
    } catch (err) {
      if ('code' in err && err.code === 'NotAuthorizedException') {
        this.setState({
          error: 'Adresse e-mail et/ou mot de passe non valide',
        });
      }
    }
  };

  render() {
    const { error } = this.state;

    if (!this._validAuthStates.includes(this.props.authState)) {
      this._isHidden = true;
      return null;
    }

    return (
      <AuthLayout>
        <FormStyle onSubmit={this.login}>
          <Title>Connexion</Title>

          <Input
            name="username"
            onChange={this.input}
            value={this.state.username}
            type="text"
            placeholder="E-mail"
            label="E-mail"
            error={error}
            required
          />

          <Input
            type="password"
            name="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            onChange={this.input}
            value={this.state.password}
            error={error}
            required
          />

          <SubmitButtonContainer>
            <div>
              <Button type="submit">Se connecter</Button>
            </div>

            <ButtonLink
              type="button"
              onClick={() => this.changeState('forgotPassword')}
            >
              Mot de passe oublié
            </ButtonLink>
          </SubmitButtonContainer>
        </FormStyle>
      </AuthLayout>
    );
  }
}
