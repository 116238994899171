import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconHeifer from '../statics/ico/heifer.svg';

const FarmDelegationInfosStyle = styled.div`
  background-color: ${({ theme }) => theme.blue500};
  padding: 10px 30px;
  display: flex;
  align-items: center;

  .iconHeifer {
    width: 20px;
    margin-right: 10px;
  }

  .delegation__text {
    font-size: 16px;
    color: #fff;
    margin-right: 40px;

    > span {
      font-weight: bold;
    }
  }
`;

const FarmDelegationInfos = ({
  poolsPerYear,
  delegationProgress,
  capacityPerPool,
  delegPerYear,
}) => {
  const displayDelegPerYear = delegPerYear !== null;
  const displayCapacity = capacityPerPool !== null && poolsPerYear !== null;
  const capacityPerYear = +capacityPerPool * +poolsPerYear;

  return (
    <FarmDelegationInfosStyle>
      <img className="iconHeifer" src={iconHeifer} alt="Heifer" />
      <p className="delegation__text">
        <span>{delegationProgress} </span>
        {`délégation${delegationProgress > 1 ? 's' : ''} en cours`}
      </p>

      {displayDelegPerYear && (
        <p className="delegation__text">
          <span>{delegPerYear} </span>
          délégation
          {delegPerYear > 0 ? 's' : ''} / an
        </p>
      )}

      {displayCapacity && (
        <>
          <p className="delegation__text">
            <span>{capacityPerYear} </span>
            place
            {capacityPerYear > 1 ? 's' : ''} / an
          </p>

          {capacityPerYear > 0 && (
            <p
              className="delegation__text"
              data-cy="FarmDelegationInfos__delegPerYearText"
            >
              capacité d&apos;accueil :{' '}
              <span>
              {poolsPerYear} lot
                {poolsPerYear > 1 ? 's' : ''} de {capacityPerPool} génisse
                {capacityPerPool > 1 ? 's' : ''}
            </span>
            </p>
          )}
        </>
      )}
    </FarmDelegationInfosStyle>
  );
};

FarmDelegationInfos.defaultProps = {
  capacityPerPool: null,
  delegationProgress: null,
  poolsPerYear: null,
  delegPerYear: null,
};

FarmDelegationInfos.propTypes = {
  delegPerYear: PropTypes.number,
  delegationProgress: PropTypes.number,
  capacityPerPool: PropTypes.number,
  poolsPerYear: PropTypes.number,
};
export default FarmDelegationInfos;
