const Theme = {
  grey700: '#666872',
  grey500: '#393a40',
  grey300: '#e6e6eb',
  grey200: 'rgba(230, 230, 235, 0.4)',
  grey100: '#b9babe',
  grey000: '#f1f3ff',
  white: '#ffffff',
  blue700: '#1AA2CA',
  blue500: '#33b6dc',
  blue400: '#C1EBF8',
  blue300: '#dcf6fe',
  blue100: '#eefafe',
  red300: '#ffe0e3',
  red600: '#e63f50',
  red900: '#e30c22',
  blueberry500: '#36417f',
  green300: '#ddffeb',
  green500: '#46b573',
  orange300: '#fff3da',
  orange500: '#ffaa00',

  darkShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
  overlayBgColor: 'rgba(57, 58, 64, 0.7)',
  overlayErrorBgColor: 'rgba(230, 63, 80, 0.75)',

  transition: 'all ease-in-out 300ms',

  purpleGradation: 'linear-gradient(to top, #36417f, #4658b9)',
  greyGradation: 'linear-gradient(to top, #666872, #85868e);',
  blueGradation: 'linear-gradient(to top, #01aac6, #33b6dc, #33b6dc);',
  greenGradation: 'linear-gradient(to top, #46b573, #4ccf81);',
};

export default Theme;
