import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { compareDesc, format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import InfoLabel from '../atoms/InfoLabel';
import DefinitionText from '../atoms/DefinitionText';
import IconButton from '../atoms/IconButton';
import H2 from '../atoms/titles/H2';
import Modal from '../atoms/Modal';
import RemoveHeiferEvent from '../organisms/RemoveHeiferEvent';
import {
  EVENT_TYPES,
  EVENT_TYPES_BREEDERS_CAN_EDIT,
  NON_REMOVABLE_EVENTS_TYPE,
} from '../constants';
import UserContext from '../context';
import SaveEventForm from '../organisms/SaveEventForm';
import { getDescription, getExtraInfos, getTypeColor } from '../commons/events';

const EventGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 20px;

  &:not(:first-child):before,
  &:not(:last-child):after {
    content: '';
    position: absolute;
    width: 7px;
    height: 50%;
    background-color: ${({ theme }) => theme.grey300};
    left: -18px;
    border-radius: 5px;
  }

  &:not(:first-child):before {
    bottom: calc(50% + 16px);
  }

  &:not(:last-child):after {
    top: calc(50% + 16px);
  }

  .EventItem__container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.darkShadow};
    position: relative;
    opacity: ${({ isDisabled }) => (isDisabled && 0.5) || 1};
    max-width: 420px;

    &:after {
      content: '\\2022';
      position: absolute;
      left: -20px;
      top: 0;
      color: ${({ color, theme, isDisabled }) =>
        (isDisabled && theme.grey100) ||
        (color === 'blue' && theme.blue500) ||
        (color === 'green' && theme.green500) ||
        (color === 'orange' && theme.orange500) ||
        (color === 'red' && theme.red600) ||
        (color === 'purple' && theme.blueberry500)};
      bottom: 0;
      margin: auto;
      height: 10px;
      line-height: 9px;
      font-size: 22px;
    }

    > * {
      padding: 10px;
    }
  }

  .picto-container {
    margin-left: 11px;
    display: none;
  }

  &:focus,
  &:hover {
    .picto-container {
      display: block;
    }
  }
`;

const LightNote = styled.p`
  margin-top: -15px;
`;

const HeiferEvents = ({
  idHeifer,
  isDisabled,
  returnEstimatedWeight,
  events,
}) => {
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [isUpdateEventModalOpen, setUpdateEventModalOpen] = useState(false);
  const { userGroups } = useContext(UserContext);

  const getSortedEvents = () => {
    return events.sort((a, b) =>
      compareDesc(parseISO(a.date), parseISO(b.date)),
    );
  };

  const isAdmin = userGroups.includes('admin');

  const isEventRemovable = event =>
    isAdmin && !NON_REMOVABLE_EVENTS_TYPE.find(elt => elt === event.type);

  const canEditThisTypeOfEvent = eventType => {
    if (isAdmin) {
      return true;
    }

    const isBreeder = userGroups.includes('breeder');
    if (!isBreeder) {
      return false;
    }

    return !!EVENT_TYPES_BREEDERS_CAN_EDIT.find(elt => elt === eventType);
  };

  const openEventEditionModal = event => {
    setSelectedEvent(event);
    setUpdateEventModalOpen(true);
  };

  const closeEventEditionModal = () => {
    setSelectedEvent(null);
    setUpdateEventModalOpen(false);
  };

  return (
    <div>
      <H2>Événements</H2>
      <EventGrid>
        {getSortedEvents().map(event => {
          const description = getDescription(event, events);
          const extra = getExtraInfos(event, returnEstimatedWeight);
          const color = getTypeColor(event.type);

          return (
            <EventItem isDisabled={isDisabled} key={event.id} color={color}>
              <div className="EventItem__container">
                <DefinitionText
                  term={format(parseISO(event.date), 'dd/MM/yyyy')}
                  desc={description}
                  extra={extra}
                />

                {event.type === EVENT_TYPES.RETURN && returnEstimatedWeight && (
                  <div>
                    {returnEstimatedWeight < 540 && (
                      <InfoLabel alert noMargin>
                        Poids de retour très insuffisant
                      </InfoLabel>
                    )}
                    {returnEstimatedWeight >= 540 &&
                      returnEstimatedWeight < 570 && (
                        <InfoLabel warning noMargin>
                          Poids de retour insuffisant
                        </InfoLabel>
                      )}
                    {returnEstimatedWeight >= 570 && (
                      <InfoLabel great noMargin>
                        Poids de retour suffisant
                      </InfoLabel>
                    )}
                  </div>
                )}

                {(event.type === EVENT_TYPES.OTHER ||
                  event.type === EVENT_TYPES.BREEDING_TIP) &&
                  event.note && <LightNote>{event.note}</LightNote>}
                {event.type === EVENT_TYPES.SOLD && (
                  <LightNote>Vendu</LightNote>
                )}
              </div>

              {isEventRemovable(event) && !isDisabled && (
                <div className="picto-container">
                  <IconButton
                    icon="delete"
                    title="Supprimer l'évènement"
                    onClick={() => {
                      setSelectedEvent(event);
                      setRemoveModalOpen(!isRemoveModalOpen);
                    }}
                  />
                </div>
              )}

              {canEditThisTypeOfEvent(event.type) && !isDisabled && (
                <div className="picto-container">
                  <IconButton
                    title="Modifier l'évènement"
                    icon="edit"
                    onClick={() => openEventEditionModal(event)}
                  />
                </div>
              )}
            </EventItem>
          );
        })}

        <Modal
          isOpen={isUpdateEventModalOpen}
          onRequestClose={() => closeEventEditionModal()}
        >
          {selectedEvent !== null &&
            !isDisabled &&
            canEditThisTypeOfEvent(selectedEvent.type) && (
              <SaveEventForm
                closeModal={() => closeEventEditionModal()}
                heiferId={idHeifer}
                allowRemoveHeiferIfDeath={false}
                handleDelete={() => {}}
                event={selectedEvent}
                isEdit
              />
            )}
        </Modal>

        <Modal
          isOpen={isRemoveModalOpen}
          onRequestClose={() => setRemoveModalOpen(!isRemoveModalOpen)}
        >
          {selectedEvent !== null && (
            <RemoveHeiferEvent
              toggleModal={() => setRemoveModalOpen(!isRemoveModalOpen)}
              eventId={selectedEvent.id}
              type={selectedEvent.type}
              idHeifer={idHeifer}
            />
          )}
        </Modal>
      </EventGrid>
    </div>
  );
};

HeiferEvents.defaultProps = {
  returnEstimatedWeight: null,
};

HeiferEvents.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  idHeifer: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ).isRequired,
  returnEstimatedWeight: PropTypes.number,
};

export default HeiferEvents;
