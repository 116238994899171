import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/fr';

import { formatDate } from '../../commons/dates';
import Calendar from '../../statics/ico/calendar.svg';
import CrossIcon from '../../statics/ico/cross.svg';
import ArrowIcon from './ArrowIcon';
import Select from '../Select';
import 'react-dates/lib/css/_datepicker.css';
import './index.css';

moment.locale('fr');

const DatePickerStyle = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 7px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
    font-size: 12px;
  }
  .error {
    font-size: 11px;
    color: ${({ theme }) => theme.red900};
    margin-top: 5px;
    display: block;
  }
`;

class DatePicker extends PureComponent {
  state = {
    isFocused: null,
  };

  focusHandle = focused => {
    this.setState({ isFocused: focused });
  };

  onClose = () => {
    this.setState({ isFocused: null });
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    const yearValues = [];

    for (let i = moment().year() - 20; i <= moment().year(); i += 1) {
      yearValues.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <Select
            value={month.month()}
            name="datePickerMonthSelector"
            onChange={e => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Select
            value={month.year()}
            name="datePickerYearSelector"
            onChange={e => onYearSelect(month, e.target.value)}
          >
            {yearValues}
          </Select>
        </div>
      </div>
    );
  };

  render = () => {
    const {
      info,
      id,
      placeHolder,
      isModal,
      highlightDay,
      date,
      onChange,
      blockDay,
      outsideRange,
      numberOfMonths,
      customMonthElement,
      error,
      label,
    } = this.props;
    const { isFocused } = this.state;

    return (
      <DatePickerStyle>
        {label && <span className="label">{label}</span>}
        <SingleDatePicker
          id={id}
          placeholder={placeHolder}
          customInputIcon={<img src={Calendar} alt="calendar" />}
          inputIconPosition="after"
          date={date ? moment(date) : null}
          onDateChange={newDate => onChange(formatDate(newDate.toDate()))}
          focused={isFocused}
          onFocusChange={({ focused }) => this.focusHandle(focused)}
          displayFormat="DD/MM/YY"
          daySize={30}
          calendarInfoPosition="top"
          renderCalendarInfo={() => (
            <>
              {isModal && <CloseButton onClose={this.onClose} />}
              <div className="datePicker__info">{info}</div>
            </>
          )}
          withPortal={isModal}
          hideKeyboardShortcutsPanel
          navPrev={<ArrowIcon direction="left" />}
          navNext={<ArrowIcon direction="right" />}
          isDayHighlighted={day => highlightDay(day)}
          isDayBlocked={day => blockDay(day)}
          isOutsideRange={day => outsideRange(day)}
          numberOfMonths={numberOfMonths}
          renderMonthElement={
            customMonthElement ? this.renderMonthElement : null
          }
        />
        {error && <span className="error">{error}</span>}
      </DatePickerStyle>
    );
  };
}

DatePicker.defaultProps = {
  info: null,
  placeHolder: 'JJ/MM/AA',
  isModal: false,
  highlightDay: () => {},
  blockDay: () => {},
  onChange: () => {},
  outsideRange: () => false,
  date: null,
  error: null,
  label: null,
  numberOfMonths: 2,
  customMonthElement: false,
};

DatePicker.propTypes = {
  numberOfMonths: PropTypes.oneOf([1, 2]),
  info: PropTypes.node,
  id: PropTypes.string.isRequired,
  date: PropTypes.string,
  error: PropTypes.string,
  placeHolder: PropTypes.string,
  isModal: PropTypes.bool,
  customMonthElement: PropTypes.bool,
  highlightDay: PropTypes.func,
  onChange: PropTypes.func,
  blockDay: PropTypes.func,
  outsideRange: PropTypes.func,
  label: PropTypes.string,
};

export default DatePicker;

/* CloseButton */
const CloseButtonStyle = styled('button')`
  position: absolute;
  background: transparent;
  border: none;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
`;

const CloseButton = ({ onClose }) => (
  <CloseButtonStyle onClick={onClose}>
    <img src={CrossIcon} alt="close" />
  </CloseButtonStyle>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};
