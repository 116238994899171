import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import loader from '../statics/loader.gif';

const LoaderStyle = styled.div`
  position: ${({ inline }) => (inline ? '' : 'fixed')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ inline }) => (inline ? 'transparent' : '#ffffff80')};
  backdrop-filter: blur(0.5rem);

  img {
    max-height: 100%;
  }
`;

const Loader = ({ inline }) => (
  <LoaderStyle inline={inline}>
    <img src={loader} alt="Loader" />
  </LoaderStyle>
);

Loader.defaultProps = {
  inline: false,
};

Loader.propTypes = {
  inline: PropTypes.bool,
};

export default Loader;
