import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import Modal from '../atoms/Modal';
import ContainerLarge from '../layouts/ContainerLarge';
import { GET_FARM, GET_FARMS_SUMMARY } from '../graphql/queries';
import FarmDelegationInfos from '../molecules/FarmDelegationInfos';
import FarmInfos from '../molecules/FarmInfos';
import PageHeader from '../molecules/PageHeader';
import HeifersDetailedList from '../molecules/HeifersDetailedList/ProducerView';
import Loader from '../atoms/Loader';
import { ReactComponent as HeiferIcon } from '../statics/ico/heifer_blue.svg';
import CreateHeiferForm from './CreateHeiferForm';
import UserContext from '../context';

const FarmDetailsStyle = styled.div`
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
`;

const FarmDetails = ({ farmId }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { userGroups } = useContext(UserContext);
  const isAdmin = userGroups.includes('admin');
  if (!farmId) return <p>No farm ID</p>;

  return (
    <Query
      query={GET_FARM}
      fetchPolicy="network-only"
      variables={{ id: farmId }}
      skip={!farmId}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error.message);

        const { farm } = data;

        const ids = [
          ...new Set(farm.heifers.map(h => h.producer_summary.id)),
          ...new Set(farm.heifers.map(h => h.breeder_summary.id)),
        ].filter(id => id);

        return (
          <Query
            query={GET_FARMS_SUMMARY}
            variables={{
              ids,
            }}
            fetchPolicy="network-only"
            skip={ids.length === 0}
          >
            {farmsQuery => {
              if (farmsQuery.loading) return <Loader />;
              if (farmsQuery.error) throw new Error(farmsQuery.error.message);

              return (
                <>
                  <ContainerLarge displayHeader color="grey">
                    <FarmDetailsStyle>
                      <FarmInfos
                        farm={farm}
                        showAddHeiferButton={false}
                        viewStyle="large"
                      />

                      <FarmDelegationInfos
                        delegPerYear={farm.deleg_per_year}
                        delegationProgress={farm.deleg_in_progress}
                        poolsPerYear={farm.pools_per_year}
                        capacityPerPool={farm.capacity_per_pool}
                      />
                    </FarmDetailsStyle>

                    <section>
                      <PageHeader
                        title="Génisses"
                        icon={<HeiferIcon />}
                        primaryButtonLabel="Déclarer une naissance"
                        primaryButtonAction={() => setIsCreateModalOpen(true)}
                      />
                      <HeifersDetailedList
                        heifers={farm.heifers}
                        farmType={farm.farm_type}
                      />
                    </section>
                  </ContainerLarge>
                  {'tag_id' in farm && farm.tag_id && (
                    <Modal
                      isOpen={isCreateModalOpen}
                      onRequestClose={() => setIsCreateModalOpen(false)}
                    >
                      <CreateHeiferForm
                        toggleModal={() => setIsCreateModalOpen(false)}
                        farmId={farm.id}
                        farmName={farm.farm_name}
                        tagId={farm.tag_id}
                        source="producer"
                        isAdmin={isAdmin}
                        isBio={farm.farm_type === 'BIO'}
                      />
                    </Modal>
                  )}
                </>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

FarmDetails.propTypes = {
  farmId: PropTypes.string,
};

FarmDetails.defaultProps = {
  farmId: null,
};

export default FarmDetails;
