import React, { Component, useContext } from 'react';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import { toast } from 'react-toastify';
import { compareAsc, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { GET_DELEGATION_DASHBOARD } from '../graphql/queries';
import {
  ADD_HEIFERS_TO_DELEGATION_POOL,
  DELETE_HEIFER_FROM_POOL,
} from '../graphql/mutations';
import List from '../molecules/DelegationPoolWaitingList';
import HeifersWaitingList from '../molecules/HeifersWaitingList';
import HeifersWaitingHeader from '../molecules/HeifersWaitingHeader';
import PageHeader from '../molecules/PageHeader';
import CreateHeiferForm from './CreateHeiferForm';
import Modal from '../atoms/Modal';
import { ReactComponent as MapPinIcon } from '../statics/ico/map-pin.svg';
import { ReactComponent as InboxIcon } from '../statics/ico/inbox.svg';
import Loader from '../atoms/Loader';
import UserContext from '../context';

const HomeStyle = styled.div`
  display: flex;
  min-height: calc(100vh - 90px);
`;

const PoolDelegationWaitingStyle = styled.div`
  flex: 1;
  padding: 30px;
  background-color: ${({ theme }) => theme.grey200};
`;

const HeifersWaitingStyle = styled.div`
  flex: 1;
  padding: 30px;
`;

class DelegationPoolsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heifersSelected: [],
      isCreateHeiferModalOpen: false,
    };
  }

  toggleCreateHeiferModal = () => {
    const { isCreateHeiferModalOpen } = this.state;

    this.setState({ isCreateHeiferModalOpen: !isCreateHeiferModalOpen });
  };

  addHeifersToPool = async idPool => {
    const { heifersSelected } = this.state;
    const { client } = this.props;

    try {
      await client.mutate({
        mutation: ADD_HEIFERS_TO_DELEGATION_POOL,
        variables: {
          idHeifers: heifersSelected,
          idPool,
        },
        refetchQueries: [{ query: GET_DELEGATION_DASHBOARD }],
      });
      this.setState({ heifersSelected: [] });

      toast.success(
        `Délégation mise à jour avec ${heifersSelected.length} nouvelle(s) génisse(s)`,
      );
    } catch (err) {
      toast.error(
        "La délégation n'a pas été mise à jour à cause d'une erreur technique",
      );
      throw err;
    }
  };

  handleOnHeiferSelected = id => {
    const { heifersSelected } = this.state;

    if (heifersSelected.includes(id)) {
      this.setState({
        heifersSelected: heifersSelected.filter(heiferId => heiferId !== id),
      });
    } else {
      heifersSelected.push(id);
      this.setState({ heifersSelected });
    }
  };

  handleOnDeletePoolHeifer = async (idPool, idHeifer) => {
    const { client } = this.props;

    try {
      await client.mutate({
        mutation: DELETE_HEIFER_FROM_POOL,
        variables: {
          idHeifer,
          idPool,
        },
        refetchQueries: [{ query: GET_DELEGATION_DASHBOARD }],
      });
      toast.success('La génisse a été enlevé du lot');
    } catch (err) {
      toast.error(
        "La génisse n'a pas été enlevé du lot à cause d'une erreur technique",
      );
      throw err;
    }
  };

  sortPoolsByTransferDateAsc = pools => {
    return pools.sort((a, b) =>
      compareAsc(parseISO(a.transfer_date), parseISO(b.transfer_date)),
    );
  };

  render = () => {
    const { heifersSelected, isCreateHeiferModalOpen } = this.state;
    const { isAdmin } = this.props;

    return (
      <Query
        query={GET_DELEGATION_DASHBOARD}
        fetchPolicy="network-only"
        pollInterval={60000}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw error;

          const { heifers } = data;
          const pools = data.pools
            ? data.pools.map(pool => {
                return {
                  ...pool,
                  heifers: pool.heifers || [],
                };
              })
            : [];

          return (
            <div>
              <HomeStyle>
                <HeifersWaitingStyle>
                  <PageHeader
                    title="Génisses en attente"
                    icon={<InboxIcon />}
                    primaryButtonLabel="Ajouter une génisse"
                    primaryButtonAction={this.toggleCreateHeiferModal}
                  />
                  <HeifersWaitingHeader
                    onFormSubmit={this.addHeifersToPool}
                    pools={pools}
                    lengthHeifersSelected={heifersSelected.length}
                  />
                  <HeifersWaitingList
                    onHeiferSelected={this.handleOnHeiferSelected}
                    heifers={heifers || []}
                    heifersSelected={heifersSelected}
                  />
                </HeifersWaitingStyle>
                <PoolDelegationWaitingStyle>
                  <PageHeader title="Lots à affecter" icon={<MapPinIcon />} />
                  <List
                    items={this.sortPoolsByTransferDateAsc(pools)}
                    onDeletePoolHeifer={this.handleOnDeletePoolHeifer}
                  />
                </PoolDelegationWaitingStyle>
              </HomeStyle>
              <Modal
                isOpen={isCreateHeiferModalOpen}
                onRequestClose={this.toggleCreateHeiferModal}
              >
                <CreateHeiferForm
                  toggleModal={this.toggleCreateHeiferModal}
                  isAdmin={isAdmin}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    );
  };
}

DelegationPoolsDashboard.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

// Hooks cannot be used in a component class.
// Then we wrap it into a function which returns the component itself
const DelegationPoolsDashboardFunc = props => {
  const { userGroups } = useContext(UserContext);
  const isAdmin = userGroups.includes('admin');

  const client = { props };
  const DecoratedDelegationPoolsDashboard = withApollo(
    DelegationPoolsDashboard,
  );

  return (
    <DecoratedDelegationPoolsDashboard isAdmin={isAdmin} client={client} />
  );
};

export default DelegationPoolsDashboardFunc;
