import React from 'react';
import { Router } from '@reach/router';

import DelegationPoolsDashboard from '../organisms/DelegationPoolsDashboard';
import Farms from '../organisms/Farms';
import FarmDetails from '../organisms/FarmDetails';
import HeiferDetails from '../organisms/HeiferDetails';
import ManagePoolsDelegation from '../organisms/ManagePoolsDelegation';
import PoolDelegationDetails from '../organisms/PoolDelegationDetails';
import PoolReturnDetails from '../organisms/PoolReturnDetails';
import NotFound from '../organisms/NotFound';
import ReturnPoolsDashboard from '../organisms/ReturnPoolsDashboard';
import AdminTestsDashboard from '../organisms/AdminTestsDashboard';
import ManageHeiferReturns from '../organisms/ManageHeiferReturns';

const Admin = () => (
  <Router primary={false}>
    <DelegationPoolsDashboard path="/" />
    <ReturnPoolsDashboard path="/returns" />
    <ManagePoolsDelegation path="manage/delegations" />
    <PoolDelegationDetails path="manage/delegations/:poolId" />
    <ManageHeiferReturns path="manage/returns" />
    <PoolReturnDetails path="manage/returns/:poolId" />
    <Farms path="view/farms" />
    <FarmDetails path="view/farms/:farmId" />
    <HeiferDetails path="view/farms/heifer/:heiferId" />
    <AdminTestsDashboard path="admin/tests" />
    <NotFound default />
  </Router>
);

export default Admin;
