import React, { useContext } from 'react';
import { Router } from '@reach/router';
import { Query } from 'react-apollo';

import { GET_FARM_ID_BY_EDE } from '../graphql/queries';
import UserContext from '../context';
import ProducerHome from '../organisms/ProducerHome';
import HeiferDetails from '../organisms/HeiferDetails';
import ProducerTransfers from '../organisms/ProducerTransfers';
import PoolDelegationDetails from '../organisms/PoolDelegationDetails';
import PoolReturnDetails from '../organisms/PoolReturnDetails';
import Loader from '../atoms/Loader';

const Producer = () => {
  const { username } = useContext(UserContext);
  const ede = username.attributes['custom:ede_number'];

  return (
    <Query query={GET_FARM_ID_BY_EDE} variables={{ ede }} skip={!ede}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error.message);
        const { farm } = data;

        return (
          <Router primary={false}>
            <ProducerHome path="/" farmId={farm.id} />
            <HeiferDetails path="heifer/:heiferId" />
            <ProducerTransfers
              path="transfer/delegations"
              farmId={farm.id}
              type="DELEGATION"
            />
            <PoolDelegationDetails
              path="transfer/delegations/:poolId"
              heifersByProducerId={farm.id}
            />
            <ProducerTransfers
              path="transfer/returns"
              farmId={farm.id}
              type="RETURN"
            />
            <PoolReturnDetails
              path="transfer/returns/:poolId"
              heifersByProducerId={farm.id}
            />
          </Router>
        );
      }}
    </Query>
  );
};

export default Producer;
