import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { UPDATE_POOL_STATUS_TO_CLOSED } from '../graphql/mutations';
import { GET_POOL } from '../graphql/queries';

const Container = styled.div`
  .highlight {
    color: ${({ theme }) => theme.blue500};
    background-color: ${({ theme }) => theme.blue300};
    padding: 10px;
    position: relative;
    margin-top: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -30px;
      width: 30px;
      height: 100%;
      background-color: ${({ theme }) => theme.blue300};
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -30px;
      width: 30px;
      height: 100%;
      background-color: ${({ theme }) => theme.blue300};
    }
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const ClosePoolForm = ({ onRequestClose, poolId, type }) => (
  <Mutation
    mutation={UPDATE_POOL_STATUS_TO_CLOSED}
    refetchQueries={[
      {
        query: GET_POOL,
        variables: {
          id: poolId,
          ...(type === 'DELEGATION' && { heifersByProducerId: null }),
        },
      },
    ]}
    awaitRefetchQueries
  >
    {(updatePoolStatusToClosed, { loading }) => (
      <Container>
        <H2 blue>Clore le transfert</H2>
        <p>
          Êtes-vous sûr de vouloir clore le transfert ? Cette action est
          irréversible et ne permettra plus d’ajouter de réserves aux génisses.
        </p>
        <p className="highlight">
          Le clôture générera les factures associées, envoyées par mail à la fin
          du mois.
        </p>
        <div className="buttonBar">
          <Button
            type="button"
            disabled={loading}
            onClick={onRequestClose}
            secondary
          >
            Annuler
          </Button>
          <Button
            data-cy="removeFarmForm__deleteButton"
            type="submit"
            disabled={loading}
            onClick={async () => {
              try {
                await updatePoolStatusToClosed({
                  variables: { id: poolId },
                });
                onRequestClose();
                toast.success('Le lot a été clos');
              } catch (err) {
                toast.error(
                  "Le lot n'a pas été clos à cause d'une erreur technique",
                );
              }
            }}
          >
            Valider
          </Button>
        </div>
      </Container>
    )}
  </Mutation>
);

ClosePoolForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  poolId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['DELEGATION', 'RETURN']).isRequired,
};

export default ClosePoolForm;
