import React from 'react';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { Rehydrated } from 'aws-appsync-react';
import PropTypes from 'prop-types';
import {
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';

// Define the interface Event and all the possible types.
// Used by Apollo GraphQL in order to define which types are used with unions
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Event',
          possibleTypes: [
            {
              name: 'EventOTHER',
            },
            {
              name: 'EventBREEDING_TIP',
            },
            {
              name: 'EventBIRTH',
            },
            {
              name: 'EventINSEMINATION',
            },
            {
              name: 'EventRETURN',
            },
            {
              name: 'EventCALVING',
            },
            {
              name: 'EventDEATH',
            },
            {
              name: 'EventSONOGRAM',
            },
            {
              name: 'EventMEASUREMENT',
            },
            {
              name: 'EventDISEASE',
            },
            {
              name: 'EventDELEGATION',
            },
            {
              name: 'EventABBATOIR',
            },
            {
              name: 'EventSOLD',
            },
          ],
        },
      ],
    },
  },
});

const Provider = ({ children }) => {
  const client = new AWSAppSyncClient({
    disableOffline: true,
    url: process.env.REACT_APP_APPSYNC_URL,
    region: process.env.REACT_APP_APPSYNC_DEFAULT_REGION,
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken,
    },
    cacheOptions: {
      fragmentMatcher,
      dataIdFromObject: object => {
        if (object.__typename === 'FarmSummary') {
          return `Farm:${object.id}`;
        }
        return defaultDataIdFromObject(object);
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Rehydrated>{children}</Rehydrated>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
