import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import ContainerLarge from '../layouts/ContainerLarge';
import PageHeader from '../molecules/PageHeader';
import { GET_PRODUCER_POOLS } from '../graphql/queries';
import Loader from '../atoms/Loader';
import { ReactComponent as RefreshIcon } from '../statics/ico/refresh.svg';
import ProducerDelegationList from '../molecules/ProducerDelegationList';
import ProducerReturnPoolsList from '../molecules/ProducerReturnPoolsList';

export const buildProducerDelegationTransferData = pools =>
  pools.reduce((acc, pool) => {
    const { heifers, ...rest } = pool;
    pool.heifers.map(h =>
      acc.push({
        ...rest,
        id_heifer: h.id_heifer,
        breeder_name: h.breeder.farm_name,
      }),
    );

    return acc;
  }, []);

const ProducerTransfers = ({ farmId, type }) => (
  <Query
    query={GET_PRODUCER_POOLS}
    fetchPolicy="network-only"
    variables={{ producerId: farmId, type }}
    skip={!farmId}
  >
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error) throw new Error(error.message);

      return (
        <ContainerLarge>
          <PageHeader
            icon={<RefreshIcon />}
            title={
              type === 'DELEGATION'
                ? 'Délégations de lots'
                : 'Retours de génisse'
            }
          />
          {type === 'DELEGATION' ? (
            <ProducerDelegationList
              pools={buildProducerDelegationTransferData(data.pools)}
            />
          ) : (
            <ProducerReturnPoolsList list={data.pools} />
          )}
        </ContainerLarge>
      );
    }}
  </Query>
);

ProducerTransfers.propTypes = {
  farmId: PropTypes.string,
  type: PropTypes.oneOf(['DELEGATION', 'RETURN']).isRequired,
};

ProducerTransfers.defaultProps = {
  farmId: null,
};

export default ProducerTransfers;
