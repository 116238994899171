import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation, Query, withApollo } from 'react-apollo';
import { toast } from 'react-toastify';

import { GET_TESTS } from '../graphql/queries';
import Loader from '../atoms/Loader';
import TestList from '../molecules/TestList';
import Button from '../atoms/Button';
import { ReactComponent as InboxIcon } from '../statics/ico/file.svg';
import { ReactComponent as CalendarIcon } from '../statics/ico/calendar.svg';
import PageHeader from '../molecules/PageHeader';
import {
  CRON_CHECK_OLD_HEIFERS,
  CRON_POOLS_TRANSFER_DATE,
  EXPORT_DATA,
  IN_DELEGATION_HEIFERS_DEPOSITS,
  SEND_MONTHLY_INVOICES,
} from '../graphql/mutations';
import Modal from '../atoms/Modal';

const MarginStyle = styled.div`
  margin: 30px;
`;

const HeaderStyle = styled.div`
  display: flex;
  .button {
    margin-right: 30px;
  }
`;

const PageStyle = styled.div`
  min-height: calc(100vh - 90px);
  display: flex;
`;

const ExportStyle = styled.div`
  flex: 1;
  padding: 30px;
  .path {
    margin-top: 30px;
  }
`;

const ImportStyle = styled.div`
  flex: 2;
  padding: 30px;
`;
const callFn = fn => async () => {
  try {
    await fn();
    toast.success(`l'appel a été réalisé avec succès'`);
  } catch (e) {
    console.error(e);
    toast.error(`Une erreur a empêché l'appel`);
  }
};
const importDataFn = (importData, setExportedPath) => async () => {
  try {
    const {
      data: { exportData: path },
    } = await importData({ variables: {} });
    setExportedPath(path);
    toast.success(
      `les données ont été chargées dans le dossier ${path} du ftp`,
    );
  } catch (e) {
    console.error(e);
    toast.error(`Une erreur a empêché d'exporter les données`);
  }
};

const NAMES = {
  e: 'éleveurs & naisseurs',
  g: 'génisses',
  m: 'lots',
  l: 'logs',
  r: 'reset de base',
};
const CheckMode = ({ mode, setMode, id, label }) => {
  const onToggle = () => {
    setMode(prev => (prev.includes(id) ? prev.replace(id, '') : prev + id));
  };
  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={mode.includes(id)}
        onChange={onToggle}
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};
CheckMode.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const AdminTestsDashboard = () => {
  const [selected, setSelected] = useState(null);
  const [mode, setMode] = useState('er');
  const [exportedPath, setExportedPath] = useState(null);
  const [isValidationModalOpen, setValidationModalOpen] = useState(false);

  return (
    <Query query={GET_TESTS} fetchPolicy="network-only" pollInterval={60000}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw error;

        const tests = data && data.tests;

        return (
          <>
            <MarginStyle>
              <PageHeader
                title="Déclenchement d'opérations"
                icon={<CalendarIcon />}
              />

              <HeaderStyle>
                <Mutation mutation={SEND_MONTHLY_INVOICES}>
                  {sendMonthlyInvoices => (
                    <Button
                      className="button"
                      onClick={callFn(sendMonthlyInvoices)}
                    >
                      Générer les factures mensuelles
                    </Button>
                  )}
                </Mutation>

                <Mutation mutation={IN_DELEGATION_HEIFERS_DEPOSITS}>
                  {inDelegationHeifersDeposits => (
                    <Button
                      className="button"
                      onClick={callFn(inDelegationHeifersDeposits)}
                    >
                      Générer les acomptes
                    </Button>
                  )}
                </Mutation>

                <Mutation mutation={CRON_POOLS_TRANSFER_DATE}>
                  {cronPoolsTransferDate => (
                    <Button
                      className="button"
                      onClick={callFn(cronPoolsTransferDate)}
                    >
                      Gérer les dates de transferts de lots
                    </Button>
                  )}
                </Mutation>
                <Mutation mutation={CRON_CHECK_OLD_HEIFERS}>
                  {cronPoolsTransferDate => (
                    <Button
                      className="button"
                      onClick={callFn(cronPoolsTransferDate)}
                    >
                      Vérification des génisses vieilles
                    </Button>
                  )}
                </Mutation>
              </HeaderStyle>
            </MarginStyle>

            <PageStyle>
              <ExportStyle>
                <PageHeader title="Export" icon={<InboxIcon />} />

                <Mutation mutation={EXPORT_DATA}>
                  {exportData => (
                    <Button onClick={importDataFn(exportData, setExportedPath)}>
                      Exporter les données courantes
                    </Button>
                  )}
                </Mutation>

                {exportedPath && (
                  <div className="path">{`(dernier dans ${exportedPath})`}</div>
                )}
              </ExportStyle>

              <ImportStyle>
                <PageHeader title="Import" icon={<InboxIcon />} />
                <MarginStyle>
                  <CheckMode
                    mode={mode}
                    setMode={setMode}
                    id="e"
                    label="Élevages / "
                  />
                  <CheckMode
                    mode={mode}
                    setMode={setMode}
                    id="g"
                    label="Génisses / "
                  />
                  <CheckMode
                    mode={mode}
                    setMode={setMode}
                    id="m"
                    label="Lots / "
                  />
                  <CheckMode
                    mode={mode}
                    setMode={setMode}
                    id="l"
                    label="Logs étendus / "
                  />
                  <CheckMode
                    mode={mode}
                    setMode={setMode}
                    id="r"
                    label="Reset base avant import"
                  />
                </MarginStyle>
                <MarginStyle>
                  {`Import avec : ${mode
                    .split('')
                    .map(m => NAMES[m])
                    .join(', ')}`}
                </MarginStyle>

                <TestList
                  tests={tests}
                  selected={selected}
                  setSelected={setSelected}
                  mode={mode}
                />
              </ImportStyle>
            </PageStyle>
            <Modal
              isOpen={isValidationModalOpen}
              onRequestClose={() => setValidationModalOpen(false)}
            >
              <div>test</div>
            </Modal>
          </>
        );
      }}
    </Query>
  );
};

AdminTestsDashboard.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withApollo(AdminTestsDashboard);
