import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';

const Container = styled.div`
  overflow-y: scroll;
  background-color: #fff;
  margin-bottom: 1px;
`;

const Item = styled(Link)`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.grey500};
  text-decoration: none;
  padding: 7px 12px;
`;

const HeifersList = ({ list }) => (
  <Container>
    {list.map(h => (
      <Item to={`/view/farms/heifer/${h.id}`} key={h.id}>
        Génisse {h.id_heifer}
      </Item>
    ))}
  </Container>
);

HeifersList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HeifersList;
