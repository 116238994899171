import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import icoChevron from '../statics/ico/chevron.svg';

const SelectStyle = styled.label`
  display: flex;
  flex-direction: ${({ labelNextTo }) => (labelNextTo ? 'row' : 'column')};
  align-items: ${({ labelNextTo }) => (labelNextTo ? 'center' : 'normal')};

  .label {
    margin-bottom: ${({ labelNextTo }) => (labelNextTo ? 0 : '7px')};
    margin-right: ${({ labelNextTo }) => (labelNextTo ? '10px' : 0)};
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
    font-size: 12px;
  }

  select {
    flex: 1 0 0%;
    appearance: none;
    border: 1px solid ${({ theme }) => theme.grey300};
    background: url(${icoChevron}) no-repeat calc(100% - 10px) center #fff;
    font-size: 14px;
    padding: 5px 30px 5px 10px;
    border-radius: 5px;
    color: ${({ theme }) => theme.grey500};

    option {
      color: ${({ theme }) => theme.grey500};
    }

    &.withError {
      border-color: ${({ theme }) => theme.red600};
    }

    &.placeholder {
      color: ${({ theme }) => theme.grey100};
    }

    &:focus {
      border-color: ${({ theme }) => theme.blue500};
    }
  }

  .error {
    font-size: 11px;
    color: ${({ theme }) => theme.red900};
    margin-top: 5px;
    display: block;
  }
`;

const Select = ({
  children,
  error,
  name,
  label,
  placeholder,
  value,
  labelNextTo,
  ...props
}) => (
  <SelectStyle htmlFor={name} labelNextTo={labelNextTo}>
    {label && <span className="label">{label}</span>}
    <select
      {...props}
      value={value === '' ? 'placeholder' : value}
      id={name}
      className={`${value === '' ? 'placeholder' : ''} ${
        error ? 'withError' : ''
      }`}
    >
      {placeholder && (
        <option value="placeholder" disabled>
          {placeholder}
        </option>
      )}
      {children}
    </select>
    {error && <span className="error">{error}</span>}
  </SelectStyle>
);

Select.defaultProps = {
  labelNextTo: false,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  labelNextTo: PropTypes.bool,
};

export default Select;
