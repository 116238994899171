import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeaderColor from './HeaderColor';

const ContainerStyle = styled.section`
  border: none;

  .children {
    max-width: 1100px;
    padding: 40px 20px;
    margin: 0 auto;
    transform: ${({ displayHeader }) =>
      displayHeader ? 'translateY(-110px)' : ''};
  }
`;

const ContainerLarge = ({ displayHeader, color, children }) => (
  <ContainerStyle displayHeader={displayHeader}>
    {displayHeader && <HeaderColor color={color} />}
    <div className="children">{children}</div>
  </ContainerStyle>
);

ContainerLarge.defaultProps = {
  displayHeader: false,
  color: '',
};

ContainerLarge.propTypes = {
  children: PropTypes.node.isRequired,
  displayHeader: PropTypes.bool,
  color: PropTypes.string,
};

export default ContainerLarge;
