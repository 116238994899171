import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getCustomError } from '../commons/graphqlError';
import { formatDate } from '../commons/dates';
import Modal from '../atoms/Modal';
import H2 from '../atoms/titles/H2';
import Checkbox from '../atoms/Checkbox';
import Button from '../atoms/Button';
import { CREATE_EVENT } from '../graphql/mutations';

export const HeiferReturnStatusStyle = styled.div`
  background-color: ${({ theme }) => theme.blue500};
  color: ${({ theme }) => theme.white};
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: -30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  div {
    margin-top: auto;
    margin-bottom: auto;
  }
  b {
    font-weight: bold;
  }

  button {
    align-items: center;
    font-weight: 700;
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue500};
    cursor: pointer;
    padding: 6px 19px;
    border-radius: 5px;
    transition: ${({ theme }) => theme.transition};
    border: 1px solid ${({ theme }) => theme.white};
    &:hover {
      background-color: ${({ theme }) => theme.blue500};
      color: ${({ theme }) => theme.white};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.grey100};
    }
  }
`;

const ConfimrModalStyle = styled.div`
  color: ${({ theme }) => theme.grey500};
  .info {
    background-color: ${({ theme }) => theme.blue300};
    color: ${({ theme }) => theme.blue500};
    padding: 10px 30px;
    margin: 10px -30px;
  }
  > * {
    margin-top: 10px;
  }
  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const addReturnEvent = async (
  createEvent,
  heiferId,
  eventType,
  setSubmitting,
  setModalOpen,
) => {
  try {
    setSubmitting(true);
    const variables = {
      id_heifer: heiferId,
      event: {
        type: eventType,
        date: formatDate(new Date()),
      },
    };

    await createEvent({ variables });

    setModalOpen(false);
    toast.success('Votre demande a bien été prise en compte');
  } catch (err) {
    setSubmitting(false);
    setModalOpen(false);
    if (getCustomError(err, 'HEIFER_DEAD')) {
      toast.error(
        "Votre demande n'a pas été ajoutée car la génisse est déclarée morte",
      );
    } else {
      toast.error(
        "Votre demande n'a pas pu être ajoutée à cause d'une erreur technique",
      );
    }
  }
};
export const AskForReturnCore = ({
  heiferId,
  createEvent,
  ageMiniNoReturn,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [event, setEvent] = useState('NO_RETURN');
  return (
    <HeiferReturnStatusStyle>
      <div>
        <b>La génisse aura plus de {ageMiniNoReturn} mois :</b> vous pouvez
        notifier que vous ne souhaitez pas la récupérer
      </div>
      <button onClick={() => setModalOpen(true)} disabled={isSubmitting}>
        Notifier
      </button>
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)}>
        <ConfimrModalStyle>
          <H2 blue>Récupération de la génisse</H2>
          <p>Préciser si vous voulez récupérer la génisse ?</p>
          <Checkbox
            onChange={() => setEvent('RETURN_ACCEPTED')}
            name="return_accepted"
            checked={event === 'RETURN_ACCEPTED'}
            label="Je souhaite le retour de la génisse"
          />
          <Checkbox
            onChange={() => setEvent('NO_RETURN')}
            name="no_return"
            checked={event === 'NO_RETURN'}
            label="Je refuse le retour la génisse"
          />
          {event === 'NO_RETURN' ? (
            <p className="info">
              Cette action est irréversible.
              <br />
              L&apos;annulation du retour de la génisse entrainera un
              remboursement des acomptes.
            </p>
          ) : (
            <p className="info">Cette action est irréversible.</p>
          )}
          <div className="buttonBar">
            <Button type="button" onClick={() => setModalOpen(false)} secondary>
              Annuler
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              onClick={async () =>
                addReturnEvent(
                  createEvent,
                  heiferId,
                  event,
                  setSubmitting,
                  setModalOpen,
                )
              }
            >
              Valider
            </Button>
          </div>
        </ConfimrModalStyle>
      </Modal>
    </HeiferReturnStatusStyle>
  );
};

AskForReturnCore.propTypes = {
  heiferId: PropTypes.string.isRequired,
  createEvent: PropTypes.func.isRequired,
  ageMiniNoReturn: PropTypes.number.isRequired,
};

const AskForReturn = ({ heiferId, ageMiniNoReturn }) => (
  <Mutation mutation={CREATE_EVENT}>
    {createEvent => (
      <AskForReturnCore
        heiferId={heiferId}
        createEvent={createEvent}
        ageMiniNoReturn={ageMiniNoReturn}
      />
    )}
  </Mutation>
);

AskForReturn.propTypes = {
  heiferId: PropTypes.string.isRequired,
  ageMiniNoReturn: PropTypes.number.isRequired,
};

export default AskForReturn;
