import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

import UserContext from '../context';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    environment: process.env.REACT_APP_SENTRY_STAGE,
  });
}

export default class ErrorBoundary extends React.Component {
  static contextType = UserContext;

  state = { error: null };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    const { username } = this.context;

    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setUser({
        id: username.username,
        email: username.attributes.email,
      });

      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // render fallback UI
      return (
        <button type="button" onClick={() => Sentry.showReportDialog()}>
          Une erreur est survenue
        </button>
      );
    }
    // when there's not an error, render children untouched
    return children;
  }
}
