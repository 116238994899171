import React, { memo } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeiferAge from '../../../atoms/HeiferAge';
import {
  getEventDate,
  getEventsByType,
  getHeiferAgeAtCalving,
  HEIFER_STATUS,
  HeiferWeightByType,
} from '../../../commons/heiferEvents';
import CellStyle from '../../../atoms/table/TableCell';
import Input from './Input';
import { ReactComponent as CheckedIcon } from '../../../statics/ico/checked.svg';
import { ReactComponent as UncheckedIcon } from '../../../statics/ico/unchecked.svg';
import HeiferStatus from '../../../atoms/HeiferStatus';

const Container = styled.div`
  color: ${({ theme }) => theme.grey500};
  margin-bottom: 1px;
  display: flex;
  background-color: #fff;
  text-decoration: none;
`;

export const HeiferCell = styled(CellStyle)`
  flex: 1.2;
  > * {
    font-weight: 700;
    text-decoration: none;
  }
`;

const CheckCell = styled(CellStyle)`
  flex: 0.3;
`;

const SmallCell = styled(CellStyle)`
  flex: 1;
`;

const WeighingCell = styled(CellStyle)`
  flex: 1.2;
`;

const Item = ({
  heifer,
  indexFormValues,
  checked,
  toggleChecked,
  defaultAge,
}) => {
  const P1 = HeiferWeightByType(heifer.events, 'P1');
  const P2 = HeiferWeightByType(heifer.events, 'P2');
  const P3 = HeiferWeightByType(heifer.events, 'P3');
  const ageAtCalving = getHeiferAgeAtCalving(heifer.events);

  return (
    <Container>
      {toggleChecked &&
        (heifer.state === HEIFER_STATUS._9_DEAD ? (
          <CheckCell />
        ) : (
          <CheckCell onClick={toggleChecked}>
            {' '}
            {checked ? <CheckedIcon /> : <UncheckedIcon />}{' '}
          </CheckCell>
        ))}
      <HeiferCell data-cy="heifersList__heiferId">
        <Link to={`/heifer/${heifer.id}`}>
          <span>{heifer.id_heifer}</span>
        </Link>
      </HeiferCell>
      <SmallCell>
        <HeiferAge
          birthDate={getEventsByType(heifer.events, 'BIRTH')[0].date}
        />
      </SmallCell>
      {indexFormValues >= 0 && !P1 ? (
        <Input name={`heifers[${indexFormValues}].P1`} />
      ) : (
        <WeighingCell>
          <p>{P1 ? `${P1} kg` : ''}</p>
        </WeighingCell>
      )}
      {indexFormValues >= 0 && !P2 ? (
        <Input name={`heifers[${indexFormValues}].P2`} />
      ) : (
        <WeighingCell>
          <p>{P2 ? `${P2} kg` : ''}</p>
        </WeighingCell>
      )}
      {indexFormValues >= 0 && !P3 ? (
        <Input name={`heifers[${indexFormValues}].P3`} />
      ) : (
        <WeighingCell>
          <p>{P3 ? `${P3} kg` : ''}</p>
        </WeighingCell>
      )}
      <SmallCell>
        <span>{ageAtCalving ? `${ageAtCalving} mois` : ''}</span>
      </SmallCell>
      <SmallCell>
        <span>{getEventDate(heifer.events, 'DELEGATION')}</span>
      </SmallCell>
      <SmallCell>
        <span>{getEventDate(heifer.events, 'RETURN')}</span>
      </SmallCell>
      <SmallCell>
        <span>{getEventDate(heifer.events, 'CALVING')}</span>
      </SmallCell>
      <HeiferCell>
        <div>
          <HeiferStatus
            status={heifer.state}
            label={heifer.ageMiniNoReturn || defaultAge}
          />
        </div>
      </HeiferCell>
    </Container>
  );
};

Item.defaultProps = {
  checked: null,
  toggleChecked: null,
  indexFormValues: null,
  defaultAge: '',
};

Item.propTypes = {
  checked: PropTypes.bool,
  toggleChecked: PropTypes.func,
  heifer: PropTypes.object.isRequired,
  indexFormValues: PropTypes.number,
  defaultAge: PropTypes.string,
};

export default memo(Item);
