import gql from 'graphql-tag';

export const IN_DELEGATION_HEIFERS_DEPOSITS = gql`
  mutation InDelegationHeifersDeposits {
    inDelegationHeifersDeposits
  }
`;
export const CRON_POOLS_TRANSFER_DATE = gql`
  mutation CronPoolsTransferDate {
    cronPoolsTransferDate
  }
`;
export const CRON_CHECK_OLD_HEIFERS = gql`
  mutation CronCheckOldHeifers {
    cronCheckOldHeifers
  }
`;
export const SEND_MONTHLY_INVOICES = gql`
  mutation SendMonthlyInvoices {
    sendMonthlyInvoices
  }
`;

export const IMPORT_DATA = gql`
  mutation ImportData($id: ID!, $mode: String) {
    importData(id: $id, mode: $mode)
  }
`;

export const EXPORT_DATA = gql`
  mutation ExportData {
    exportData
  }
`;

export const CREATE_FARM = gql`
  mutation CreateFarm($farm: CreateFarmInput!) {
    createFarm(farm: $farm) {
      id
      role
      name
      farm_name
      is_deletable
      address {
        street
        city
        zip_code
      }
      email
      phone_number
      farm_type
    }
  }
`;

export const IS_EDE_NUMBER_EXISTING = gql`
  mutation isExistingEDE($ede_number: Int!) {
    isExistingEdeNumber(ede_number: $ede_number)
  }
`;

export const SEND_MONTHLY_RETURN_POOLS_REPORT = gql`
  mutation sendMonthlyReturnPoolReport($month: Int!, $year: Int!) {
    sendMonthlyReturnPoolReport(month: $month, year: $year)
  }
`;

export const UPDATE_FARM = gql`
  mutation UpdateFarm($farm: UpdateFarmInput!) {
    updateFarm(farm: $farm) {
      id
      role
      name
      farm_name
      address {
        street
        city
        zip_code
      }
      email
      phone_number
      farm_type
      deleg_per_year
      deleg_in_progress
      pools_per_year
      capacity_per_pool
    }
  }
`;

export const UPDATE_FARM_AVAILABILITIES = gql`
  mutation UpdateFarmAvailabilities($farm: UpdateFarmAvailabilitiesInput!) {
    updateFarmAvailabilities(farm: $farm) {
      id
      availabilities
    }
  }
`;

export const UPDATE_HEIFER = gql`
  mutation UpdateHeifer($heifer: UpdateHeiferInput!) {
    updateHeifer(heifer: $heifer) {
      id
      id_heifer
      delegation_availability
      breeder {
        id
        farm_name
      }
      producer {
        id
        farm_name
      }
      events {
        id
        type
        date
      }
    }
  }
`;

export const CREATE_HEIFER = gql`
  mutation CreateHeifer($heifer: CreateHeiferInput!) {
    createHeifer(heifer: $heifer) {
      id
    }
  }
`;

export const DELETE_HEIFER_FROM_POOL = gql`
  mutation deleteHeiferFromPool($idHeifer: ID!, $idPool: ID!) {
    deleteHeiferFromPool(idHeifer: $idHeifer, idPool: $idPool) {
      id
      heifers {
        id
        delegation_availability
        breeder {
          id
        }
      }
    }
  }
`;

export const CHECK_POOL_AVAILABILITIES = gql`
  mutation checkPoolAvailabilities($poolId: ID!) {
    checkPoolAvailabilities(poolId: $poolId) {
      id
      last_pool_availabilities_notification
    }
  }
`;

export const MERGE_POOLS = gql`
  mutation mergePools($ids: [ID!]!) {
    mergePools(ids: $ids)
  }
`;

export const UPDATE_POOL_STATUS_TO_ASSIGNED = gql`
  mutation updatePoolStatusToAssigned($id: ID!, $carrier: ID!) {
    updatePoolStatusToAssigned(id: $id, carrier: $carrier)
  }
`;

export const UPDATE_POOL_STATUS_TO_CLOSED = gql`
  mutation updatePoolStatusToClosed($id: ID!) {
    updatePoolStatusToClosed(id: $id)
  }
`;

export const UPDATE_POOL_TRANSFER_DATE = gql`
  mutation updatePoolTransferDate($id: ID!, $date: String!) {
    updatePoolTransferDate(id: $id, date: $date) {
      id
      transfer_date
    }
  }
`;

export const ADD_HEIFERS_TO_DELEGATION_POOL = gql`
  mutation addHeifersToDelegationPool($idHeifers: [ID!]!, $idPool: ID!) {
    addHeifersToDelegationPool(idHeifers: $idHeifers, idPool: $idPool) {
      id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!, $type: ValidEventRemove!, $id_heifer: ID!) {
    deleteEvent(id: $id, type: $type, id_heifer: $id_heifer)
  }
`;

export const DELETE_FARM = gql`
  mutation DeleteFarm($id: ID!) {
    deleteFarm(id: $id)
  }
`;

export const DELETE_HEIFER = gql`
  mutation DeleteHeifer($id: ID!) {
    deleteHeifer(id: $id)
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($id_heifer: ID!, $event: CreateEventInput!) {
    createEvent(id_heifer: $id_heifer, event: $event) {
      id
      id_heifer
      is_dead
      return_estimated_weight
      producer {
        id
        farm_name
      }
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
          weighingType
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($id_heifer: ID!, $event: UpdateEventInput!) {
    updateEvent(id_heifer: $id_heifer, event: $event) {
      id
      id_heifer
      is_dead
      return_estimated_weight
      producer {
        id
        farm_name
      }
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
          weighingType
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
  }
`;

export const CREATE_MULTI_EVENT = gql`
  mutation CreateEventMulti($ids_heifer: [ID!]!, $event: CreateEventInput!) {
    createEventMulti(ids_heifer: $ids_heifer, event: $event) {
      id
      id_heifer
      is_dead
      return_estimated_weight
      producer {
        id
        farm_name
      }
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
          weighingType
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
  }
`;

export const CREATE_HEIFERS_MEASUREMENTS = gql`
  mutation createHeifersMeasurements($heifers: [HeiferMeasurementInput!]!) {
    createHeifersMeasurements(heifers: $heifers) {
      id
      id_heifer
      is_dead
      return_estimated_weight
      producer {
        id
        farm_name
      }
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
          weighingType
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
  }
`;
