import React from 'react';
import PropTypes from 'prop-types';
import { HEIFER_STATUS, HEIFER_STATUS_LABEL } from '../commons/heiferEvents';
import InfoLabel from './InfoLabel';

const HeiferStatus = ({ status, label }) => (
  <InfoLabel
    blueberry={status === HEIFER_STATUS._2_TO_INSEMINATE}
    warning={status === HEIFER_STATUS._4_INSEMINATED}
    great={status === HEIFER_STATUS._3_IN_CALF}
    alert={
      status === HEIFER_STATUS._6_OLD || status === HEIFER_STATUS._11_OLD_BIO
    }
    valid={[HEIFER_STATUS._5_IN_PROGRESS, HEIFER_STATUS._1_WAITING].includes(
      status,
    )}
    normal={status === HEIFER_STATUS._8_ENDED}
    noMargin
  >
    {HEIFER_STATUS_LABEL[status].replace('%age', label)}
  </InfoLabel>
);

HeiferStatus.defaultProps = {
  label: '',
};

HeiferStatus.propTypes = {
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default HeiferStatus;
