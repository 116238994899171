import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InlineList from '../atoms/InlineList';

const PoolFarmInfosStyle = styled.div`
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.darkShadow};
  background-color: #fff;
  padding: 20px;
  margin: 10px 0;

  .farm__farmName {
    color: ${({ theme }) => theme.grey500};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .farm__contact {
    margin-top: 10px;
  }
`;

const PoolFarmInfos = ({ farm }) => (
  <PoolFarmInfosStyle>
    <p className="farm__farmName">{farm.farm_name}</p>
    <InlineList separator>
      <p>{farm.name}</p>
      <p>
        {farm.address.street}, {farm.address.zip_code} {farm.address.city}
      </p>
    </InlineList>
    <InlineList className="farm__contact">
      {farm.email && <p>{farm.email}</p>}
      {farm.phone_number && <p>{farm.phone_number}</p>}
    </InlineList>
  </PoolFarmInfosStyle>
);

PoolFarmInfos.propTypes = {
  farm: PropTypes.PropTypes.shape({
    farm_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PoolFarmInfos;
