import React, { useContext } from 'react';
import styled from 'styled-components';

import Header from '../organisms/Header';
import UserContext from '../context';
import AdminRouter from '../routers/Admin';
import BreederRouter from '../routers/Breeder';
import ProducerRouter from '../routers/Producer';

const MainStyle = styled.main`
  min-height: 100vh;
`;

const Main = () => {
  const { userGroups } = useContext(UserContext);

  return (
    <MainStyle>
      <Header />
      {userGroups.includes('admin') && <AdminRouter />}
      {userGroups.includes('breeder') && <BreederRouter />}
      {userGroups.includes('producer') && <ProducerRouter />}
    </MainStyle>
  );
};

export default Main;
