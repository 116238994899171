import gql from 'graphql-tag';

export const GET_TESTS = gql`
  query tests {
    tests {
      id
      label
      note
    }
  }
`;

export const GET_CARRIERS = gql`
  query carriers {
    carriers {
      id
      email
      name
      phone_number
    }
  }
`;

export const GET_DELEGATION_DASHBOARD = gql`
  query heifers {
    heifers(inDelegation: false) {
      id
      id_heifer
      producer {
        id
        farm_name
        farm_type
      }
      delegation_availability
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
    pools(type: DELEGATION, status: [CREATED]) {
      id
      id_pool
      last_pool_availabilities_notification
      breeder {
        id
        name
        farm_name
        email
        phone_number
        farm_type
        capacity_per_pool
        address {
          street
          city
        }
      }
      heifers {
        id
        id_heifer
        return_estimated_weight
        producer {
          id
          name
          farm_name
          email
          phone_number
          farm_type
          address {
            street
            city
          }
        }
        breeder {
          id
          name
        }
        events {
          id
          type
          date
        }
      }
      transfer_date
      status
      invoices_generated
    }
  }
`;

export const GET_FARMS = gql`
  {
    farms {
      id
      role
      farm_name
      farm_type
      is_deletable
      address {
        street
        city
        zip_code
      }
    }
  }
`;

export const GET_FARMS_SUMMARY = gql`
  query farms($ids: [ID!]) {
    farms(ids: $ids) {
      id
      farm_name
    }
  }
`;

export const GET_FARMS_BY_ROLE = gql`
  query farms($role: FarmRoleType) {
    farms(role: $role) {
      id
      farm_name
      tag_id
      availabilities
      capacity_per_pool
    }
  }
`;

export const GET_FARM = gql`
  query farm($id: ID!) {
    farm(id: $id) {
      id
      role
      name
      farm_name
      address {
        street
        city
        zip_code
      }
      availabilities
      email
      phone_number
      farm_type
      deleg_per_year
      pools_per_year
      capacity_per_pool
      deleg_in_progress
      is_deletable
      intracommunity_number
      ede_number
      tag_id
      heifers {
        id
        id_heifer
        delegation_status
        is_dead
        delegation_availability
        producer {
          id
          farm_type
        }
        producer_summary {
          id
          farm_name
        }
        breeder_summary {
          id
          farm_name
        }
        events {
          id
          type
          date
          ... on EventMEASUREMENT {
            weight
          }
        }
      }
    }
  }
`;

export const GET_BREEDER_HOME = gql`
  query farm($id: ID!) {
    farm(id: $id) {
      id
      role
      name
      farm_name
      address {
        street
        city
        zip_code
      }
      availabilities
      email
      phone_number
      farm_type
      deleg_per_year
      pools_per_year
      capacity_per_pool
      deleg_in_progress
      is_deletable
      intracommunity_number
      ede_number
      tag_id
      heifers {
        id
        id_heifer
        delegation_status
        delegation_availability
        events {
          id
          type
          date
          ... on EventMEASUREMENT {
            weight
            weighingType
          }
        }
      }
    }
  }
`;

export const GET_FARM_ID_BY_EDE = gql`
  query farm($ede: ID!) {
    farm(ede: $ede) {
      id
      ede_number
    }
  }
`;

export const GET_FARM_ADMIN = gql`
  query farm($id: ID!) {
    farm(id: $id) {
      id
      role
      name
      farm_name
      address {
        street
        city
        zip_code
      }
      availabilities
      email
      phone_number
      farm_type
      deleg_per_year
      pools_per_year
      capacity_per_pool
      deleg_in_progress
      is_deletable
      intracommunity_number
      ede_number
      tag_id
      heifers {
        id
        id_heifer
        delegation_availability
        producer {
          id
          farm_type
        }
        producer_summary {
          id
          farm_name
        }
        breeder_summary {
          id
          farm_name
        }
        events {
          id
          type
          date
        }
      }
    }
  }
`;

export const GET_FARM_DETAILS = gql`
  query farm($id: ID!) {
    farm(id: $id) {
      id
      role
      name
      farm_name
      farm_type
      address {
        street
        city
        zip_code
      }
      availabilities
      email
      phone_number
      farm_type
      deleg_per_year
      pools_per_year
      capacity_per_pool
      deleg_in_progress
      is_deletable
      intracommunity_number
      ede_number
      tag_id
      heifers {
        id
        id_heifer
        producer {
          id
          farm_type
        }
        producer_summary {
          id
          farm_name
        }
        breeder_summary {
          id
          farm_name
        }
        events {
          id
          type
          date
        }
      }
    }
    billings {
      id
      billing_type
      no_return
      end_date
      start_date
    }
  }
`;

export const GET_BILLINGS = gql`
  query billings {
    billings {
      id
      billing_type
      no_return
      end_date
      start_date
    }
  }
`;

export const GET_HEIFERS = gql`
  query heifers($inDelegation: Boolean) {
    heifers(inDelegation: $inDelegation) {
      id
      id_heifer
      producer {
        id
        farm_name
      }
      delegation_availability
      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
  }
`;

export const GET_HEIFERS_SUMMARY = gql`
  query heiferByIds($ids: [ID!]) {
    heiferByIds(ids: $ids) {
      id
      id_heifer
    }
  }
`;

export const GET_HEIFER = gql`
  query heifer($id: ID!) {
    heifer(id: $id) {
      id
      id_heifer
      return_estimated_weight
      delegation_status
      is_dead
      producer {
        id
        farm_name
        tag_id
        farm_type
      }
      breeder {
        id
        farm_name
        farm_type
        address {
          street
          city
          zip_code
        }
      }

      events {
        id
        date
        type
        ... on EventMEASUREMENT {
          weight
          gmq
          weighingType
        }
        ... on EventDISEASE {
          disease
          treatment
        }
        ... on EventOTHER {
          note
        }
        ... on EventBREEDING_TIP {
          note
        }
        ... on EventABATTOIR {
          note
        }
        ... on EventINSEMINATION {
          sexed
          bull
        }
      }
    }
    billings {
      id
      billing_type
      no_return
      end_date
      start_date
    }
  }
`;

export const GET_POOLS = gql`
  query pools(
    $type: PoolType!
    $status: [PoolStatusType]
    $month: Int
    $year: Int
  ) {
    pools(type: $type, status: $status, month: $month, year: $year) {
      id
      id_pool
      last_pool_availabilities_notification
      status
      breeder {
        capacity_per_pool
        id
        farm_name
        name
        address {
          street
          city
        }
      }
      heifers {
        id
        id_heifer
        producer {
          id
          farm_name
        }
        events {
          id
          type
          date
        }
        delegation_availability
      }
      transfer_date
    }
  }
`;

export const GET_BREEDER_POOLS = gql`
  query pools($type: PoolType!, $breederId: ID!) {
    pools(type: $type, breederId: $breederId) {
      id
      id_pool
      transfer_date
      status
      heifers {
        id
        id_heifer
        events {
          id
          date
          type
        }
        producer {
          id
          farm_name
        }
      }
      breeder {
        id
      }
    }
  }
`;

export const GET_PRODUCER_POOLS = gql`
  query pools($type: PoolType!, $producerId: ID!) {
    pools(type: $type, producerId: $producerId) {
      id
      id_pool
      transfer_date
      status
      heifers {
        id
        id_heifer
        breeder {
          id
          farm_name
        }
        events {
          id
          date
          type
        }
      }
      breeder {
        id
        farm_name
      }
    }
  }
`;

export const GET_HEIFER_RETURNS = gql`
  query heiferReturns($year: Int!, $month: Int!) {
    heiferReturns(year: $year, month: $month) {
      id
      id_heifer
      producer {
        id
        farm_name
      }
      breeder {
        id
        farm_name
      }
      events {
        type
        date
      }
      transfer_date
    }
  }
`;

export const GET_RETURNS_DATES = gql`
  query returnDates {
    returnDates {
      year
      month
    }
  }
`;

export const GET_ASSIGNED_DELEGATION_POOLS = gql`
  query poolSummaries {
    poolSummaries(type: DELEGATION, status: [ASSIGNED, INPROGRESS, CLOSED]) {
      id
      id_pool
      breeder {
        id
        farm_name
      }
      heifers
      transfer_date
    }
  }
`;

export const GET_POOL = gql`
  query pool($id: ID!, $heifersByProducerId: ID) {
    pool(id: $id, heifersByProducerId: $heifersByProducerId) {
      id
      id_pool
      breeder {
        id
        name
        farm_name
        email
        phone_number
        farm_type
        address {
          street
          city
          zip_code
        }
      }
      heifers {
        id
        id_heifer
        return_estimated_weight
        producer {
          id
          name
          farm_name
          email
          phone_number
          farm_type
          address {
            street
            city
            zip_code
          }
        }
        breeder {
          id
          name
        }
        events {
          id
          type
          date
        }
      }
      transfer_date
      status
      invoices_generated
    }
  }
`;
