/**
 * Retourne si on est en mode développement ou non.
 *
 * @return {boolean}
 */
export const isDevMode = () => {
  return process && process.env && process.env.NODE_ENV === 'development';
};

export default isDevMode;

export const withNoDuplicates = array => [...new Set(array)];
