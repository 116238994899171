import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';
import Modal from '../atoms/Modal';
import H2 from '../atoms/titles/H2';
import IconTitle from '../atoms/titles/IconTitle';
import { HEIFER_STATUS, heiferCurrentState } from '../commons/heiferEvents';
import UserContext from '../context';
import { GET_POOL } from '../graphql/queries';
import ContainerSmall from '../layouts/ContainerSmall';
import PoolDate from '../molecules/PoolDate';
import PoolReturnHeifersList from '../molecules/PoolDetailsHeifersList/ReturnHeifersList';
import PoolFarmInfos from '../molecules/PoolFarmInfos';
import { ReactComponent as Heifer } from '../statics/ico/heifer.svg';
import { ReactComponent as Profile } from '../statics/ico/profile.svg';

import ClosePoolForm from './ClosePoolForm';

const RecapStyle = styled.div`
  margin-top: 30px;

  .recap__section {
    flex: 1;
    padding: 10px 0;
  }

  .recap__farms {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;

    .recap__section {
      margin: 0 20px;
    }
  }
`;

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TitleStyle = styled(H2)`
  margin: 0;
`;

const PoolReturnDetails = ({ poolId }) => {
  const { userGroups } = useContext(UserContext);

  const [showClosePoolModal, toggleClosePoolModal] = useState(false);

  if (!poolId) return <Loader />;

  return (
    <>
      <Query query={GET_POOL} variables={{ id: poolId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return `Error! ${error.message}`;

          const { pool } = data;
          const heifers = !pool.heifers
            ? []
            : pool.heifers.filter(heifer => {
                return (
                  heiferCurrentState(heifer.events) !==
                  HEIFER_STATUS._7_NO_RETURN
                );
              });

          return (
            <ContainerSmall displayHeader color="green">
              <HeaderStyle>
                <TitleStyle white>Retour {pool.id_pool}</TitleStyle>
                {userGroups.includes('admin') && (
                  <>
                    {pool.status === 'INPROGRESS' && (
                      <Button
                        onClick={() => toggleClosePoolModal(true)}
                        tertiary
                      >
                        Clore le transfert
                      </Button>
                    )}
                  </>
                )}
              </HeaderStyle>

              <PoolDate title="Date de retour" date={pool.transfer_date} />

              <RecapStyle>
                <H2 grey>Récapitulatif</H2>

                <div className="recap__farms">
                  <div className="recap__section">
                    <IconTitle icon={<Profile />}>Éleveur</IconTitle>
                    {pool.breeder && <PoolFarmInfos farm={pool.breeder} />}
                  </div>
                  <div className="recap__section">
                    <IconTitle blue icon={<Profile />}>
                      Naisseur
                    </IconTitle>
                    {heifers && heifers.length > 0 && (
                      <PoolFarmInfos farm={heifers[0].producer} />
                    )}
                  </div>
                </div>

                <div className="recap__section">
                  <IconTitle purple icon={<Heifer />}>
                    <span>{heifers.length}</span>{' '}
                    {`Génisse${heifers.length > 1 ? 's' : ''}`}
                  </IconTitle>
                  {heifers.length > 0 && (
                    <PoolReturnHeifersList heifers={heifers} />
                  )}
                </div>
              </RecapStyle>
            </ContainerSmall>
          );
        }}
      </Query>
      <Modal
        isOpen={showClosePoolModal}
        onRequestClose={() => toggleClosePoolModal(false)}
      >
        <ClosePoolForm
          onRequestClose={() => toggleClosePoolModal(false)}
          poolId={poolId}
          type="DELEGATION"
        />
      </Modal>
    </>
  );
};

PoolReturnDetails.propTypes = {
  poolId: PropTypes.string,
};

PoolReturnDetails.defaultProps = {
  poolId: null,
};

export default PoolReturnDetails;
