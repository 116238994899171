import { WEIGHT_STATUSES } from '../constants';

export const getWeightStatus = weight => {
  if (weight < 540) {
    return WEIGHT_STATUSES.VERY_LOW;
  }

  if (weight >= 540 && weight < 570) {
    return WEIGHT_STATUSES.LOW;
  }

  return WEIGHT_STATUSES.OK;
};

export default getWeightStatus;
