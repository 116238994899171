import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  .content {
    max-height: ${({ isOpened }) => (isOpened ? '400px' : '0px')};
    overflow: hidden;
    transition: all 500ms ease-in-out;
    display: flex;
    flex-direction: column;
  }
`;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.isOpened,
    };
  }

  toggleIsOpened = () => {
    const { isOpened } = this.state;
    this.setState({ isOpened: !isOpened });
  };

  render = () => {
    const { isOpened } = this.state;
    const { visible, expandable } = this.props;

    return (
      <Container isOpened={isOpened}>
        <div>
          {visible({
            toggleAccordion: this.toggleIsOpened,
            isOpened,
          })}
        </div>
        <div className="content">{isOpened && expandable}</div>
      </Container>
    );
  };
}

Accordion.propTypes = {
  visible: PropTypes.func.isRequired,
  expandable: PropTypes.node.isRequired,
  isOpened: PropTypes.bool,
};

Accordion.defaultProps = {
  isOpened: false,
};

export default Accordion;
