import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { isAfter, parseISO } from 'date-fns';

import { formatDate } from '../commons/dates';
import { CREATE_MULTI_EVENT } from '../graphql/mutations';
import Button from '../atoms/Button';
import {
  FormikCheckbox,
  FormikDatePickerArea,
  FormikInput,
  FormikSelect,
  FormikTextArea,
} from '../molecules/Formik';
import H2 from '../atoms/titles/H2';
import { EVENT_TYPES, formErrorMsgs } from '../constants';
import UserContext from '../context';
import { getEventTypeShortName } from '../commons/events';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .form__item--small {
    flex: 0 1 130px;
  }

  .form__item--medium {
    flex: 0 1 250px;
  }

  .form__item--centered {
    display: flex;
    margin: 26px 0 0 10px;
    align-items: flex-start;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const initialValues = {
  date: '',
  eventType: '',
  mesureType: 'OTHER',
  weight: '',
  disease: '',
  treatment: '',
  gmq: '',
  note: '',
  sexedInsemination: false,
};

const formSchema = () =>
  Yup.object().shape({
    date: Yup.date()
      .required(formErrorMsgs.required)
      .when('eventType', {
        is: value =>
          [
            EVENT_TYPES.INSEMINATION,
            EVENT_TYPES.SONOGRAM_1,
            EVENT_TYPES.SONOGRAM_2,
            EVENT_TYPES.SONOGRAM_EMPTY,
            EVENT_TYPES.SONOGRAM_OTHERS,
          ].includes(value),
        then: Yup.date().required(formErrorMsgs.required),
      }),

    eventType: Yup.string().required(formErrorMsgs.required),
    weight: Yup.number().when('eventType', {
      is: EVENT_TYPES.MEASUREMENT,
      then: Yup.number()
        .moreThan(0, formErrorMsgs.greaterThanZero)
        .required(formErrorMsgs.required),
    }),
    disease: Yup.string().when('eventType', {
      is: EVENT_TYPES.DISEASE,
      then: Yup.string().required(formErrorMsgs.required),
    }),
    treatment: Yup.string().when('eventType', {
      is: EVENT_TYPES.DISEASE,
      then: Yup.string().required(formErrorMsgs.required),
    }),
    note: Yup.string().when('eventType', {
      is: value =>
        [EVENT_TYPES.OTHER, EVENT_TYPES.BREEDING_TIP].includes(value),
      then: Yup.string().required(formErrorMsgs.required),
    }),
  });

export const getMutationVariables = values => ({
  event: {
    type: values.eventType,
    date: formatDate(parseISO(values.date)),
    ...(values.eventType === EVENT_TYPES.MEASUREMENT && {
      weight: values.weight,
      ...(values.gmq && { gmq: values.gmq }),
      weighingType: values.mesureType,
    }),
    ...(values.eventType === EVENT_TYPES.DISEASE && {
      disease: values.disease,
      treatment: values.treatment,
    }),
    ...((values.eventType === EVENT_TYPES.OTHER ||
      values.eventType === EVENT_TYPES.BREEDING_TIP) && {
      note: values.note,
    }),
    ...(values.eventType === EVENT_TYPES.ABATTOIR && {
      note: values.note || null,
    }),
    ...(values.eventType === EVENT_TYPES.INSEMINATION && {
      sexed: values.sexedInsemination,
    }),
  },
});

const CreateMultiEventForm = ({ toggleModal, heiferIds, resetSelected }) => {
  const { userGroups } = useContext(UserContext);
  const isOutsideRange = day => isAfter(day.toDate(), new Date(Date.now()));
  const isAdmin = userGroups.includes('admin');

  const availableEventTypes = [
    EVENT_TYPES.ABATTOIR,
    ...((isAdmin && [EVENT_TYPES.BREEDING_TIP]) || []),
    EVENT_TYPES.DEATH,
    EVENT_TYPES.DISEASE,
    EVENT_TYPES.INSEMINATION,
    EVENT_TYPES.OTHER,
    EVENT_TYPES.SONOGRAM_1,
    EVENT_TYPES.SONOGRAM_2,
    EVENT_TYPES.SONOGRAM_EMPTY,
    EVENT_TYPES.SONOGRAM_OTHERS,
  ];

  return (
    <>
      <H2 blue>Évènement</H2>

      <Mutation mutation={CREATE_MULTI_EVENT}>
        {createEventMulti => (
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema()}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const variables = {
                  ids_heifer: heiferIds,
                  ...getMutationVariables(values),
                };
                const result = await createEventMulti({ variables });
                toast.success(
                  `L'événement a été ajouté à ${result.data.createEventMulti.length} génisses sur ${heiferIds.length} génisses sélectionnées`,
                );
              } catch (err) {
                toast.error(
                  "L'événement n'a pas été ajouté à cause d'une erreur technique",
                );
              }
              setSubmitting(false);
              resetForm(initialValues);
              resetSelected();
              toggleModal();
            }}
          >
            {({ resetForm, isSubmitting, values }) => (
              <FormStyle>
                <div className="form__row">
                  <div className="form__item--small">
                    <Field
                      name="date"
                      label="Date"
                      component={FormikDatePickerArea}
                      outsideRange={isOutsideRange}
                    />
                  </div>

                  <div className="form__item--medium">
                    <Field
                      name="eventType"
                      placeholder="Type d'événement"
                      component={FormikSelect}
                      label="Type d'événement"
                    >
                      {availableEventTypes.map(eventType => (
                        <option value={eventType} key={eventType}>
                          {getEventTypeShortName(eventType)}
                        </option>
                      ))}
                    </Field>
                  </div>

                  {values.eventType === EVENT_TYPES.INSEMINATION && (
                    <div className="form__item form__item--centered">
                      <Field
                        name="sexedInsemination"
                        label="Insémination sexée"
                        checked={values.sexedInsemination}
                        component={FormikCheckbox}
                      />
                    </div>
                  )}
                </div>

                {values.eventType === EVENT_TYPES.DISEASE && (
                  <div className="form__row">
                    <Field
                      type="text"
                      name="disease"
                      label="Maladie"
                      component={FormikInput}
                    />
                    <Field
                      type="text"
                      name="treatment"
                      label="Traitement"
                      component={FormikInput}
                    />
                  </div>
                )}

                {values.eventType === EVENT_TYPES.ABATTOIR && (
                  <div className="form__row">
                    <div className="form__item">
                      <Field
                        name="note"
                        label="Note"
                        component={FormikTextArea}
                      />
                    </div>
                  </div>
                )}

                {(values.eventType === EVENT_TYPES.OTHER ||
                  values.eventType === EVENT_TYPES.BREEDING_TIP) && (
                  <div className="form__row">
                    <div className="form__item">
                      <Field
                        name="note"
                        label="Note"
                        component={FormikTextArea}
                      />
                    </div>
                  </div>
                )}

                <div className="buttonBar">
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm(initialValues);
                      toggleModal();
                    }}
                    secondary
                  >
                    Annuler
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Ajouter
                  </Button>
                </div>
              </FormStyle>
            )}
          </Formik>
        )}
      </Mutation>
    </>
  );
};

CreateMultiEventForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  heiferIds: PropTypes.array.isRequired,
  resetSelected: PropTypes.func.isRequired,
};

export default CreateMultiEventForm;
