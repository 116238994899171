import {
  addDays,
  closestIndexTo,
  compareDesc,
  differenceInDays,
  differenceInMonths,
  format,
  isPast,
  parseISO,
} from 'date-fns';
import { EVENT_TYPES } from '../constants';

export const getEventDate = (events, type) => {
  const eventFound = events.find(event => event.type === type);
  return eventFound && 'date' in eventFound
    ? format(parseISO(eventFound.date), 'dd/MM/yyyy')
    : null;
};

export const getEventsByType = (events, type) =>
  events.filter(e => e.type === type);

export const getLastEventByType = (events, type) =>
  getEventsByType(events, type).sort((a, b) =>
    compareDesc(parseISO(a.date), parseISO(b.date)),
  )[0] || null;

export const getDelegationAvailabilityFromBirthEvent = events => {
  const birthEvents = getEventsByType(events, EVENT_TYPES.BIRTH);
  if (birthEvents.length === 1) {
    return format(addDays(parseISO(birthEvents[0].date), 20), 'dd/MM/yyyy');
  }

  return false;
};

export const getHeiferAgeInMonths = events => {
  const event = getLastEventByType(events, EVENT_TYPES.BIRTH);
  return (
    (event &&
      event.date &&
      differenceInMonths(new Date(), parseISO(event.date))) ||
    null
  );
};

export const getHeiferAgeAtCalving = events => {
  const birth = getLastEventByType(events, EVENT_TYPES.BIRTH);
  const calving = getLastEventByType(events, EVENT_TYPES.CALVING);
  return (
    (birth &&
      birth.date &&
      calving &&
      calving.date &&
      differenceInMonths(parseISO(calving.date), parseISO(birth.date))) ||
    null
  );
};

export const getLastEventInDays = (events, type) => {
  const event = getLastEventByType(events, type);
  return (
    (event &&
      event.date &&
      differenceInDays(new Date(), parseISO(event.date))) ||
    null
  );
};

export const getHeiferAgeInDays = events =>
  getLastEventInDays(events, EVENT_TYPES.BIRTH);

export const heiferCurrentStatus = (events, ageMiniNoReturn) => {
  // variables pour une seule insémination
  let calvingDate = null;
  let nbSonogram = 0;
  return events
    .sort((a, b) => +parseISO(a.date) - +parseISO(b.date))
    .reduce(
      (status, e) => {
        if (status.dead) return status;

        switch (e.type) {
          case EVENT_TYPES.BIRTH:
            return {
              ...status,
              birthDate: parseISO(e.date),
              ageInMonths: differenceInMonths(new Date(), parseISO(e.date)),
            };
          case EVENT_TYPES.DELEGATION:
            return {
              ...status,
              inDelegation: isPast(parseISO(e.date)),
            };
          case EVENT_TYPES.INSEMINATION: {
            calvingDate = addDays(parseISO(e.date), 280);
            nbSonogram = 0;
            return {
              ...status,
              toInseminate: false,
              inseminated: true,
              calvingDate,
              nbSonogram: 0,
              oldHeiferAtCalving: ageMiniNoReturn
                ? differenceInMonths(calvingDate, status.birthDate) >=
                  ageMiniNoReturn
                : false,
            };
          }
          case EVENT_TYPES.SONOGRAM_1:
          case EVENT_TYPES.SONOGRAM_2:
          case EVENT_TYPES.SONOGRAM_OTHERS:
            nbSonogram += 1;
            return {
              ...status,
              nbSonogram,
              toInseminate: false,
              inseminated: true,
              calvingDate,
            };
          case EVENT_TYPES.SONOGRAM_EMPTY:
          case EVENT_TYPES.ABORTION:
            return {
              ...status,
              calvingDate: null,
              nbSonogram: 0,
              inseminated: false,
              toInseminate: e.type === EVENT_TYPES.SONOGRAM_EMPTY,
            };
          case EVENT_TYPES.DEATH:
            return {
              ...status,
              ageInMonths: differenceInMonths(
                parseISO(e.date),
                status.birthDate,
              ),
              oldHeiferAtCalving: false,
              inDelegation: false,
              calvingDate: null,
              inseminated: false,
              toInseminate: false,
              nbSonogram: 0,
              dead: true,
            };
          case EVENT_TYPES.NO_RETURN:
            return {
              ...status,
              inDelegation: false,
              noReturn: true,
            };
          case EVENT_TYPES.RETURN_ACCEPTED:
            return {
              ...status,
              inDelegation: false,
              noReturn: false,
            };
          case EVENT_TYPES.RETURN:
            return isPast(parseISO(e.date))
              ? {
                  ...status,
                  returned: true,
                  inDelegation: false,
                }
              : status;
          default:
        }
        return status;
      },
      {
        birthDate: null,
        ageInMonths: 0,
        inDelegation: false,
        inseminated: false,
        toInseminate: false,
        oldHeiferAtCalving: false,
        nbSonogram: 0,
        calvingDate: null,
        returned: false,
        noReturn: null, // null => unknown, the heifer is too young, under 30 months at calving
        // true => the heifer will not return to the producer,
        // false => the heifer will return even if it will be too old
        dead: false,
      },
    );
};

export const HEIFER_STATUS = {
  _1_WAITING: '_1_WAITING',
  _2_TO_INSEMINATE: '_2_TO_INSEMINATE',
  _3_IN_CALF: '_3_IN_CALF',
  _4_INSEMINATED: '_4_INSEMINATED',
  _5_IN_PROGRESS: '_5_IN_PROGRESS',
  _6_OLD: '_6_OLD',
  _7_NO_RETURN: '_7_NO_RETURN',
  _8_ENDED: '_8_ENDED',
  _9_DEAD: '_9_DEAD',
  _10_TO_INSEMINATE: '_10_TO_INSEMINATE',
  _11_OLD_BIO: '_11_OLD_BIO',
};

export const heiferCurrentState = (
  events,
  ageMiniNoReturn = null,
  farmType = null,
) => {
  const status = heiferCurrentStatus(events, ageMiniNoReturn);

  // "morte" if the heifer has a death event
  if (status.dead) return HEIFER_STATUS._9_DEAD;

  // "non retour" if the heifer has a non return event in the past
  if (status.noReturn) return HEIFER_STATUS._7_NO_RETURN;

  // "terminé" if the heifer has a return event in the past
  if (status.returned) return HEIFER_STATUS._8_ENDED;

  // "vieille" if the heifer is too old
  if (status.oldHeiferAtCalving && status.noReturn === null)
    return farmType
      ? farmType === 'BIO'
        ? HEIFER_STATUS._11_OLD_BIO
        : HEIFER_STATUS._6_OLD
      : HEIFER_STATUS._6_OLD;

  if (status.toInseminate) return HEIFER_STATUS._10_TO_INSEMINATE;

  // "pleine" if the heifer has sonogram event
  if (status.inseminated && status.nbSonogram > 0)
    return HEIFER_STATUS._3_IN_CALF;

  // "Inséminée" if the heifer has an insemination event
  if (status.inseminated) return HEIFER_STATUS._4_INSEMINATED;

  //  if the heifer has a delegation event
  if (status.inDelegation) {
    // "en cours" si moins de 10 mois
    if (status.ageInMonths < 10) return HEIFER_STATUS._5_IN_PROGRESS;
    // sinon '+30 mois' ou "à surveiller" selon l'age
    return status.ageInMonths > ageMiniNoReturn && status.noReturn !== false
      ? farmType === 'BIO'
        ? HEIFER_STATUS._11_OLD_BIO
        : HEIFER_STATUS._6_OLD
      : HEIFER_STATUS._2_TO_INSEMINATE;
  }
  // Otherwise "en attente"
  return HEIFER_STATUS._1_WAITING;
};
export const HEIFER_STATUS_LABEL = {
  _1_WAITING: 'en attente',
  _2_TO_INSEMINATE: 'à surveiller',
  _3_IN_CALF: 'pleine',
  _4_INSEMINATED: 'inséminée',
  _5_IN_PROGRESS: 'en cours',
  _6_OLD: '+%age mois',
  _7_NO_RETURN: 'non retour',
  _8_ENDED: 'terminée',
  _9_DEAD: 'morte',
  _10_TO_INSEMINATE: 'à inséminer',
  _11_OLD_BIO: '+%age mois',
};
export const HEIFER_STATUS_LABEL_UPCASE = {
  _1_WAITING: 'En attente',
  _2_TO_INSEMINATE: 'À surveiller',
  _3_IN_CALF: 'Pleine',
  _4_INSEMINATED: 'Inséminée',
  _5_IN_PROGRESS: 'En cours',
  _6_OLD: '+%age mois',
  _7_NO_RETURN: 'Non retour',
  _8_ENDED: 'Terminée',
  _9_DEAD: 'Morte',
  _10_TO_INSEMINATE: 'À inséminer',
  _11_OLD_BIO: '+%age mois',
};

export const HeiferWeightByType = (events, type) => {
  const filteredEvents = events.filter(
    e => 'weighingType' in e && e.weighingType === type,
  );

  const indexClosestEvent = closestIndexTo(
    new Date(),
    filteredEvents.map(e => parseISO(e.date)),
  );

  if (indexClosestEvent >= 0) {
    return filteredEvents[indexClosestEvent].weight;
  }

  return null;
};

// Return a boolean to check if the heifer as an event type MEASURE of the weighing type defined
export const hasMeasureType = (events, type) =>
  !!events.some(e => e.type === 'MEASUREMENT' && e.weighingType === type);
