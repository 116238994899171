import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const TooltipStyle = styled(ReactTooltip)`
  background-color: #e6e6eb !important;
  font-size: 12px;
  color: #666872 !important;
  border-radius: 3px;
  opacity: 1 !important;

  &:after {
    border-bottom-color: #e6e6eb !important;
  }

  p {
    font-size: inherit;
    color: inherit;
  }
`;

const Tooltip = ({ id, children }) => (
  <TooltipStyle multiline id={id} place="bottom" type="dark" effect="solid">
    <p>{children}</p>
  </TooltipStyle>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
