import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import locale from 'date-fns/locale/fr';
import styled from 'styled-components';

import MonthlyReturnPoolsReportButton from '../MonthlyReturnPoolsReportButton';
import Select from '../../atoms/Select';

const buildOptions = choices => {
  return choices.map(({ year, month }) => {
    const date = new Date(year, month);

    return {
      value: `${month} ${year}`,
      label: format(date, 'MMMM yyyy', { locale }),
    };
  });
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px 30px;

  & > * {
    margin: 0 10px;
  }
`;

const HeaderHeiferReturns = ({
  selectedYear,
  selectedMonth,
  changeSelectedDate,
  choices,
  disabledReport,
}) => (
  <Container>
    <Select
      name="heiferReturnDate"
      onChange={changeSelectedDate}
      value={`${selectedMonth} ${selectedYear}`}
    >
      {buildOptions(choices).map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>

    <MonthlyReturnPoolsReportButton
      disabled={disabledReport}
      month={selectedMonth}
      year={selectedYear}
    />
  </Container>
);

HeaderHeiferReturns.propTypes = {
  disabledReport: PropTypes.bool.isRequired,
  selectedYear: PropTypes.number.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired,
  changeSelectedDate: PropTypes.func.isRequired,
};

export default HeaderHeiferReturns;
