import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CellStyle from '../../atoms/table/TableCell';
import ContentRowStyle from '../../atoms/table/TableContentRow';
import { ReactComponent as Chevron } from '../../statics/ico/chevron__grey.svg';

const IdCellStyle = styled(CellStyle)`
  flex: 1;
  > * {
    font-weight: 700;
  }
`;

const MediumCellStyle = styled(CellStyle)`
  flex: 2;
`;

const BreederCellStyle = styled(CellStyle)`
  flex: 3;
`;

const HeiferCellStyle = styled(CellStyle)`
  flex: 0 0 120px;
`;

const HeiferButton = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    transform: rotate(${({ isOpened }) => (isOpened ? '180deg' : '0deg')});
    transition: ${({ theme }) => theme.transition};
  }
`;

const Item = ({ pool, isOpened, toggleAccordion }) => (
  <ContentRowStyle to={`/manage/delegations/${pool.id}`}>
    <IdCellStyle>
      <span>{pool.id_pool}</span>
    </IdCellStyle>

    <BreederCellStyle>
      {pool.breeder && <span>{pool.breeder.farm_name}</span>}
    </BreederCellStyle>

    <MediumCellStyle>
      {pool.transfer_date && (
        <span>{format(parseISO(pool.transfer_date), 'dd/MM/yyyy')}</span>
      )}
    </MediumCellStyle>

    <HeiferCellStyle>
      {pool.heifers && (
        <HeiferButton
          isOpened={isOpened}
          onClick={e => {
            e.preventDefault();
            toggleAccordion();
          }}
        >
          <span>
            {pool.heifers.length} génisse
            {pool.heifers.length > 1 ? 's' : ''}
          </span>
          <Chevron />
        </HeiferButton>
      )}
    </HeiferCellStyle>
  </ContentRowStyle>
);

Item.propTypes = {
  pool: PropTypes.shape({
    id: PropTypes.string.isRequired,
    id_pool: PropTypes.string.isRequired,
    breeder: PropTypes.shape({
      farm_name: PropTypes.string.isRequired,
    }),
    producer: PropTypes.shape({
      farm_name: PropTypes.string.isRequired,
    }),
    heifers: PropTypes.array.isRequired,
    transfer_date: PropTypes.string.isRequired,
  }).isRequired,
  isOpened: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
};

export default Item;
