import React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import FarmsList from '../molecules/FarmsList';
import { GET_FARMS } from '../graphql/queries';
import Modal from '../atoms/Modal';
import Select from '../atoms/Select';
import CreateFarmForm from './CreateFarmForm';
import PageHeader from '../molecules/PageHeader';
import { ReactComponent as HomeDetailedIcon } from '../statics/ico/homeDetailed.svg';
import ContainerLarge from '../layouts/ContainerLarge';
import RemoveFarmForm from './RemoveFarmForm';
import EditFarmForm from './EditFarmForm';
import Loader from '../atoms/Loader';

const SelectRoleStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  > .label {
    color: ${({ theme }) => theme.grey500};
    margin-right: 10px;
  }

  > .selectContainer {
    width: 120px;
  }
`;

class Farms extends React.Component {
  state = {
    modalCreateFarmOpen: false,
    modalDeleteFarmOpen: false,
    modalEditFarmOpen: false,
    roleSelected: 'all',
    farmId: null,
  };

  handleOnChangeSelectRole = e => {
    if (typeof e === 'object' && 'target' in e && 'value' in e.target)
      this.setState({ roleSelected: e.target.value });
  };

  filteredFarms = farms => {
    const { roleSelected } = this.state;

    return farms.filter(farm =>
      roleSelected !== 'all' ? farm.role === roleSelected : true,
    );
  };

  toggleModalCreateFarm = () => {
    const { modalCreateFarmOpen } = this.state;
    this.setState({ modalCreateFarmOpen: !modalCreateFarmOpen });
  };

  // Set the farmId to the value pass as parameter only if the previous
  // value of modalEditFarmOpen was closed. Otherwise will set farmId
  // to null
  toggleModalEditFarm = id => {
    const { modalEditFarmOpen } = this.state;

    this.setState({
      modalEditFarmOpen: !modalEditFarmOpen,
      farmId: !modalEditFarmOpen && id ? id : null,
    });
  };

  // Set the farmId to the value pass as parameter only if the previous
  // value of modalDeleteFarmOpen was closed. Otherwise will set farmId
  // to null
  toggleModalDeleteFarm = id => {
    const { modalDeleteFarmOpen } = this.state;

    this.setState({
      modalDeleteFarmOpen: !modalDeleteFarmOpen,
      farmId: !modalDeleteFarmOpen && id ? id : null,
    });
  };

  render = () => {
    const {
      modalCreateFarmOpen,
      modalDeleteFarmOpen,
      modalEditFarmOpen,
      roleSelected,
      farmId,
    } = this.state;

    return (
      <ContainerLarge>
        <PageHeader
          icon={<HomeDetailedIcon />}
          title="Exploitations"
          primaryButtonLabel="Ajouter une exploitation"
          primaryButtonAction={this.toggleModalCreateFarm}
        />

        <SelectRoleStyle>
          <p className="label">Afficher</p>
          <div className="selectContainer">
            <Select
              name="roletype"
              value={roleSelected}
              onChange={this.handleOnChangeSelectRole}
            >
              <option value="all">Tout</option>
              <option value="producer">Naisseur</option>
              <option value="breeder">Éleveur</option>
            </Select>
          </div>
        </SelectRoleStyle>

        <Query query={GET_FARMS}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error.message);

            return (
              <FarmsList
                farms={this.filteredFarms(data.farms)}
                handleOnClickOnEdit={this.toggleModalEditFarm}
                handleOnClickOnDelete={this.toggleModalDeleteFarm}
              />
            );
          }}
        </Query>

        <Modal
          isOpen={modalCreateFarmOpen}
          onRequestClose={this.toggleModalCreateFarm}
        >
          <CreateFarmForm onRequestClose={this.toggleModalCreateFarm} />
        </Modal>
        {farmId && (
          <>
            <Modal
              isOpen={modalDeleteFarmOpen}
              onRequestClose={this.toggleModalDeleteFarm}
            >
              <RemoveFarmForm
                farmId={farmId}
                onRequestClose={this.toggleModalDeleteFarm}
              />
            </Modal>
            <Modal
              isOpen={modalEditFarmOpen}
              onRequestClose={this.toggleModalEditFarm}
            >
              <EditFarmForm
                farmId={farmId}
                onRequestClose={this.toggleModalEditFarm}
              />
            </Modal>
          </>
        )}
      </ContainerLarge>
    );
  };
}

export default Farms;
