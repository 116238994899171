import React, { useContext } from 'react';
import { Link, Router } from '@reach/router';
import styled from 'styled-components';
import { isDevMode } from '../commons/misc';

import { ReactComponent as Activity } from '../statics/ico/activity.svg';
import { ReactComponent as Home } from '../statics/ico/home.svg';
import { ReactComponent as Transfert } from '../statics/ico/refresh.svg';
import { ReactComponent as HomeDetailedIcon } from '../statics/ico/homeDetailed.svg';
import Logout from '../molecules/Logout';
import logo from '../statics/logistock.png';
import logo2x from '../statics/logistock@2x.png';
import logo3x from '../statics/logistock@3x.png';
import UserContext from '../context';

const HeaderStyle = styled.header`
  background-color: #fff;

  a {
    text-decoration: none;
  }

  span:not(.nav__text) {
    font-size: 8px;
    color: #e6e6eb;
    margin-top: 24px;
    margin-right: -18px;
  }
`;

const TopHeaderWrapper = styled.div`
  display: flex;
  height: 50px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
`;

const HeaderLogo = styled(Link)`
  border-right: 2px solid ${({ theme }) => theme.grey300};
  display: flex;
  align-items: center;
  padding: 10px 34px;

  img {
    width: 76px;
    height: 30px;
  }

  span.version {
    font-weight: bold;
    color: #33b6dc;
  }
`;

const NavStyle = styled.nav`
  display: flex;
  margin: 0 25px;

  .nav__item {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px 17px;
    margin: 0 5px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: ${({ theme }) => theme.blue500};
      border-radius: 3px 3px 0 0;
      transition: ${({ theme }) => theme.transition};
    }
  }

  .nav__icon {
    max-width: 15px;
    max-height: 15px;

    > * {
      stroke: ${({ theme }) => theme.grey100};
      transition: ${({ theme }) => theme.transition};
    }
  }

  .nav__text {
    font-weight: 700;
    color: ${({ theme }) => theme.grey500};
    font-size: 14px;
    padding-left: 7px;
  }

  .nav__item--active {
    &:after {
      bottom: 0;
    }

    .nav__icon * {
      stroke: ${({ theme }) => theme.blue500};
    }
  }

  .nav__item:hover {
    .nav__icon * {
      stroke: ${({ theme }) => theme.blue500};
    }
  }
`;

export const isMenuItemActive = (isPartiallyCurrent, href, pathname) =>
  isPartiallyCurrent &&
  ((href !== '/' && pathname !== '/') || (href === '/' && pathname === '/'));

export const getMainMenuClasses = item => ({ href, location }) => {
  const className = ['nav__item'];

  if (
    (item === 'home' && location.pathname.split('/').length === 2) ||
    (location.pathname.includes(item) && href.includes(item))
  )
    className.push('nav__item--active');

  return { className: className.join(' ') };
};

const getSubMenuClasses = ({ isPartiallyCurrent, href, location }) => ({
  className: `nav__subitem ${
    isMenuItemActive(isPartiallyCurrent, href, location.pathname)
      ? 'nav__subitem--active'
      : ''
  }`,
});

const version = process.env.REACT_APP_VERSION;

const Header = () => {
  const { userGroups } = useContext(UserContext);

  return (
    <HeaderStyle>
      <TopHeaderWrapper>
        <HeaderLogo to="/">
          <img
            src={logo}
            alt="logo"
            srcSet={`${logo} 1x, ${logo2x} 2x,
                                 ${logo3x} 3x`}
          />
          {version && <span className="version">{version}</span>}
        </HeaderLogo>
        <NavStyle>
          <Link to="/" getProps={getMainMenuClasses('home')}>
            <Home className="nav__icon" />
            <span className="nav__text">Accueil</span>
          </Link>
          {userGroups.includes('admin') && (
            <>
              <Link
                to="manage/delegations"
                getProps={getMainMenuClasses('manage')}
              >
                <Activity className="nav__icon" />
                <span className="nav__text">Suivi</span>
              </Link>
              <Link to="view/farms" getProps={getMainMenuClasses('farm')}>
                <HomeDetailedIcon className="nav__icon" />
                <span className="nav__text">Exploitations</span>
              </Link>
            </>
          )}
          {isDevMode() && userGroups.includes('admin') && (
            <Link to="admin/tests" getProps={getMainMenuClasses('manage')}>
              <Activity className="nav__icon" />
              <span className="nav__text">Super Admin</span>
            </Link>
          )}
          {(userGroups.includes('producer') ||
            userGroups.includes('breeder')) && (
            <Link
              to="transfer/delegations"
              getProps={getMainMenuClasses('transfer')}
            >
              <Transfert className="nav__icon" />
              <span className="nav__text">Transferts</span>
            </Link>
          )}
        </NavStyle>
        <Logout />
      </TopHeaderWrapper>
      {userGroups.includes('admin') && (
        <div>
          <Router>
            <HomeSubMenu path="/" />
            <HomeSubMenu path="/returns" />
            <ManageSubMenu path="manage/*" />
          </Router>
        </div>
      )}
      {(userGroups.includes('producer') || userGroups.includes('breeder')) && (
        <div>
          <Router>
            <TransferSubMenu path="transfer/*" />
          </Router>
        </div>
      )}
    </HeaderStyle>
  );
};

const SubMenuStyle = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  .nav__subitem {
    color: ${({ theme }) => theme.grey700};
    font-size: 14px;
    margin: 0 10px;
    padding: 13px 0;
    text-decoration: none;

    &--active {
      color: ${({ theme }) => theme.blue500};
      font-weight: 700;
    }
  }
`;

const HomeSubMenu = () => (
  <SubMenuStyle>
    <Link to="/" getProps={getSubMenuClasses}>
      Délégations
    </Link>
    <Link to="/returns" getProps={getSubMenuClasses}>
      Retours
    </Link>
  </SubMenuStyle>
);

const ManageSubMenu = () => (
  <SubMenuStyle>
    <Link to="delegations" getProps={getSubMenuClasses}>
      Transferts de lots
    </Link>
    <Link to="returns" getProps={getSubMenuClasses}>
      Transferts retours
    </Link>
  </SubMenuStyle>
);

const TransferSubMenu = () => (
  <SubMenuStyle>
    <Link to="delegations" getProps={getSubMenuClasses}>
      Délégations de lots
    </Link>
    <Link to="returns" getProps={getSubMenuClasses}>
      Retours de génisse
    </Link>
  </SubMenuStyle>
);

export default Header;
