import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Mutation, Query } from 'react-apollo';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { getCustomError } from '../commons/graphqlError';
import Tooltip from '../atoms/Tooltip';
import { ReactComponent as InfoIcon } from '../statics/ico/info_grey.svg';
import { formErrorMsgs } from '../constants';
import { UPDATE_FARM } from '../graphql/mutations';
import { GET_FARM_DETAILS } from '../graphql/queries';
import { FormikInput, FormikSelect } from '../molecules/Formik';
import H2 from '../atoms/titles/H2';
import H3 from '../atoms/titles/H3';
import Button from '../atoms/Button';
import Loader from '../atoms/Loader';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .informations {
    background-color: ${({ theme }) => theme.grey200};
    margin: 20px -30px;
    padding: 20px 30px 10px;
  }

  .form__item--email {
    display: flex;
    align-items: flex-start;

    > label {
      flex: 1 0 0%;
    }

    .infoIcon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: 25px;
    }
  }

  .form__item--extrasmall {
    flex: 0 0 20%;
  }
  .form__item--small {
    flex: 0 0 30%;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin: 40px -10px 0;

    & > * {
      margin: 0 10px;
    }
  }
`;

const formSchema = role =>
  Yup.object().shape({
    farm_name: Yup.string().required(formErrorMsgs.required),
    name: Yup.string().required(formErrorMsgs.required),
    addressStreet: Yup.string().required(formErrorMsgs.required),
    addressCity: Yup.string().required(formErrorMsgs.required),
    phone_number: Yup.string().required(formErrorMsgs.required),
    ede_number: Yup.number()
      .min(10000000, formErrorMsgs.lengthHeightDigits)
      .max(99999999, formErrorMsgs.lengthHeightDigits)
      .required(formErrorMsgs.required),
    addressZipCode: Yup.number()
      .min(10000, formErrorMsgs.lengthFiveDigits)
      .max(99999, formErrorMsgs.lengthFiveDigits)
      .required(formErrorMsgs.required),
    intracommunity_number: Yup.string().required(formErrorMsgs.required),
    email: Yup.string(),
    ...(role === 'breeder' && {
      poolsPerYear: Yup.number().required(formErrorMsgs.required),
      capacityPerPool: Yup.number().required(formErrorMsgs.required),
    }),
    ...(role === 'producer' && {
      delegPerYear: Yup.number().required(formErrorMsgs.required),
      tagId: Yup.number()
        .min(100000, formErrorMsgs.lengthSixDigits)
        .max(999999, formErrorMsgs.lengthSixDigits)
        .required(formErrorMsgs.required),
    }),
  });

const EditFarmForm = ({ onRequestClose, farmId }) => {
  const [wrongEdeNumber, changeWrongEdeNumber] = useState(null);
  const [role, changeRole] = useState(null);

  return (
    <Query query={GET_FARM_DETAILS} variables={{ id: farmId }}>
      {({ loading, error, data: { farm } }) => {
        if (loading) return <Loader inline />;
        if (error) return `Error! ${error.message}`;

        if (!role) {
          changeRole(farm.role);
        }

        const initialValues = {
          name: farm.name,
          farm_name: farm.farm_name,
          phone_number: farm.phone_number || '',
          farm_type: farm.farm_type || 'CONVENTIONNEL',
          email: farm.email || '',
          intracommunity_number: farm.intracommunity_number || '',
          ede_number: farm.ede_number || '',
          addressStreet: farm.address.street,
          addressCity: farm.address.city,
          addressZipCode: farm.address.zip_code ?? '',
          deleg_per_year: farm.deleg_per_year,
          delegPerYear: farm.deleg_per_year ?? '',
          poolsPerYear: farm.pools_per_year ?? '',
          capacityPerPool: farm.capacity_per_pool ?? '',
          role: farm.role,
          tagId: farm.tag_id || '',
        };

        return (
          <Mutation mutation={UPDATE_FARM}>
            {updateFarm => (
              <div>
                <H2 blue>Modifier une exploitation</H2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formSchema(role)}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const {
                        addressCity,
                        addressStreet,
                        addressZipCode,
                        delegPerYear,
                        poolsPerYear,
                        capacityPerPool,
                        email,
                        tagId,
                        ...rest
                      } = values;

                      const params = {
                        farm: {
                          id: farm.id,
                          ...rest,
                          address: {
                            city: addressCity,
                            street: addressStreet,
                            zip_code: addressZipCode,
                          },
                          ...(values.role === 'producer' && {
                            deleg_per_year: delegPerYear,
                            tag_id: tagId,
                          }),
                          ...(values.role === 'breeder' && {
                            pools_per_year: poolsPerYear,
                            capacity_per_pool: capacityPerPool,
                          }),
                          email: email || null,
                        },
                      };

                      await updateFarm({ variables: params });
                      toast.success("L'exploitation a été mise à jour");

                      setSubmitting(false);
                      onRequestClose();
                    } catch (err) {
                      const errorEdeNumber = getCustomError(
                        err,
                        'EXISTING_EDE_NUMBER',
                      );

                      if (errorEdeNumber) {
                        changeWrongEdeNumber(values.ede_number);
                      } else {
                        toast.error(
                          "L'exploitation n'a pas été mise à jour à cause d'une erreur technique",
                        );
                      }

                      setSubmitting(false);
                    }
                  }}
                >
                  {({ resetForm, isSubmitting, values, setFieldValue }) => (
                    <FormStyle>
                      <div className="form__row">
                        <Field
                          type="text"
                          name="farm_name"
                          placeholder="Nom de l'exploitation"
                          label="Exploitation"
                          component={FormikInput}
                        />
                      </div>
                      <div className="form__row">
                        <Field
                          type="text"
                          name="name"
                          placeholder="Nom de l'exploitant"
                          label="Exploitant"
                          component={FormikInput}
                          className="form__item"
                        />

                        {farm.deleg_in_progress === 0 && (
                          <div className="form__item form__item--small">
                            <Field
                              name="role"
                              placeholder="Rôle"
                              component={FormikSelect}
                              label="Rôle"
                              onChange={e => {
                                changeRole(e.target.value);
                                setFieldValue('role', e.target.value);
                              }}
                            >
                              <option value="producer">Naisseur</option>
                              <option value="breeder">Éleveur</option>
                            </Field>
                          </div>
                        )}
                      </div>
                      <div className="form__row">
                        <Field
                          type="text"
                          name="farm_type"
                          placeholder="Choisissez"
                          label="Type d'élevage"
                          component={FormikSelect}
                          className="form__item"
                        >
                          <option value="BIO">BIO</option>
                          <option value="CONVENTIONNEL">CONVENTIONNEL</option>
                        </Field>
                      </div>
                      <div className="form__row">
                        <Field
                          type="text"
                          name="addressStreet"
                          placeholder="Rue, Lieu-dit"
                          label="Adresse"
                          component={FormikInput}
                        />
                        <div className="form__item--extrasmall">
                          <Field
                            type="number"
                            name="addressZipCode"
                            placeholder="Code postal"
                            label="Code postal"
                            component={FormikInput}
                          />
                        </div>
                        <Field
                          type="text"
                          name="addressCity"
                          placeholder="Ville"
                          label="Ville"
                          component={FormikInput}
                        />
                      </div>
                      <div className="form__row">
                        <Field
                          type="text"
                          name="phone_number"
                          placeholder="N° de téléphone mobile"
                          label="N° de téléphone mobile"
                          component={FormikInput}
                        />
                        <div className="form__item--email">
                          <InfoIcon
                            data-tip
                            data-for="emailInfos"
                            className="infoIcon"
                          />
                          <Field
                            type="text"
                            name="email"
                            placeholder="Adresse email"
                            label="Adresse email"
                            component={FormikInput}
                          />
                          <Tooltip id="emailInfos">
                            L’adresse mail est conseillée pour la réception de
                            <br />
                            toutes les informations relatives au service pour
                            <br />
                            l’exploitant.
                          </Tooltip>
                        </div>
                      </div>
                      <div className="form__section">
                        <div className="form__row">
                          <Field
                            type="text"
                            name="intracommunity_number"
                            placeholder="N° Intracommunautaire"
                            label="N° Intracommunautaire"
                            component={FormikInput}
                          />
                          <Field
                            type="number"
                            name="ede_number"
                            placeholder="N° EDE"
                            label="N° EDE"
                            component={FormikInput}
                            customError={
                              wrongEdeNumber &&
                              values.ede_number === wrongEdeNumber
                                ? formErrorMsgs.exitingEdeNumber
                                : null
                            }
                          />
                          {values.role === 'producer' && (
                            <Field
                              type="number"
                              name="tagId"
                              placeholder="N° boucle"
                              label="N° boucle"
                              component={FormikInput}
                            />
                          )}
                        </div>
                      </div>
                      <div className="informations">
                        <H3>Informations</H3>
                        <div className="form__row">
                          {values.role === 'producer' && (
                            <Field
                              type="number"
                              name="delegPerYear"
                              placeholder="Ex. 10"
                              label="Délégations / an"
                              component={FormikInput}
                            />
                          )}
                          {values.role === 'breeder' && (
                            <>
                              <Field
                                type="number"
                                name="poolsPerYear"
                                placeholder="Ex. 10"
                                label="Nombre de lots / an"
                                component={FormikInput}
                              />
                              <Field
                                type="number"
                                name="capacityPerPool"
                                placeholder="Ex. 10"
                                label="Génisse(s) / lot"
                                component={FormikInput}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="buttonBar">
                        <Button
                          type="button"
                          onClick={() => {
                            resetForm(initialValues);
                            onRequestClose();
                          }}
                          disabled={isSubmitting}
                          secondary
                        >
                          Annuler
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          Modifier
                        </Button>
                      </div>
                    </FormStyle>
                  )}
                </Formik>
              </div>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

EditFarmForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
};

export default EditFarmForm;
