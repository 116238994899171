import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { farmRoles } from '../constants';
import CellStyle from '../atoms/table/TableCell';
import IconButton from '../atoms/IconButton';
import HeaderRowStyle from '../atoms/table/TableHeaderRow';
import ContentRowStyle from '../atoms/table/TableContentRow';
import { orderByRole, orderByAttribut } from '../commons/orderList';
import EmptyList from './EmptyList';

export const RoleCellStyle = styled(CellStyle)`
  flex: 1;
`;

export const NameCellStyle = styled(CellStyle)`
  flex: 2;
  font-weight: 700;
`;

export const TypeCellStyle = styled(CellStyle)`
  flex: 1;
`;

const AddressCellStyle = styled(CellStyle)`
  flex: 3;
`;

const ActionsCellStyle = styled(CellStyle)`
  flex: 0 0 100px;
  display: flex;
  justify-content: start;
  margin: 0 -5px;
  visibility: hidden;
  opacity: 0;

  > * {
    margin: 0 5px;
  }
`;

const ContentRowWithHoverStyle = styled(ContentRowStyle)`
  &:hover > ${ActionsCellStyle} {
    visibility: visible;
    opacity: 1;
  }
`;

class FarmsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDirection: 'asc',
      orderValue: 'role',
    };
  }

  // Order the farms list depending on the direction and value saved in state.
  orderList = () => {
    const { farms } = this.props;
    const { orderValue, orderDirection } = this.state;
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'role':
        return orderByRole(farms, orderValue, orderStatement);
      default:
        return orderByAttribut(farms, orderValue, orderStatement);
    }
  };

  toggleOrder = orderValue => {
    const { orderDirection } = this.state;

    this.setState({
      orderValue,
      orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  render = () => {
    const { orderValue, orderDirection } = this.state;
    const { farms, handleOnClickOnDelete, handleOnClickOnEdit } = this.props;

    return (
      <div>
        <HeaderRowStyle>
          <RoleCellStyle>
            <button
              type="button"
              className={`sortButton ${
                orderValue === 'role' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('role')}
            >
              Rôle
            </button>
          </RoleCellStyle>
          <NameCellStyle>
            <button
              type="button"
              className={`sortButton ${
                orderValue === 'farm_name' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('farm_name')}
            >
              Exploitation
            </button>
          </NameCellStyle>
          <TypeCellStyle>
            <button
              type="button"
              className={`sortButton ${
                orderValue === 'farm_type' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('farm_type')}
            >
              Type
            </button>
          </TypeCellStyle>
          <AddressCellStyle>
            <span>Adresse</span>
          </AddressCellStyle>
          <ActionsCellStyle />
        </HeaderRowStyle>
        {farms.length > 0 ? (
          this.orderList().map(farm => (
            <ContentRowWithHoverStyle
              key={farm.id}
              to={farm.id}
              data-cy="farmList__farmContainer"
            >
              <RoleCellStyle>
                <span>{farmRoles[farm.role]}</span>
              </RoleCellStyle>
              <NameCellStyle data-cy="farmList__farmName">
                <span>{farm.farm_name}</span>
              </NameCellStyle>
              <TypeCellStyle>
                <span>{farm.farm_type}</span>
              </TypeCellStyle>
              <AddressCellStyle>
                <span>
                  {farm.address.street}, {farm.address.zip_code}{' '}
                  {farm.address.city}
                </span>
              </AddressCellStyle>
              <ActionsCellStyle>
                {farm.is_deletable && (
                  <IconButton
                    icon="delete"
                    onClick={() => handleOnClickOnDelete(farm.id)}
                    data-cy="FarmsList__deleteFarm"
                  />
                )}
                <IconButton
                  icon="edit"
                  onClick={() => handleOnClickOnEdit(farm.id)}
                />
              </ActionsCellStyle>
            </ContentRowWithHoverStyle>
          ))
        ) : (
          <EmptyList text="Il n’y a pas d’exploitations pour l’instant." />
        )}
      </div>
    );
  };
}

FarmsList.propTypes = {
  handleOnClickOnDelete: PropTypes.func.isRequired,
  handleOnClickOnEdit: PropTypes.func.isRequired,
  farms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      farm_name: PropTypes.string.isRequired,
      address: PropTypes.shape({
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        zip_code: PropTypes.number,
      }),
    }),
  ).isRequired,
};
export default FarmsList;
