import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CellStyle from '../../atoms/table/TableCell';
import ContentRowStyle from '../../atoms/table/TableContentRow';

const IdCellStyle = styled(CellStyle)`
  flex: 2;
  > * {
    font-weight: 700;
  }
`;

const MediumCellStyle = styled(CellStyle)`
  flex: 2;
`;

const BreederCellStyle = styled(CellStyle)`
  flex: 3;
`;

const formatDate = date => format(date, 'dd/MM/yyyy');
const parseAndFormatDate = dateString => formatDate(parseISO(dateString));

const Item = ({ heifer }) => {
  return (
    <ContentRowStyle to={`/view/farms/heifer/${heifer.id}`}>
      <IdCellStyle>
        <span>{heifer.id_heifer}</span>
      </IdCellStyle>

      <BreederCellStyle>
        {heifer.producer && <span>{heifer.producer.farm_name}</span>}
      </BreederCellStyle>

      <BreederCellStyle>
        {heifer.breeder && <span>{heifer.breeder.farm_name}</span>}
      </BreederCellStyle>

      <MediumCellStyle>
        {heifer.return_date && (
          <span>{parseAndFormatDate(heifer.return_date)}</span>
        )}
      </MediumCellStyle>

      <MediumCellStyle>
        {heifer.transfer_date && (
          <span>{parseAndFormatDate(heifer.transfer_date)}</span>
        )}
      </MediumCellStyle>
    </ContentRowStyle>
  );
};

Item.propTypes = {
  heifer: PropTypes.shape({
    id: PropTypes.string,
    id_heifer: PropTypes.string.isRequired,
    breeder: PropTypes.shape({
      farm_name: PropTypes.string,
    }),
    producer: PropTypes.shape({
      farm_name: PropTypes.string,
    }),
    return_date: PropTypes.string,
    transfer_date: PropTypes.string,
  }).isRequired,
};

export default Item;
