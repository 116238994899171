import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import ContainerSmall from '../layouts/ContainerSmall';
import { ReactComponent as QuestionMark } from '../statics/ico/questionMark.svg';
import { ReactComponent as MagnifyingGlass } from '../statics/ico/magnifyingGlass.svg';
import H1 from '../atoms/titles/H1';
import H2 from '../atoms/titles/H2';
import Button from '../atoms/Button';

const Wrapper = styled.div`
  margin-top: 40vh;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const IconsContainer = styled.div`
  position: relative;

  .questionMark {
    position: absolute;
    left: 77px;
    top: 61px;
  }
`;

const ContentContainer = styled.div`
  margin-left: 20px;

  ${H1} {
    margin-bottom: 30px;
  }

  .subtitle__first {
    margin-bottom: 10px;
  }

  .subtitle__second {
    margin-bottom: 60px;
  }
`;

const NotFound = () => (
  <ContainerSmall>
    <Wrapper>
      <IconsContainer>
        <QuestionMark className="questionMark" />
        <MagnifyingGlass />
      </IconsContainer>
      <ContentContainer>
        <H1>Erreur 404</H1>
        <H2 blue className="subtitle__first">
          PAGE NON TROUVÉE
        </H2>
        <H2 grey className="subtitle__second">
          La page que vous recherchez ne paraît pas exister.
        </H2>
        <Button onClick={() => navigate('/')}>
          Retourner à l&apos;accueil
        </Button>
      </ContentContainer>
    </Wrapper>
  </ContainerSmall>
);

export default NotFound;
