import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconTitleStyle = styled.div`
  display: flex;
  align-items: center;

  .title__picto {
    background-color: ${({ blue, purple, theme }) =>
      (blue && theme.blue500) ||
      (purple && theme.blueberry500) ||
      theme.orange500};
    padding: 6px;
    border-radius: 5px;
    height: 30px;
    width: 30px;

    > svg {
      width: 20px;
      height: 20px;

      * {
        stroke: #fff;
      }
    }
  }

  > h3 {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};

    > span {
      color: ${({ blue, purple, theme }) =>
        (blue && theme.blue500) ||
        (purple && theme.blueberry500) ||
        theme.orange500};
    }
  }
`;

const IconTitle = ({ children, icon, ...props }) => (
  <IconTitleStyle {...props}>
    <div className="title__picto">{icon}</div>
    <h3>{children}</h3>
  </IconTitleStyle>
);

IconTitle.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default IconTitle;
