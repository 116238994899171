import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-shim';
import 'unorm';
// Above are necessary packages to support IE11

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import Provider from './graphql/Provider';
import Sentry from './organisms/Sentry';
import './styles/index.css';
import 'react-dates/initialize';
import Auth from './organisms/auth';
import * as ServiceWorker from './registerServiceWorker';
import Theme from './styles/theme';
import Toast from './atoms/Toast';
import Main from './layouts/Main';

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <>
      <Auth>
        <Sentry>
          <Provider>
            <Main />
          </Provider>
        </Sentry>
      </Auth>
      <Toast />
    </>
  </ThemeProvider>,
  document.getElementById('root'),
);

ServiceWorker.unregister();
