import styled from 'styled-components';
import { Link } from '@reach/router';

const ContentRowStyle = styled(Link)`
  color: ${({ theme }) => theme.grey500};
  margin-bottom: 1px;
  display: flex;
  background-color: #fff;
  text-decoration: none;
  justify-content: space-between;
`;

export default ContentRowStyle;
