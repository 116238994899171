import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation, Query } from 'react-apollo';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { HeiferWeightByType } from '../commons/heiferEvents';
import ContainerLarge from '../layouts/ContainerLarge';
import { GET_BREEDER_HOME } from '../graphql/queries';
import { CREATE_HEIFERS_MEASUREMENTS } from '../graphql/mutations';
import FarmDelegationInfos from '../molecules/FarmDelegationInfos';
import FarmInfos from '../molecules/FarmInfos';
import FarmAvailabilities from '../molecules/FarmAvailabilities';
import PageHeader from '../molecules/PageHeader';
import HeifersDetailedList from '../molecules/HeifersDetailedList/BreederView';
import Loader from '../atoms/Loader';
import { ReactComponent as HeiferIcon } from '../statics/ico/heifer_blue.svg';
import Modal from '../atoms/Modal';
import CreateMultiEventForm from './CreateMultiEventForm';

const FarmDetailsStyle = styled.div`
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
`;

const FarmAvailabilitiesStyle = styled.section`
  margin-bottom: 30px;
`;

const FarmDetails = ({ farmId }) => {
  const [isAddEventModalOpen, setAddEventModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSelected] = useState([]);

  if (!farmId) return <p>No farm ID</p>;

  const resetSelected = () => {
    setSelected([]);
  };

  function getFormValues(heifers) {
    return {
      heifers: heifers
        .filter(h => h.delegation_status === 'INPROGRESS')
        .map(h => ({
          id: h.id,
          P1: (HeiferWeightByType(h.events, 'P1') && false) || '',
          P2: (HeiferWeightByType(h.events, 'P2') && false) || '',
          P3: (HeiferWeightByType(h.events, 'P3') && false) || '',
        })),
    };
  }

  return (
    <Query
      query={GET_BREEDER_HOME}
      fetchPolicy="network-only"
      variables={{ id: farmId }}
      skip={!farmId}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error.message);
        const { farm } = data;

        return (
          <Mutation
            mutation={CREATE_HEIFERS_MEASUREMENTS}
            refetchQueries={[
              {
                query: GET_BREEDER_HOME,
                variables: { id: farmId },
              },
            ]}
            awaitRefetchQueries
          >
            {createHeifersMeasurements => (
              <ContainerLarge displayHeader color="grey">
                <FarmDetailsStyle>
                  <FarmInfos
                    farm={farm}
                    showAddHeiferButton={false}
                    viewStyle="large"
                  />

                  <FarmDelegationInfos
                    delegPerYear={farm.deleg_per_year}
                    delegationProgress={farm.deleg_in_progress}
                    poolsPerYear={farm.pools_per_year}
                    capacityPerPool={farm.capacity_per_pool}
                  />
                </FarmDetailsStyle>

                <FarmAvailabilitiesStyle>
                  <FarmAvailabilities
                    lightVersion
                    farmId={farm.id}
                    availabilities={farm.availabilities || []}
                  />
                </FarmAvailabilitiesStyle>

                {farm.heifers.length > 0 && (
                  <section>
                    <Formik
                      initialValues={getFormValues(farm.heifers)}
                      onSubmit={async (values, actions) => {
                        try {
                          const params = values.heifers
                            .filter(h => h.P1 || h.P2 || h.P3)
                            .map(({ id, ...measurements }) => ({
                              id,
                              measurements: Object.entries(measurements)
                                .filter(([, value]) => value)
                                .map(([key, value]) => ({
                                  weighingType: key,
                                  weight: value,
                                })),
                            }));

                          await createHeifersMeasurements({
                            variables: { heifers: params },
                          });

                          toast.success('Mise à jour effectué');

                          setIsEditMode(false);
                          actions.resetForm(getFormValues(farm.heifers));
                        } catch (err) {
                          toast.error('Erreur technique');
                          throw err;
                        }
                      }}
                      render={({ values, isSubmitting, handleSubmit }) => {
                        return (
                          <Form>
                            {isEditMode ? (
                              <PageHeader
                                title="Génisses"
                                icon={<HeiferIcon />}
                                primaryButtonLabel="Valider les modifications"
                                primaryButtonAction={handleSubmit}
                                primaryButtonIcon={false}
                                ButtonsDisabled={isSubmitting}
                                secondaryButtonLabel="Annuler"
                                secondaryButtonAction={() =>
                                  setIsEditMode(false)
                                }
                              />
                            ) : (
                              <PageHeader
                                title="Génisses"
                                icon={<HeiferIcon />}
                                primaryButtonLabel="Ajouter un événement"
                                primaryButtonAction={() =>
                                  setAddEventModalOpen(true)
                                }
                                primaryButtonIcon
                                primaryButtonDisabled={selected.length === 0}
                                secondaryButtonLabel="Mettre à jour les poids"
                                secondaryButtonAction={() =>
                                  setIsEditMode(true)
                                }
                                ButtonsDisabled={
                                  isSubmitting || isAddEventModalOpen
                                }
                              />
                            )}
                            <HeifersDetailedList
                              values={values}
                              selected={selected}
                              setSelected={setSelected}
                              heifers={farm.heifers}
                              showInputs={isEditMode}
                              farmType={farm.farm_type}
                            />
                          </Form>
                        );
                      }}
                    />
                  </section>
                )}
                <Modal
                  isOpen={isAddEventModalOpen}
                  onRequestClose={() =>
                    setAddEventModalOpen(!isAddEventModalOpen)
                  }
                >
                  <CreateMultiEventForm
                    toggleModal={() =>
                      setAddEventModalOpen(!isAddEventModalOpen)
                    }
                    heiferIds={selected}
                    resetSelected={resetSelected}
                  />
                </Modal>
              </ContainerLarge>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

FarmDetails.propTypes = {
  farmId: PropTypes.string,
};

FarmDetails.defaultProps = {
  farmId: null,
};

export default FarmDetails;
