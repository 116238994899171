import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import parseIsoDate from 'yup/lib/util/isodate';
import { formatDate } from '../commons/dates';
import Button from '../atoms/Button';
import { UPDATE_FARM_AVAILABILITIES } from '../graphql/mutations';
import H2 from '../atoms/titles/H2';
import { GET_FARM_ADMIN } from '../graphql/queries';
import AvailabilitiesFields from '../molecules/AvailabilitiesFormikFields';
import { sortByDate } from '../commons/sort';
import Loader from '../atoms/Loader';

const FormStyle = styled(Form)`
  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const EditFarmAvailabilities = ({ farmId, closeModal, date }) => (
  <Mutation mutation={UPDATE_FARM_AVAILABILITIES}>
    {updateFarmAvailabilities => (
      <Query query={GET_FARM_ADMIN} variables={{ id: farmId }}>
        {({ loading, error, data: { farm } }) => {
          if (loading) return <Loader />;
          if (error) return `Error! ${error.message}`;

          const initialValues = {
            availabilities: [...sortByDate(farm.availabilities), date],
          };

          const submitAvailabilityDates = async values => {
            const uniqueAvailabilityDates = [
              ...new Set(
                values.availabilities
                  .filter(date => !!date)
                  .map(a => formatDate(parseIsoDate(a))),
              ),
            ];

            try {
              await updateFarmAvailabilities({
                variables: {
                  farm: {
                    id: farmId,
                    availabilities: uniqueAvailabilityDates,
                  },
                },
              });

              toast.success('Les disponibilités ont été mises à jour');
            } catch (err) {
              toast.error(
                "Les disponibilités n'ont pas été mises à jour à cause d'une erreur technique",
              );
            }
          };

          return (
            <div>
              <H2 blue>Disponibilités</H2>
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  await submitAvailabilityDates(values);
                  setSubmitting(true);
                  resetForm(values);
                  closeModal();
                }}
              >
                {({ resetForm, isSubmitting, values }) => (
                  <FormStyle>
                    <AvailabilitiesFields
                      initialLength={values.availabilities.length}
                    />

                    <div className="buttonBar">
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm(initialValues);
                          closeModal();
                        }}
                        secondary
                      >
                        Annuler
                      </Button>

                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        data-cy="editFarmAvailabilities__submit"
                      >
                        Valider
                      </Button>
                    </div>
                  </FormStyle>
                )}
              </Formik>
            </div>
          );
        }}
      </Query>
    )}
  </Mutation>
);

EditFarmAvailabilities.defaultProps = {
  date: null,
};

EditFarmAvailabilities.propTypes = {
  farmId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  date: PropTypes.string,
};

export default EditFarmAvailabilities;
