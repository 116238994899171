import React from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { DELETE_FARM } from '../graphql/mutations';
import { GET_FARMS, GET_FARM_ADMIN } from '../graphql/queries';
import Loader from '../atoms/Loader';

const RemoveEventHeiferStyle = styled.div`
  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const RemoveFarmForm = ({ onRequestClose, farmId, shouldRedirect }) => (
  <Query query={GET_FARM_ADMIN} variables={{ id: farmId }}>
    {query => {
      if (query.loading) return <Loader inline />;
      if (query.error) return `Error! ${query.error.message}`;

      const { farm } = query.data;

      return (
        <Mutation
          mutation={DELETE_FARM}
          refetchQueries={[{ query: GET_FARMS }]}
        >
          {(deleteFarm, { loading }) => (
            <RemoveEventHeiferStyle>
              <H2 blue>Suppression de l&apos;exploitation</H2>
              {farm.is_deletable ? (
                <p>
                  Êtes-vous sûr de vouloir supprimer cette exploitation ? Cette
                  action est irréversible.
                </p>
              ) : (
                <p>
                  Vous ne pouvez pas supprimer cette exploitation car des
                  délégations sont en cours.
                </p>
              )}
              <div className="buttonBar">
                <Button type="button" onClick={onRequestClose} secondary>
                  Annuler
                </Button>
                {farm.is_deletable && (
                  <Button
                    data-cy="removeFarmForm__deleteButton"
                    type="submit"
                    disabled={loading}
                    onClick={async () => {
                      try {
                        await deleteFarm({
                          variables: { id: farmId },
                        });
                        onRequestClose();
                        if (shouldRedirect) {
                          window.history.back();
                        }
                        toast.success("L'exploitation a été supprimée");
                      } catch (err) {
                        toast.error(
                          "L'exploitation n'a pas été supprimée à cause d'une erreur technique",
                        );
                      }
                    }}
                  >
                    Supprimer
                  </Button>
                )}
              </div>
            </RemoveEventHeiferStyle>
          )}
        </Mutation>
      );
    }}
  </Query>
);

RemoveFarmForm.defaultProps = {
  shouldRedirect: false,
};

RemoveFarmForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  farmId: PropTypes.string.isRequired,
  shouldRedirect: PropTypes.bool,
};

export default RemoveFarmForm;
