import styled from 'styled-components';

const H2 = styled.h2`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 26px;
  color: ${({ blue, purple, white, grey, theme }) =>
    (blue && theme.blue500) ||
    (purple && theme.blueberry500) ||
    (grey && theme.grey700) ||
    (white && '#fff') ||
    theme.grey500};
`;

export default H2;
