import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import loginBg from '../statics/img/login_background.jpg';

const AuthStyle = styled.section`
  height: 100vh;
  display: flex;

  .authLayout__background {
    background: url(${loginBg}) no-repeat center center / cover transparent;
    flex: 1 0 0%;
  }

  .authLayout__content {
    flex: 0 0 550px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AuthLayout = ({ children }) => (
  <AuthStyle>
    <div className="authLayout__background" />
    <div className="authLayout__content">{children}</div>
  </AuthStyle>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
