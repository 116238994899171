/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parse, parseISO } from 'date-fns';
import { withApollo } from 'react-apollo';
import { farmRoles } from '../constants';

import HeaderRowStyle from '../atoms/table/TableHeaderRow';
import CellStyle from '../atoms/table/TableCell';
import ContentRowStyle from '../atoms/table/TableContentRow';
import {
  getEventDate,
  getHeiferAgeAtCalving,
  HEIFER_STATUS,
  HEIFER_STATUS_LABEL_UPCASE,
  heiferCurrentState,
} from '../commons/heiferEvents';
import {
  orderByAttribut,
  orderByEventDate,
  orderByFarmName,
  orderByValue,
} from '../commons/orderList';
import EmptyList from './EmptyList';
import HeiferStatus from '../atoms/HeiferStatus';
import Select from '../atoms/Select';
import { GET_BILLINGS, GET_POOLS } from '../graphql/queries';
import loader from '../statics/loader.gif';

export const HeiferCellStyle = styled(CellStyle)`
  width: 13%;
  > * {
    font-weight: 700;
  }
`;

export const FarmerCellStyle = styled(CellStyle)`
  width: 21%;
  > * {
    font-weight: 700;
  }
`;

const SmallCellStyle = styled(CellStyle)`
  width: 10%;
  > * {
    font-weight: 700;
  }
`;

const ListCheckBtnStyle = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select {
    cursor: pointer;
    color: ${({ theme }) => theme.blue500};
    font-weight: 700;
  }
  .unselect {
    cursor: pointer;
    margin-left: 22px;
  }
`;

const getHeiferHydrater = (defaultAge, pools, billings, billingType) => {
  return heifer => {
    let ageMiniNoReturn = null;

    const poolOfHeifer = pools.find(pool =>
      pool.heifers.filter(h => !!h).some(h => h.id_heifer === heifer.id_heifer),
    );
    if (poolOfHeifer) {
      const filteredBillings = billings.filter(
        billing =>
          billing.billing_type === billingType &&
          parse(billing.start_date, 'dd/MM/yyyy', new Date()) <
            parseISO(poolOfHeifer.transfer_date) &&
          parse(billing.end_date, 'dd/MM/yyyy', new Date()) >
            parseISO(poolOfHeifer.transfer_date),
      );
      ageMiniNoReturn =
        filteredBillings.length > 0
          ? filteredBillings[0].no_return.toString()
          : '30';
    }

    return {
      ...heifer,
      ageMiniNoReturn,
      state: heiferCurrentState(
        heifer.events,
        ageMiniNoReturn,
        billingType === 'BIO' ? 'BIO' : 'CONV',
      ),
    };
  };
};

class HeifersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDirection: 'asc',
      orderValue: 'id_heifer',
      filter: 'ALL',
      defaultAge: '30',
      loading: false,
      isBio: false,
      pools: [],
      billings: [],
    };
  }

  componentDidMount = async () => {
    const { client, heifers } = this.props;
    this.setState({ loading: true });

    const poolResponse = await client.query({
      query: GET_POOLS,
      fetchPolicy: 'network-only',
      variables: { type: 'DELEGATION' },
    });
    const { pools } = poolResponse.data;
    const billingResponse = await client.query({
      query: GET_BILLINGS,
      variables: {},
    });
    const { billings } = billingResponse.data;

    this.setState({
      pools,
      billings,
      isBio: heifers[0].producer.farm_type === 'BIO',
      loading: false,
    });
  };

  getOrderedList = heifers => {
    const { orderValue, orderDirection } = this.state;
    const { role } = this.props;
    const orderStatement = orderDirection === 'asc' ? 1 : -1;

    switch (orderValue) {
      case 'farm_name':
        return role === 'breeder'
          ? orderByFarmName(heifers, 'producer_summary', orderStatement)
          : orderByFarmName(heifers, 'breeder_summary', orderStatement);
      case 'birth':
        return orderByEventDate(heifers, orderStatement, 'BIRTH');
      case 'age':
        return orderByValue(heifers, getHeiferAgeAtCalving, orderStatement);
      case 'delegation':
        return orderByEventDate(heifers, orderStatement, 'DELEGATION');
      case 'return':
        return orderByEventDate(heifers, orderStatement, 'RETURN');
      case 'calving':
        return orderByEventDate(heifers, orderStatement, 'CALVING');
      case 'state':
        return heifers.sort((heifer1, heifer2) => {
          const comparison = (heifer1.state || '').localeCompare(
            heifer2.state || '',
          );

          return comparison * orderStatement;
        });
      default:
        return orderByAttribut(heifers, 'id_heifer', orderStatement);
    }
  };

  toggleOrder = orderValue => {
    const { orderDirection } = this.state;

    this.setState({
      orderValue,
      orderDirection: orderDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  setDisplayFilter = e => {
    if (typeof e === 'object' && 'target' in e && 'value' in e.target) {
      this.setState({ filter: e.target.value });
    }
  };

  filterHeifer = exclude => {
    const { filter } = this.state;
    return heifer => {
      const heiferState = heifer.state;
      if (filter === heiferState) return true;
      if (exclude && heiferState === HEIFER_STATUS._9_DEAD) return false;
      if (exclude && heiferState === HEIFER_STATUS._8_ENDED) return false;
      if (filter === 'ALL') return true;
      return heiferState === filter;
    };
  };

  render = () => {
    const { role, heifers } = this.props;
    const {
      filter,
      defaultAge,
      loading,
      isBio,
      orderValue,
      orderDirection,
      pools,
      billings,
    } = this.state;

    const heifersToDisplay = this.getOrderedList(
      heifers.map(getHeiferHydrater(defaultAge, pools, billings)),
    )
      .filter(this.filterHeifer(true))
      .map(heifer => ({
        ...heifer,
        producer_name:
          (heifer.producer && heifer.producer.farm_name) ||
          (heifer.producer_summary && heifer.producer_summary.farm_name),
        breeder_name:
          (heifer.breeder && heifer.breeder.farm_name) ||
          (heifer.breeder_summary && heifer.breeder_summary.farm_name),
      }));

    return (
      <div>
        <ListCheckBtnStyle>
          <div className="left" />
          <Select
            label="Afficher"
            labelNextTo
            name="display"
            value={filter}
            onChange={this.setDisplayFilter}
          >
            <option value="ALL">Toutes</option>
            {Object.getOwnPropertyNames(HEIFER_STATUS)
              .filter(status =>
                isBio
                  ? status !== HEIFER_STATUS._6_OLD
                  : status !== HEIFER_STATUS._11_OLD_BIO,
              )
              .map(status => (
                <option value={status} key={status}>
                  {HEIFER_STATUS_LABEL_UPCASE[status].replace(
                    '%age',
                    defaultAge || '',
                  )}
                </option>
              ))}
          </Select>
        </ListCheckBtnStyle>
        <HeaderRowStyle>
          <HeiferCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'id_heifer' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('id_heifer')}
            >
              Génisse
            </button>
          </HeiferCellStyle>
          <FarmerCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'farm_name' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('farm_name')}
            >
              {role === 'producer' ? farmRoles.breeder : farmRoles.producer}
            </button>
          </FarmerCellStyle>
          <SmallCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'birth' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('birth')}
            >
              Naissance
            </button>
          </SmallCellStyle>
          <HeiferCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'age' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('age')}
            >
              Âge au vêlage
            </button>
          </HeiferCellStyle>
          <SmallCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'delegation' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('delegation')}
            >
              Départ
            </button>
          </SmallCellStyle>
          <SmallCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'return' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('return')}
            >
              Retour
            </button>
          </SmallCellStyle>
          <SmallCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'calving' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('calving')}
            >
              Terme
            </button>
          </SmallCellStyle>
          <HeiferCellStyle>
            <button
              className={`sortButton ${
                orderValue === 'state' ? `active ${orderDirection}` : ''
              }`}
              onClick={() => this.toggleOrder('state')}
            >
              État
            </button>
          </HeiferCellStyle>
        </HeaderRowStyle>

        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={loader} alt="Loader" />
          </div>
        )}

        {!loading && heifersToDisplay.length > 0 ? (
          heifersToDisplay.map(heifer => (
            <ContentRowStyle
              key={heifer.id}
              to={`/view/farms/heifer/${heifer.id}`}
            >
              <HeiferCellStyle data-cy="heifersList__heiferId">
                <span>{heifer.id_heifer}</span>
              </HeiferCellStyle>
              <FarmerCellStyle>
                <span>
                  {role === 'producer'
                    ? heifer.breeder_name
                    : heifer.producer_name}
                </span>
              </FarmerCellStyle>

              <SmallCellStyle>
                <span>{getEventDate(heifer.events, 'BIRTH')}</span>
              </SmallCellStyle>

              <SmallCellStyle>
                <span>
                  {getHeiferAgeAtCalving(heifer.events)
                    ? `${getHeiferAgeAtCalving(heifer.events)} mois`
                    : ''}
                </span>
              </SmallCellStyle>

              <SmallCellStyle>
                <span>{getEventDate(heifer.events, 'DELEGATION')}</span>
              </SmallCellStyle>
              <SmallCellStyle>
                <span>{getEventDate(heifer.events, 'RETURN')}</span>
              </SmallCellStyle>
              <SmallCellStyle>
                <span>{getEventDate(heifer.events, 'CALVING')}</span>
              </SmallCellStyle>

              <HeiferCellStyle>
                <HeiferStatus
                  status={heifer.state}
                  label={heifer.ageMiniNoReturn || defaultAge}
                />
              </HeiferCellStyle>
            </ContentRowStyle>
          ))
        ) : (
          <EmptyList text="Aucune génisse n’a été ajoutée pour l’instant." />
        )}
      </div>
    );
  };
}

HeifersList.propTypes = {
  heifers: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
};

export default withApollo(HeifersList);
