import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';

import { ReactComponent as Arrow } from '../statics/ico/arrow.svg';

const BreadcrumbStyle = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 1100px) {
    display: none;
  }

  svg {
    margin-right: 6px;
    width: 8px;
    height: 11px;

    * {
      stroke: #fff;
      opacity: 0.5;
    }
  }
`;

const Breadcrumb = () => (
  <Location>
    {({ location }) => {
      // Don't show return button if current pathname is a homepage
      if (location.pathname === '/') return null;

      return (
        <BreadcrumbStyle onClick={() => window.history.back()}>
          <Arrow />
          Retour
        </BreadcrumbStyle>
      );
    }}
  </Location>
);

export default Breadcrumb;
