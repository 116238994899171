import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

const PoolDateStyle = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.darkShadow};
  padding: 17px 21px;

  .transferDate__title {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey500};
    padding-right: 10px;
  }

  .transferDate__value {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
  }
`;

const PoolDate = ({ title, date }) => (
  <PoolDateStyle>
    <p className="transferDate__title">{title}</p>
    <p className="transferDate__value">
      {format(parseISO(date), 'dd/MM/yyyy')}
    </p>
  </PoolDateStyle>
);

PoolDate.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default PoolDate;
