import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { toast } from 'react-toastify';
import { Link } from '@reach/router';
import { format, parseISO } from 'date-fns';

import Modal from '../atoms/Modal';
import { UPDATE_POOL_TRANSFER_DATE } from '../graphql/mutations';
import IconButton from '../atoms/IconButton';
import { ReactComponent as CheckIcon } from '../statics/ico/check.svg';
import Button from '../atoms/Button';
import DatePicker from '../atoms/DatePicker';
import H2 from '../atoms/titles/H2';
import Checkbox from '../atoms/Checkbox';
import AssignedPoolConfirmation from '../organisms/AssignedPoolConfirmation';
import CheckPoolAvailabilitiesConfirm from '../organisms/CheckPoolAvailabilitiesConfirm';

const ToggleButton = styled.div`
  position: absolute;
  right: 18px;
  top: 9px;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : '')};
`;

const ItemStyle = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ selected, theme }) =>
    selected ? theme.blue100 : '#fff'};
  flex-direction: ${({ poolType }) =>
    poolType === 'DELEGATION' ? 'column' : 'row'};
  justify-content: ${({ poolType }) =>
    poolType === 'DELEGATION' ? 'none' : 'space-between'};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  .topSide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 55px 8px 15px;
    min-height: 51px;
  }

  .bottomSide {
    min-height: 51px;
    display: flex;
    flex-direction: column;
    padding: ${({ poolType }) =>
      poolType === 'RETURN' ? '8px 70px 8px 15px' : '8px 15px'};
    background-color: ${({ theme, selected }) =>
      selected ? 'transparent' : theme.grey200};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }

  .bottomSide__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottomSide__bottom {
    text-align: right;
  }

  .dateContainer {
    display: flex;
    align-items: center;
  }

  .titleContainer {
    display: flex;
  }

  .title {
    margin-left: ${({ poolType }) => (poolType === 'RETURN' ? '15px' : '0')};
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }

  .buttonsContainer {
    display: flex;
    margin: 0 -5px 0 5px;

    & > * {
      margin: 0 5px;
    }
  }

  .heifersLength {
    display: ${({ poolType }) => (poolType === 'RETURN' ? 'none' : 'block')};
    span {
      font-weight: 700;
    }
  }
`;

const NotificationDate = styled.p`
  margin-top: 5px;
  font-size: 12px;

  svg {
    width: 11px;
    height: 8px;
    margin-right: 5px;

    path {
      stroke: currentColor;
    }
  }
`;

const InfosDatePickerStyle = styled.div`
  display: flex;
  align-items: center;
`;

const getInfosDatePicker = (type, id) => (
  <InfosDatePickerStyle>
    <H2 blue>Date de {`${type === 'RETURN' ? 'retour' : 'transfert'}`} - </H2>
    <div>&nbsp;</div>
    <H2> lot {id}</H2>
  </InfosDatePickerStyle>
);

const Item = ({
  onShow,
  opened,
  poolId,
  poolType,
  transferDate,
  activateButtons,
  poolIdToShow,
  onSelectPool,
  selected,
  farmName,
  farmId,
  heifersLength,
  disabled,
  capacity,
  lastPoolAvailabilitiesNotification,
  client,
}) => {
  const [
    isConfirmAssignedPoolModalOpen,
    setConfirmAssignedPoolModalOpen,
  ] = useState(false);
  const [
    isCheckAvailabilitiesModalOpen,
    setCheckAvailabilitiesModalOpen,
  ] = useState(false);

  const toggleCheckAvailabilitiesModal = () => {
    setCheckAvailabilitiesModalOpen(!isCheckAvailabilitiesModalOpen);
  };

  const handleOnChangeTransferDate = async date => {
    try {
      if (!poolId) throw new Error('no poolId');

      await client.mutate({
        mutation: UPDATE_POOL_TRANSFER_DATE,
        variables: {
          id: poolId,
          date,
        },
      });
      toast.success('La date de transfert a été modifiée');
    } catch (err) {
      toast.error(
        "La date de transfert n'a pas été modifiée à cause d'une erreur technique",
      );
      throw err;
    }
  };

  return (
    <>
      <ItemStyle poolType={poolType} selected={selected} disabled={disabled}>
        {onShow && (
          <ToggleButton opened={opened}>
            <IconButton
              icon="chevron"
              color="blue"
              size="large"
              onClick={onShow}
            />
          </ToggleButton>
        )}

        <div className="topSide">
          <div className="titleContainer">
            {poolType === 'RETURN' && (
              <Checkbox
                name={poolId}
                checked={selected}
                onChange={onSelectPool}
              />
            )}

            <Link className="title" to={`/view/farms/${farmId}`}>
              {farmName}
            </Link>
          </div>

          <p className="heifersLength">
            <span>{heifersLength}</span> / {capacity} génisse
            {capacity > 1 ? 's' : ''}
          </p>
        </div>

        <div className="bottomSide">
          <div className="bottomSide__top">
            <div className="dateContainer">
              <DatePicker
                date={transferDate}
                id={poolId || farmName}
                isModal
                info={getInfosDatePicker(poolType, poolIdToShow)}
                onChange={e => handleOnChangeTransferDate(e)}
              />
            </div>

            {!disabled && (
              <div className="buttonsContainer">
                <Button
                  disabled={!activateButtons}
                  onClick={toggleCheckAvailabilitiesModal}
                  secondary
                >
                  Vérifier les disponibilités
                </Button>

                <Button
                  disabled={!activateButtons}
                  onClick={() => setConfirmAssignedPoolModalOpen(true)}
                  data-cy="poolWaitingItem__assign"
                >
                  Affecter
                </Button>
              </div>
            )}
          </div>

          <div className="bottomSide__bottom">
            {lastPoolAvailabilitiesNotification && (
              <NotificationDate>
                <CheckIcon />
                <span>
                  Vérification de dispo. faite{' '}
                  {format(
                    parseISO(lastPoolAvailabilitiesNotification),
                    'dd/MM à HH:mm',
                  )}
                </span>
              </NotificationDate>
            )}
          </div>
        </div>
      </ItemStyle>

      <Modal
        isOpen={isConfirmAssignedPoolModalOpen}
        onRequestClose={() => setConfirmAssignedPoolModalOpen(false)}
      >
        <AssignedPoolConfirmation
          closeModal={() => setConfirmAssignedPoolModalOpen(false)}
          id={poolId}
        />
      </Modal>

      <Modal
        isOpen={isCheckAvailabilitiesModalOpen}
        onRequestClose={toggleCheckAvailabilitiesModal}
      >
        <CheckPoolAvailabilitiesConfirm
          toggleModal={toggleCheckAvailabilitiesModal}
          id={poolId}
        />
      </Modal>
    </>
  );
};

Item.defaultProps = {
  onShow: null,
  opened: null,
  selected: null,
  poolId: null,
  poolIdToShow: null,
  activateButtons: null,
  heifersLength: null,
  onSelectPool: () => {},
  disabled: false,
  capacity: null,
  lastPoolAvailabilitiesNotification: null,
};

Item.propTypes = {
  onShow: PropTypes.func,
  opened: PropTypes.bool,
  selected: PropTypes.bool,
  poolId: PropTypes.string,
  poolIdToShow: PropTypes.string,
  lastPoolAvailabilitiesNotification: PropTypes.string,
  transferDate: PropTypes.string.isRequired,
  farmName: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  poolType: PropTypes.string.isRequired,
  heifersLength: PropTypes.number,
  capacity: PropTypes.number,
  activateButtons: PropTypes.bool,
  onSelectPool: PropTypes.func,
  disabled: PropTypes.bool,
  client: PropTypes.shape({ mutate: PropTypes.func.isRequired }).isRequired,
};

export default withApollo(Item);
