export const formErrorMsgs = {
  required: 'Veuillez renseigner ce champ',
  greaterThanZero: 'Veuillez renseigner une valeur supérieur à 0',
  lengthTenDigits: 'Veuillez renseigner un numéro à 10 chiffres',
  lengthHeightDigits: 'Veuillez renseigner un numéro à 8 chiffres',
  lengthSixDigits: 'Veuillez renseigner un numéro à 6 chiffres',
  lengthFiveDigits: 'Veuillez renseigner un numéro à 5 chiffres',
  lengthFourDigits: 'Veuillez renseigner un numéro à 4 chiffres',
  betweenOneAndFour: 'Veuillez renseigner une valeur entre 1 et 4',
  email: "Veuillez vérifier l'email indiqué",
  exitingHeiferId: 'Ce numéro de génisse existe déjà',
  exitingEdeNumber: 'Ce numéro EDE existe déjà',
};

export const farmRoles = {
  producer: 'Naisseur',
  breeder: 'Éleveur',
};

export const EVENT_TYPES = {
  ABATTOIR: 'ABATTOIR',
  ABORTION: 'ABORTION',
  BIRTH: 'BIRTH',
  BREEDING_TIP: 'BREEDING_TIP',
  CALVING: 'CALVING',
  DEATH: 'DEATH',
  DELEGATION: 'DELEGATION',
  DISEASE: 'DISEASE',
  INSEMINATION: 'INSEMINATION',
  MEASUREMENT: 'MEASUREMENT',
  NO_RETURN: 'NO_RETURN',
  OTHER: 'OTHER',
  RETURN: 'RETURN',
  RETURN_ACCEPTED: 'RETURN_ACCEPTED',
  SOLD: 'SOLD',
  SONOGRAM_1: 'SONOGRAM1',
  SONOGRAM_2: 'SONOGRAM2',
  SONOGRAM_EMPTY: 'SONOGRAMV',
  SONOGRAM_OTHERS: 'SONOGRAMAUTRES',
};

export const UNIQUE_EVENT_TYPES_BY_HEIFER = [
  EVENT_TYPES.BIRTH,
  EVENT_TYPES.SONOGRAM_1,
  EVENT_TYPES.SONOGRAM_2,
  EVENT_TYPES.DEATH,
  EVENT_TYPES.ABATTOIR,
];

export const NON_REMOVABLE_EVENTS_TYPE = [EVENT_TYPES.BIRTH];

export const EVENT_TYPES_BREEDERS_CAN_EDIT = [
  EVENT_TYPES.ABORTION,
  EVENT_TYPES.ABATTOIR,
  EVENT_TYPES.DEATH,
  EVENT_TYPES.DISEASE,
  EVENT_TYPES.INSEMINATION,
  EVENT_TYPES.MEASUREMENT,
  EVENT_TYPES.SONOGRAM_1,
  EVENT_TYPES.SONOGRAM_2,
  EVENT_TYPES.SONOGRAM_EMPTY,
  EVENT_TYPES.SONOGRAM_OTHERS,
];

export const WEIGHT_STATUSES = {
  VERY_LOW: 'VERY_LOW',
  LOW: 'LOW',
  OK: 'OK',
};

/* Redefine french days with right order because of a bug in moment.js
    example : day.weekday() === FrenchDays.MARDI
*/
export const FrenchDays = {
  LUNDI: 0,
  MARDI: 1,
  MERCREDI: 2,
  JEUDI: 3,
  VENDREDI: 4,
  SAMEDI: 5,
  DIMANCHE: 6,
};
