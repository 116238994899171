import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonLinkIcon from '../atoms/ButtonLinkIcon';
import H2 from '../atoms/titles/H2';
import Button from '../atoms/Button';
import InlineList from '../atoms/InlineList';
import Modal from '../atoms/Modal';
import CreateHeiferForm from '../organisms/CreateHeiferForm';
import EditFarmForm from '../organisms/EditFarmForm';
import RemoveFarmForm from '../organisms/RemoveFarmForm';
import UserContext from '../context';

const FarmHeader = styled.div`
  padding: 30px 20px 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .titleContainer {
    display: flex;
    align-items: center;
  }

  .title {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`;

const FarmDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${({ viewStyle }) =>
    (viewStyle === 'large' && 'row') || 'column'};
  justify-content: space-between;
`;

const FarmExtraDetails = styled.div`
  padding: 10px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .farm__contact {
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

const FarmDetailsStyle = styled.div`
  display: flex;
  flex-direction: ${({ viewStyle }) =>
    (viewStyle === 'large' && 'column') || 'row'};
  padding: 10px 30px;

  .item {
    line-height: 22px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    span {
      font-weight: 700;
    }
  }
`;

const FarmInfos = ({ farm, showAddHeiferButton, viewStyle }) => {
  const [createHeiferOpen, changeCreateHeiferOpen] = useState(false);
  const [editFarmOpen, changeEditFarmOpen] = useState(false);
  const [removeFarmOpen, changeRemoveFarmOpen] = useState(false);
  const { userGroups } = useContext(UserContext);
  const isAdmin = userGroups.includes('admin');

  return (
    <div>
      <FarmHeader>
        <div className="titleContainer">
          <H2 className="title" blue>
            {farm.farm_name}
          </H2>
          {isAdmin && (
            <div>
              <Button
                small
                secondary
                className="button__edit"
                onClick={() => changeEditFarmOpen(!editFarmOpen)}
                data-cy="farm_update"
              >
                Modifier
              </Button>
            </div>
          )}
        </div>
        {isAdmin && (
          <div data-tip data-for="deleteHeiferAlert">
            <ButtonLinkIcon
              icon="delete"
              disabled={!farm.is_deletable}
              onClick={() => changeRemoveFarmOpen(!removeFarmOpen)}
            >
              Supprimer l&apos;exploitation
            </ButtonLinkIcon>
          </div>
        )}
      </FarmHeader>

      <FarmDetailsContainer viewStyle={viewStyle}>
        <FarmDetailsStyle viewStyle={viewStyle}>
          {farm.intracommunity_number && (
            <p className="item">
              N°Intracommunautaire : <span>{farm.intracommunity_number}</span>
            </p>
          )}
          <p className="item">
            N°EDE : <span>{farm.ede_number}</span>
          </p>
          {farm.farm_type && (
            <p className="item">
              Type d&apos;élevage : <span>{farm.farm_type}</span>
            </p>
          )}
        </FarmDetailsStyle>
        <FarmExtraDetails>
          <div className="farm__contact">
            <InlineList separator>
              <p>{farm.name}</p>
              <p>
                {farm.address.street}, {farm.address.zip_code}{' '}
                {farm.address.city}
              </p>
            </InlineList>
            <InlineList>
              {farm.email && <p>{farm.email}</p>}
              {farm.phone_number && <p>{farm.phone_number}</p>}
            </InlineList>
          </div>
          {farm.role === 'producer' && showAddHeiferButton && (
            <div>
              <Button
                className="button__add"
                iconPlus
                onClick={() => changeCreateHeiferOpen(!createHeiferOpen)}
              >
                Ajouter une génisse
              </Button>
            </div>
          )}
        </FarmExtraDetails>
      </FarmDetailsContainer>
      {'tag_id' in farm && farm.tag_id && (
        <Modal
          isOpen={createHeiferOpen}
          onRequestClose={() => changeCreateHeiferOpen(!createHeiferOpen)}
        >
          <CreateHeiferForm
            toggleModal={() => changeCreateHeiferOpen(!createHeiferOpen)}
            farmId={farm.id}
            farmName={farm.farm_name}
            tagId={farm.tag_id}
            isAdmin={isAdmin}
          />
        </Modal>
      )}
      <Modal
        isOpen={editFarmOpen}
        onRequestClose={() => changeEditFarmOpen(!editFarmOpen)}
      >
        <EditFarmForm
          onRequestClose={() => changeEditFarmOpen(!editFarmOpen)}
          farmId={farm.id}
        />
      </Modal>
      <Modal
        isOpen={removeFarmOpen}
        onRequestClose={() => changeRemoveFarmOpen(!removeFarmOpen)}
      >
        <RemoveFarmForm
          onRequestClose={() => changeRemoveFarmOpen(!removeFarmOpen)}
          farmId={farm.id}
          shouldRedirect
        />
      </Modal>
    </div>
  );
};

FarmInfos.defaultProps = {
  showAddHeiferButton: true,
  viewStyle: 'normal',
};

FarmInfos.propTypes = {
  viewStyle: PropTypes.oneOf(['large', 'normal']),
  showAddHeiferButton: PropTypes.bool,
  farm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    farm_name: PropTypes.string,
    deleg_in_progress: PropTypes.number,
    deleg_per_year: PropTypes.number,
    pools_per_year: PropTypes.number,
    intracommunity_number: PropTypes.string,
    ede_number: PropTypes.number.isRequired,
    capacity_per_pool: PropTypes.number,
    role: PropTypes.oneOf(['producer', 'breeder']).isRequired,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    farm_type: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      zip_code: PropTypes.number,
    }).isRequired,
    tag_id: PropTypes.number,
    is_deletable: PropTypes.bool,
  }).isRequired,
};

export default FarmInfos;
