import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Mutation } from 'react-apollo';

import CellStyle from '../../atoms/table/TableCell';
import Button from '../../atoms/Button';
import { IMPORT_DATA } from '../../graphql/mutations';

const Container = styled.div`
  color: ${({ theme }) => theme.grey500};
  margin-bottom: 1px;
  display: flex;
  background-color: #fff;
  text-decoration: none;
  .button {
    padding: 10px;
    color: white;
  }
`;

const TestCell = styled(CellStyle)`
  flex: 3;
  > * {
    font-weight: 700;
    text-decoration: none;
  }
`;

const SmallCell = styled(CellStyle)`
  flex: 1;
`;

const uploadTestData = (importData, setSubmitting, id, mode) => async () => {
  setSubmitting(true);
  toast.success(`chargement du jeu de données ${id}`);
  try {
    await importData({ variables: { id, mode } });
    toast.success(`le jeu de données ${id} a été chargé`);
  } catch (e) {
    console.error(e);
    toast.error(`Une erreur a empêché de charger le jeu de données ${id}`);
  } finally {
    setSubmitting(false);
  }
};

const Item = ({ mode, test, isSubmitting, setSubmitting }) => (
  <Mutation mutation={IMPORT_DATA}>
    {importData => (
      <Container>
        <SmallCell>
          <span>{test.id}</span>
        </SmallCell>
        <SmallCell>
          <span>{test.label}</span>
        </SmallCell>
        <TestCell>
          <span>{test.note}</span>
        </TestCell>
        <TestCell>
          <Button
            className="button"
            type="button"
            disabled={isSubmitting}
            onClick={uploadTestData(importData, setSubmitting, test.id, mode)}
          >
            Importer le jeu de données
          </Button>
        </TestCell>
      </Container>
    )}
  </Mutation>
);

Item.propTypes = {
  mode: PropTypes.string.isRequired,
  test: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
};

export default memo(Item);
