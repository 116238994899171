import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Arrow from '../../statics/ico/arrow.svg';

const ArrowIconStyle = styled.div`
  transform: ${({ direction }) =>
    direction === 'right' ? 'rotate(180deg)' : ''};
`;

const ArrowIcon = ({ direction }) => (
  <ArrowIconStyle direction={direction}>
    <img src={Arrow} alt={direction} />
  </ArrowIconStyle>
);

ArrowIcon.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default ArrowIcon;
