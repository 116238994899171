import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { getEventDate } from '../../commons/heiferEvents';

const Container = styled.div`
  overflow-y: scroll;
  background-color: #fff;
  margin-bottom: 2px;
`;

const Item = styled(Link)`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.grey500};
  text-decoration: none;

  > * {
    padding: 12px;
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.grey200};
  }

  .farmName {
    flex: 3;
  }
`;

const HeifersList = ({ list }) => (
  <Container>
    {list.map(h => (
      <Item to={`/heifer/${h.id}`} key={h.id}>
        <div>{h.id_heifer}</div>
        <div />
        <div />
        <div>vêlage estimé le {getEventDate(h.events, 'CALVING')}</div>
        <div />
      </Item>
    ))}
  </Container>
);

HeifersList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(HeifersList);
