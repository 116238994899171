import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';
import { DELETE_HEIFER } from '../graphql/mutations';

const RemoveEventHeiferStyle = styled.div`
  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const RemoveHeiferForm = ({ toggleModal, heiferId, source }) => (
  <Mutation mutation={DELETE_HEIFER}>
    {(deleteHeifer, { loading }) => (
      <RemoveEventHeiferStyle>
        {source === 'edit' ? (
          <>
            <H2 blue>Mort de la génisse</H2>
            <p>
              La mort de la génisse hors délégation supprimera la génisse.
              Confirmez-vous cette action ?
            </p>
          </>
        ) : (
          <>
            <H2 blue>Suppression de la génisse</H2>
            <p>
              Êtes-vous sûr de vouloir supprimer cette génisse ? Cette action
              est irréversible.
            </p>
          </>
        )}
        <div className="buttonBar">
          <Button type="button" onClick={toggleModal} secondary>
            Annuler
          </Button>
          <Button
            data-cy="removeFarmForm__deleteButton"
            type="submit"
            disabled={loading}
            onClick={async () => {
              try {
                await deleteHeifer({
                  variables: { id: heiferId },
                });
                toggleModal();
                toast.success('La génisse a été supprimée');
                window.history.back();
              } catch (err) {
                toast.error(
                  "La génisse n'a pas été supprimée à cause d'une erreur technique",
                );
              }
            }}
          >
            Supprimer
          </Button>
        </div>
      </RemoveEventHeiferStyle>
    )}
  </Mutation>
);

RemoveHeiferForm.defaultProps = {
  source: null,
};

RemoveHeiferForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  heiferId: PropTypes.string.isRequired,
  source: PropTypes.string,
};

export default RemoveHeiferForm;
