import React from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { formatDate } from '../commons/dates';
import { CREATE_EVENT } from '../graphql/mutations';
import Button from '../atoms/Button';
import { FormikTextArea } from '../molecules/Formik';
import H2 from '../atoms/titles/H2';
import { formErrorMsgs } from '../constants';
import { getCustomError } from '../commons/graphqlError';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .form__item--small {
    flex: 0 1 130px;
  }

  .form__item--medium {
    flex: 0 1 250px;
  }

  .form__item--centered {
    display: flex;
    margin: 26px 0 0 10px;
    align-items: flex-start;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const initialValues = {
  note: '',
};

const formSchema = () =>
  Yup.object().shape({
    note: Yup.string().required(formErrorMsgs.required),
  });

const CreateEventBreedingTip = ({ toggleModal, heiferId }) => (
  <>
    <H2 blue>Conseil accouplement</H2>

    <Mutation mutation={CREATE_EVENT}>
      {createEvent => (
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema()}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const variables = {
                id_heifer: heiferId,
                event: {
                  type: 'BREEDING_TIP',
                  date: formatDate(new Date()),
                  note: values.note,
                },
              };

              await createEvent({ variables });

              toast.success('Votre note a été ajoutée');
            } catch (err) {
              if (getCustomError(err, 'HEIFER_DEAD')) {
                toast.error(
                  "Votre note n'a pas été ajoutée car la génisse est déclarée morte",
                );
              } else {
                toast.error(
                  "Votre note n'a pas été ajoutée à cause d'une erreur technique",
                );
              }
            }

            setSubmitting(false);
            resetForm(initialValues);
            toggleModal();
          }}
        >
          {({ resetForm, isSubmitting }) => (
            <FormStyle>
              <div className="form__row">
                <div className="form__item">
                  <Field name="note" label="Note" component={FormikTextArea} />
                </div>
              </div>

              <div className="buttonBar">
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm(initialValues);
                    toggleModal();
                  }}
                  secondary
                >
                  Annuler
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </div>
            </FormStyle>
          )}
        </Formik>
      )}
    </Mutation>
  </>
);

CreateEventBreedingTip.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  heiferId: PropTypes.string.isRequired,
};

export default CreateEventBreedingTip;
