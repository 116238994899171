import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CrossIcon from '../statics/ico/cross__white.svg';

const CloseButtonStyle = styled('button')`
  position: absolute;
  background: transparent;
  border: none;
  right: 10px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;

  img {
    width: 10px;
    height: 10px;
    opacity: 0.7;
    transition: ${({ theme }) => theme.transition};
  }

  &:hover img {
    opacity: 1;
  }
`;

const CloseButton = ({ closeToast }) => (
  <CloseButtonStyle onClick={closeToast}>
    <img src={CrossIcon} alt="close" />
  </CloseButtonStyle>
);

CloseButton.defaultProps = {
  closeToast: () => {},
};

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

const ToastStyle = styled.div`
  .Toastify__toast-container {
    top: 127px;
  }

  .Toastify__toast {
    min-height: 0;
    border-radius: 100px;
    box-shadow: none;
    text-align: center;
    padding: 8px 44px;

    &.Toastify__toast--success {
      background-color: ${({ theme }) => theme.overlayBgColor};
    }

    &.Toastify__toast--error {
      background-color: ${({ theme }) => theme.overlayErrorBgColor};
    }
  }

  .Toastify__toast-body {
    font-size: 14px;
  }
`;

const Toast = () => (
  <ToastStyle>
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable={false}
      pauseOnHover
      transition={Slide}
      closeButton={<CloseButton />}
    />
  </ToastStyle>
);

export default Toast;
