import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import locale from 'date-fns/locale/fr';

import Modal from '../atoms/Modal';
import H2 from '../atoms/titles/H2';
import { SEND_MONTHLY_RETURN_POOLS_REPORT } from '../graphql/mutations';
import Button from '../atoms/Button';

const ModalStyle = styled.div`
  .supTitle {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * {
      margin: 0 10px;
    }
  }
`;

class MonthlyReturnPoolsReportButton extends Component {
  state = {
    isModalOpen: false,
    isLoading: false,
  };

  handleSendReport = async () => {
    const { client, month, year } = this.props;

    try {
      this.setState({ isLoading: true });
      await client.mutate({
        mutation: SEND_MONTHLY_RETURN_POOLS_REPORT,
        variables: {
          month,
          year,
        },
      });
      toast.success('Le récapitulatif a été envoyé');
      this.setState({ isLoading: false });
      this.toggleModal();
    } catch (err) {
      toast.error(
        "Le récapitulatif n'a pas été envoyé à cause d'une erreur technique",
      );
      throw err;
    }
  };

  toggleModal = async () => {
    const { isModalOpen } = this.state;
    this.setState({
      isModalOpen: !isModalOpen,
    });
  };

  getMonthInText = () => {
    const { month } = this.props;
    const date = new Date();

    date.setMonth(month);
    return format(date, 'MMMM', { locale });
  };

  render = () => {
    const { disabled } = this.props;
    const { isModalOpen, isLoading } = this.state;

    return (
      <>
        <Button disabled={disabled} onClick={this.toggleModal}>
          Envoyer le récapitulatif mensuel
        </Button>
        <Modal isOpen={isModalOpen} onRequestClose={this.toggleModal}>
          <ModalStyle>
            <p className="supTitle">
              Récapitulatif mois de {this.getMonthInText()}
            </p>
            <H2 blue>Confirmation</H2>
            <p>
              La confirmation enverra le récapitulatif mensuel aux exploitants
              concernés et au transporteur.
            </p>
            <div className="buttonBar">
              <Button
                type="button"
                onClick={this.toggleModal}
                disabled={isLoading}
                secondary
              >
                Annuler
              </Button>
              <Button disabled={isLoading} onClick={this.handleSendReport}>
                Envoyer
              </Button>
            </div>
          </ModalStyle>
        </Modal>
      </>
    );
  };
}

MonthlyReturnPoolsReportButton.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withApollo(MonthlyReturnPoolsReportButton);
