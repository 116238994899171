import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as Heifer } from '../statics/ico/heifer_transparent.svg';
import Breadcrumb from '../atoms/Breadcrumb';

const HeaderColorStyle = styled.section`
  height: 110px;
  background-image: ${({ theme, color }) => theme[`${color}Gradation`]};
  position: relative;

  .heifer__picto {
    position: absolute;
    bottom: 0;

    &:first-child {
      left: 121px;
    }

    &:last-child {
      right: 106px;
      transform: scaleX(-1);
    }
  }

  > button {
    position: absolute;
    left: 53px;
    top: 40px;
  }
`;

const HeaderColor = ({ color }) => (
  <HeaderColorStyle color={color}>
    <div className="heifer__picto">
      <Heifer />
    </div>
    <Breadcrumb />
    <div className="heifer__picto">
      <Heifer />
    </div>
  </HeaderColorStyle>
);

HeaderColor.propTypes = {
  color: PropTypes.string.isRequired,
};

export default HeaderColor;
