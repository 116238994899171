import styled from 'styled-components';

import ChevronGrey from '../../statics/ico/chevron__grey.svg';
import Chevronblue from '../../statics/ico/chevron.svg';

const CellStyle = styled.div`
  display: block;
  position: relative;
  padding: 12px;
  align-self: center;

  > * {
    font-size: 12px;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  > button.sortButton {
    background: transparent;
    font-weight: 700;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    padding-right: 6px;

    &:after {
      content: '';
      display: inline-block;
      background: url(${ChevronGrey}) no-repeat center center transparent;
      width: 12px;
      height: 7px;
      background-size: contain;
      transform: translateX(6px);
      transition: ${({ theme }) => theme.transition};
    }

    &.active:after {
      background-image: url(${Chevronblue});
    }

    &.asc:after {
      transform: translateX(6px) rotate(180deg);
    }
  }
`;

export default CellStyle;
