import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';

import CellStyle from '../../atoms/table/TableCell';
import HeaderRowStyle from '../../atoms/table/TableHeaderRow';
import { getEventDate } from '../../commons/heiferEvents';
import UserContext from '../../context';
import { byFarmNameHeiferComparator } from '../../commons/orderList';

export const HeiferHeaderRowStyle = styled(HeaderRowStyle)`
  border-radius: 0;
  margin-bottom: 1px;
`;

export const ClassicCellStyle = styled(CellStyle)`
  flex: 1;
  padding: 12px;
`;

export const LargeCellStyle = styled(CellStyle)`
  flex: 2;
  padding: 12px;
`;

const DelegationHeifersListStyle = styled.div`
  box-shadow: ${({ theme }) => theme.darkShadow};
  overflow: hidden;
  border-radius: 5px;
  margin: 10px 0;
`;

const HeiferItemStyle = styled(CellStyle)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
  padding: 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  .informations {
    display: flex;
    color: ${({ theme }) => theme.grey500};
    background-color: #fff;
  }

  .clickableInformations {
    display: flex;
    flex: 0 1 100%;
    text-decoration: none;
    color: inherit;

    > * {
      padding: 12px;
    }
  }

  .heiferItem__id {
    font-weight: 700;
  }
`;

class DelegationHeifersList extends Component {
  static contextType = UserContext;

  render = () => {
    const { heifers } = this.props;
    const { userGroups } = this.context;

    return (
      <DelegationHeifersListStyle>
        <HeiferHeaderRowStyle>
          <ClassicCellStyle>
            <span>Génisses</span>
          </ClassicCellStyle>
          <LargeCellStyle>
            <span>Naisseur</span>
          </LargeCellStyle>
          <ClassicCellStyle>
            <span>Adresse</span>
          </ClassicCellStyle>
          <ClassicCellStyle>
            <span>Date de naissance</span>
          </ClassicCellStyle>
        </HeiferHeaderRowStyle>
        {heifers.sort(byFarmNameHeiferComparator('producer')).map(heifer => (
          <HeiferItemStyle key={heifer.id}>
            <div className="informations">
              <Link
                className="clickableInformations"
                to={`${
                  userGroups.includes('admin') ? '/view/farms' : ''
                }/heifer/${heifer.id}`}
              >
                <ClassicCellStyle>
                  <span className="heiferItem__id">{heifer.id_heifer}</span>
                </ClassicCellStyle>
                <LargeCellStyle>
                  <span>{heifer.producer.farm_name}</span>
                </LargeCellStyle>
                <ClassicCellStyle>
                  <span>
                    {heifer.producer.address.street},{' '}
                    {heifer.producer.address.city}
                  </span>
                </ClassicCellStyle>
                <ClassicCellStyle>
                  {getEventDate(heifer.events, 'BIRTH')}
                </ClassicCellStyle>
              </Link>
            </div>
          </HeiferItemStyle>
        ))}
      </DelegationHeifersListStyle>
    );
  };
}

DelegationHeifersList.propTypes = {
  heifers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
      producer: PropTypes.shape({
        name: PropTypes.string.isRequired,
        farm_name: PropTypes.string.isRequired,
        address: PropTypes.shape({
          street: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
        }),
      }),
      events: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

export default DelegationHeifersList;
