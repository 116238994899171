import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parseISO, parse } from 'date-fns';
import { withApollo } from 'react-apollo';

import HeaderRowStyle from '../../../atoms/table/TableHeaderRow';
import CellStyle from '../../../atoms/table/TableCell';

import {
  orderByAttribut,
  orderByEventDate,
  orderByHeiferState,
  orderByValue,
} from '../../../commons/orderList';
import EmptyList from '../../EmptyList';
import Item from './Item';
import Select from '../../../atoms/Select';
import {
  getHeiferAgeAtCalving,
  HEIFER_STATUS,
  HEIFER_STATUS_LABEL_UPCASE,
  heiferCurrentState,
} from '../../../commons/heiferEvents';
import { GET_POOLS, GET_BILLINGS } from '../../../graphql/queries';
import loader from '../../../statics/loader.gif';

export const CheckCellStyle = styled(CellStyle)`
  flex: 0.3;
`;

export const HeiferCellStyle = styled(CellStyle)`
  flex: 1.2;
  > * {
    font-weight: 700;
  }
`;

const SmallCellStyle = styled(CellStyle)`
  flex: 1;

  > * {
    font-weight: 700;
  }
`;

const WeighingCell = styled(CellStyle)`
  flex: 1.2;

  > * {
    font-weight: 700;
  }
`;

const ListCheckBtnStyle = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select {
    cursor: pointer;
    color: ${({ theme }) => theme.blue500};
    font-weight: 700;
  }
  .unselect {
    cursor: pointer;
    margin-left: 22px;
  }
`;

const HeifersDetailedList = ({
  heifers,
  values,
  showInputs,
  selected,
  setSelected,
  client,
  farmType,
}) => {
  const [orderDirection, changeOrderDirection] = useState('asc');
  const [orderValue, changeOrderValue] = useState('state');
  const [filter, setFilter] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const [heiferList, setHeiferList] = useState([]);
  const [defaultAge, setDefaultAge] = useState(null);
  const [isBio, setIsBio] = useState(null);

  useEffect(() => {
    async function loadHeifers() {
      setLoading(true);

      const poolResponse = await client.query({
        query: GET_POOLS,
        fetchPolicy: 'network-only',
        variables: { type: 'DELEGATION' },
      });

      const billingresponse = await client.query({
        query: GET_BILLINGS,
        variables: {},
      });

      const billingType = farmType === 'BIO' ? 'BIO' : 'CONV';

      setIsBio(billingType === 'BIO');

      let ageMiniNoReturn = null;

      const heifersList = heifers.map(heifer => {
        const pool = poolResponse.data.pools.find(pool =>
          pool.heifers
            .filter(h => !!h)
            .some(h => h.id_heifer === heifer.id_heifer)
        );
        if (pool) {
          const billing = billingresponse.data.billings.filter(
            billing =>
              billing.billing_type === billingType &&
              parse(billing.start_date, 'dd/MM/yyyy', new Date()) <
                parseISO(pool.transfer_date) &&
              parse(billing.end_date, 'dd/MM/yyyy', new Date()) >
                parseISO(pool.transfer_date),
          );
          ageMiniNoReturn =
            billing.length > 0 ? billing[0].no_return.toString() : '30';
        }
        const heiferState = heiferCurrentState(
          heifer.events,
          ageMiniNoReturn,
          billingType === 'BIO' ? 'BIO' : 'CONV',
        );
        return { ...heifer, ageMiniNoReturn, state: heiferState };
      });

      setDefaultAge(ageMiniNoReturn);
      setHeiferList(heifersList);
      setLoading(false);
    }
    loadHeifers();
  }, [heifers, client, farmType]);

  function getOrderedList() {
    const orderStatement = orderDirection === 'asc' ? 1 : -1;
    switch (orderValue) {
      case 'state':
        return orderByHeiferState(heiferList, orderStatement);
      case 'age':
        return orderByValue(heiferList, getHeiferAgeAtCalving, orderStatement);
      case 'delegation':
      case 'return':
      case 'calving':
        return orderByEventDate(
          heiferList,
          orderStatement,
          orderValue.toUpperCase(),
        );
      default:
        return orderByAttribut(heiferList, 'id_heifer', orderStatement);
    }
  }

  function filterHeifer(exclude) {
    return heifer => {
      const heiferState = heifer.state;

      if (filter === heiferState) return true;
      if (exclude && heiferState === HEIFER_STATUS._9_DEAD) return false;
      if (exclude && heiferState === HEIFER_STATUS._8_ENDED) return false;

      return filter === 'ALL';
    };
  }

  function toggleOrder(e, newOrderValue) {
    e.preventDefault();
    changeOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    changeOrderValue(newOrderValue);
  }

  function headerClass(id) {
    return `sortButton ${orderValue === id ? `active ${orderDirection}` : ''}`;
  }

  function toggleSelected(id) {
    setSelected(
      selected.includes(id)
        ? selected.filter(i => i !== id)
        : [...selected, id],
    );
  }

  function unselect() {
    setSelected([]);
  }

  function setDisplayFilter(e) {
    if (typeof e === 'object' && 'target' in e && 'value' in e.target) {
      setFilter(e.target.value);
      unselect();
    }
  }

  return (
    <div>
      {toggleSelected && (
        <ListCheckBtnStyle>
          <div className="left" />
          <Select
            label="Afficher"
            labelNextTo
            name="display"
            value={filter}
            onChange={setDisplayFilter}
          >
            <option value="ALL">Toutes</option>
            {Object.getOwnPropertyNames(HEIFER_STATUS)
              .filter(status =>
                isBio
                  ? status !== HEIFER_STATUS._6_OLD
                  : status !== HEIFER_STATUS._11_OLD_BIO,
              )
              .map(status => (
                <option value={status} key={status}>
                  {HEIFER_STATUS_LABEL_UPCASE[status].replace(
                    '%age',
                    defaultAge || '',
                  )}
                </option>
              ))}
          </Select>
        </ListCheckBtnStyle>
      )}
      <HeaderRowStyle>
        {toggleSelected && <CheckCellStyle />}
        <HeiferCellStyle>
          <button
            className={headerClass('id_heifer')}
            onClick={e => toggleOrder(e, 'id_heifer')}
          >
            Génisse
          </button>
        </HeiferCellStyle>
        <SmallCellStyle>
          <p>Âge</p>
        </SmallCellStyle>
        <WeighingCell>
          <p>Poids arrivée</p>
        </WeighingCell>
        <WeighingCell>
          <p>Poids insémination</p>
        </WeighingCell>
        <WeighingCell>
          <p>Poids sortie</p>
        </WeighingCell>
        <SmallCellStyle>
          <button
            className={headerClass('age')}
            onClick={e => toggleOrder(e, 'age')}
          >
            Âge au vêlage
          </button>
        </SmallCellStyle>
        <SmallCellStyle>
          <button
            className={headerClass('delegation')}
            onClick={e => toggleOrder(e, 'delegation')}
          >
            Arrivée
          </button>
        </SmallCellStyle>
        <SmallCellStyle>
          <button
            className={headerClass('return')}
            onClick={e => toggleOrder(e, 'return')}
          >
            Retour
          </button>
        </SmallCellStyle>
        <SmallCellStyle>
          <button
            className={headerClass('calving')}
            onClick={e => toggleOrder(e, 'calving')}
          >
            Terme
          </button>
        </SmallCellStyle>
        <HeiferCellStyle>
          <button
            className={headerClass('state')}
            onClick={e => toggleOrder(e, 'state')}
          >
            État
          </button>
        </HeiferCellStyle>
      </HeaderRowStyle>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={loader} alt="Loader" />
        </div>
      ) : heifers.length > 0 ? (
        getOrderedList()
          .filter(filterHeifer(true))
          .map(heifer => (
            <Item
              key={heifer.id}
              // Tweak the index to -1 (not existing) if not in edit mode
              // in order to prevent showing the input when no necessary
              indexFormValues={
                showInputs
                  ? values.heifers.findIndex(h => h.id === heifer.id)
                  : -1
              }
              checked={selected && selected.includes(heifer.id)}
              toggleChecked={() => toggleSelected(heifer.id)}
              heifer={heifer}
              defaultAge={defaultAge}
            />
          ))
      ) : (
        <EmptyList text="Aucune génisse n’a été ajoutée pour l’instant." />
      )}
    </div>
  );
};

HeifersDetailedList.defaultProps = {
  selected: null,
  setSelected: null,
};

HeifersDetailedList.propTypes = {
  heifers: PropTypes.array.isRequired,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  values: PropTypes.object.isRequired,
  showInputs: PropTypes.bool.isRequired,
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
  farmType: PropTypes.string.isRequired,
};

export default withApollo(HeifersDetailedList);
