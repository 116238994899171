import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { formErrorMsgs } from '../../constants';
import { FormikSelect } from '../../molecules/Formik';
import Button from '../../atoms/Button';
import PoolSummaryTitle from '../PoolSummaryTitle';

const FormStyle = styled(Form)`
  .form__row {
    display: flex;
    margin: 0 -10px;

    & > * {
      flex: 1 0 0%;
      padding: 10px 10px;
    }
  }

  .buttonsBar {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > * {
      margin: 0 10px;
    }
  }
`;

const initialValues = {
  carrier: '',
};

const formSchema = Yup.object().shape({
  carrier: Yup.string().required(formErrorMsgs.required),
});

const StepOne = ({ carriers, pool, onRequestClose, onSubmit }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={formSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      resetForm(initialValues);
      onSubmit(values);
    }}
  >
    {({ resetForm, isSubmitting }) => (
      <FormStyle>
        <PoolSummaryTitle title="Choix du transporteur" pool={pool} />
        <div className="form__row">
          <Field
            name="carrier"
            placeholder="Transporteur"
            component={FormikSelect}
          >
            {carriers.map(carrier => (
              <option value={carrier.id} key={carrier.id}>
                {carrier.name}
              </option>
            ))}
          </Field>
        </div>

        <div className="buttonsBar">
          <Button
            type="button"
            onClick={() => {
              resetForm(initialValues);
              onRequestClose();
            }}
            disabled={isSubmitting}
            secondary
          >
            Annuler
          </Button>

          <Button type="submit" disabled={isSubmitting}>
            Suivant
          </Button>
        </div>
      </FormStyle>
    )}
  </Formik>
);

StepOne.propTypes = {
  carriers: PropTypes.array.isRequired,
  pool: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StepOne;
