import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  differenceInCalendarMonths,
  differenceInCalendarDays,
  parseISO,
} from 'date-fns';

const HeiferAge = ({ birthDate }) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    const diffInMonths = differenceInCalendarMonths(
      new Date(),
      parseISO(birthDate),
    );
    const diffInDays = differenceInCalendarDays(
      new Date(),
      parseISO(birthDate),
    );

    if (diffInMonths > 0) return setDate(`${diffInMonths} mois`);
    if (diffInDays > 1) return setDate(`${diffInDays} jours`);

    setDate(`1 jour`);
  }, [birthDate]);

  return <span data-testid="elm">{date}</span>;
};

HeiferAge.propTypes = {
  birthDate: PropTypes.string.isRequired,
};

export default HeiferAge;
