import styled from 'styled-components';

const H3 = styled.h3`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.blue500};
`;

export default H3;
