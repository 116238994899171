import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Check from '../statics/ico/check.svg';

const CheckboxStyle = styled.label`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .label {
    color: ${({ theme }) => theme.grey500};
    font-size: 14px;
    padding-left: 10px;
  }

  input {
    position: absolute;
    opacity: 0;

    + .checkmark {
      height: 16px;
      width: 16px;
      border-radius: 3px;
      border: ${({ theme }) => `solid 2px ${theme.grey100}`};
      transition: all 150ms;
    }

    &:checked {
      + .checkmark {
        border: ${({ theme }) => `solid 2px ${theme.blue500}`};

        &::after {
          content: '';
          position: absolute;
          background: url('${Check}') no-repeat center center;
          height: 12px;
          width: 12px; 
        }
      }
    }

    &:disabled + .checkmark {
      opacity: 0.4;
    }
  }
`;

const Checkbox = ({ name, label, ...props }) => (
  <CheckboxStyle htmlFor={name}>
    <input type="checkbox" name={name} id={name} {...props} />
    <span className="checkmark" />
    {label && <span className="label">{label}</span>}
  </CheckboxStyle>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  label: null,
};

export default Checkbox;
