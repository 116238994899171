import styled from 'styled-components';

const InfoLabelStyle = styled.p`
  background-color: ${({
    theme,
    alert,
    valid,
    warning,
    great,
    blueberry,
    normal,
  }) =>
    (alert && theme.red300) ||
    (valid && theme.blue300) ||
    (great && theme.green300) ||
    (warning && theme.orange300) ||
    (blueberry && theme.grey000) ||
    (normal && theme.grey200) ||
    theme.grey100};
  color: ${({ theme, alert, valid, warning, great, blueberry, normal }) =>
    (alert && theme.red600) ||
    (valid && theme.blue500) ||
    (great && theme.green500) ||
    (warning && theme.orange500) ||
    (blueberry && theme.blueberry500) ||
    (normal && theme.grey100) ||
    theme.grey300};
  font-weight: 700;
  font-size: 12px;
  padding: 1px 10px;
  border-radius: 30px;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '10px')};
  display: inline-block;
`;

export default InfoLabelStyle;
