import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerStyle = styled.div`
  .definitionText__term {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
    margin-bottom: 3px;
  }

  .definitionText__content {
    display: flex;
    font-size: 14px;
  }

  .definitionText__desc {
    color: ${({ theme }) => theme.grey500};
    font-weight: 700;
  }

  .definitionText__extra {
    padding-left: 10px;
    margin-left: 10px;
    position: relative;

    &:before {
      position: absolute;
      content: '\\2022';
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: translateX(-50%);
    }
  }
`;

const DefinitionText = ({ term, desc, extra }) => (
  <ContainerStyle>
    <p className="definitionText__term">{term}</p>
    <div className="definitionText__content">
      <p className="definitionText__desc">{desc}</p>
      {extra && <p className="definitionText__extra">{extra}</p>}
    </div>
  </ContainerStyle>
);

DefinitionText.defaultProps = {
  extra: null,
};

DefinitionText.propTypes = {
  term: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  extra: PropTypes.string,
};

export default DefinitionText;
