import { getMonth, getYear } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query, withApollo } from 'react-apollo';
import Loader from '../atoms/Loader';
import { GET_HEIFER_RETURNS, GET_RETURNS_DATES } from '../graphql/queries';
import ContainerLarge from '../layouts/ContainerLarge';
import PageHeader from '../molecules/PageHeader';
import { ReactComponent as RefreshIcon } from '../statics/ico/refresh.svg';
import HeaderHeiferReturns from '../molecules/HeiferReturnsList/HeaderHeiferReturns';
import HeiferReturnsList from '../molecules/HeiferReturnsList';

class ManageHeiferReturns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: getMonth(new Date()),
      selectedYear: getYear(new Date()),
      returnDateList: [],
    };
  }

  componentDidMount = async () => {
    const { client } = this.props;

    const { data } = await client.query({
      query: GET_RETURNS_DATES,
      fetchPolicy: 'network-only',
      variables: {},
    });
    this.setState({
      returnDateList: this.addCurrentDateIfMissing(data.returnDates),
    });
  };

  addCurrentDateIfMissing = returnDates => {
    const currentYear = getYear(new Date());
    const currentMonth = getMonth(new Date());

    const isContainingCurrentMonth = returnDates.some(({ year, month }) => {
      return year === currentYear && month === currentMonth;
    });
    if (!isContainingCurrentMonth) {
      returnDates.push({ year: currentYear, month: currentMonth });
    }

    return returnDates;
  };

  handleChangeSelectedDate = event => {
    const values = event.target.value.split(' ');
    this.setState({
      selectedMonth: parseInt(values[0], 10),
      selectedYear: parseInt(values[1], 10),
    });
  };

  render = () => {
    const { selectedMonth, selectedYear, returnDateList } = this.state;

    return (
      <ContainerLarge>
        <PageHeader icon={<RefreshIcon />} title="Transferts retours" />

        <Query
          query={GET_HEIFER_RETURNS}
          variables={{ month: selectedMonth, year: selectedYear }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (error) throw new Error(error.message);

            const heifers = (data.heiferReturns || []).map(heifer => ({
              ...heifer,
              return_date: this.getReturnDate(heifer),
            }));

            return (
              <>
                <HeaderHeiferReturns
                  choices={returnDateList}
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                  changeSelectedDate={this.handleChangeSelectedDate}
                  disabledReport={heifers.length === 0}
                />
                {loading ? <Loader /> : <HeiferReturnsList list={heifers} />}
              </>
            );
          }}
        </Query>
      </ContainerLarge>
    );
  };

  getReturnDate = ({ events }) => {
    const { selectedMonth, selectedYear } = this.state;

    return (
      events
        .filter(({ type }) => type === 'RETURN')
        // If an heifer has several return events,
        // then we chose the one matching the selected year-month
        .filter(({ date }) => {
          const realMonth = selectedMonth + 1;
          const monthString = `${realMonth}`.padStart(2, '0');

          return date.startsWith(`${selectedYear}-${monthString}`);
        })
        .map(({ date }) => date)[0] || null
    );
  };
}

ManageHeiferReturns.propTypes = {
  client: PropTypes.shape({ query: PropTypes.func.isRequired }).isRequired,
};

export default withApollo(ManageHeiferReturns);
