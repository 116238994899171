import { formatISO, startOfDay } from 'date-fns';

// Find consistency in how dates are format in the app
// Use "startOfDay" to change time to 00:00:00 and format
// to convert date to string
export const formatDate = date => formatISO(startOfDay(date));

export default formatDate;

// 60 * 60 * 24 * 10e3
export const NB_MILLI_SECONDS_PER_DAY = 864e5;
