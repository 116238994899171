import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../atoms/Button';
import H2 from '../atoms/titles/H2';

const PageHeaderStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleContainerStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsContainerStyle = styled.div`
  display: flex;

  .secondaryButtonContainer {
    margin-right: 10px;
  }
`;

const TitleStyle = styled(H2)`
  margin: 0;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

class PageHeader extends React.PureComponent {
  render = () => {
    const {
      icon,
      title,
      primaryButtonLabel,
      primaryButtonAction,
      primaryButtonIcon,
      primaryButtonDisabled,
      ButtonsDisabled,
      secondaryButtonLabel,
      secondaryButtonAction,
    } = this.props;

    return (
      <PageHeaderStyle>
        <TitleContainerStyle>
          {icon !== null && <IconContainer>{icon}</IconContainer>}
          <TitleStyle>{title}</TitleStyle>
        </TitleContainerStyle>
        <ButtonsContainerStyle>
          {secondaryButtonAction && secondaryButtonLabel && (
            <div className="secondaryButtonContainer">
              <Button
                type="button"
                onClick={secondaryButtonAction}
                disabled={ButtonsDisabled}
                secondary
              >
                {secondaryButtonLabel}
              </Button>
            </div>
          )}
          {primaryButtonAction && primaryButtonLabel && (
            <Button
              type="button"
              onClick={primaryButtonAction}
              iconPlus={primaryButtonIcon}
              disabled={ButtonsDisabled || primaryButtonDisabled}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </ButtonsContainerStyle>
      </PageHeaderStyle>
    );
  };
}

PageHeader.defaultProps = {
  icon: null,
  primaryButtonLabel: null,
  primaryButtonAction: null,
  primaryButtonIcon: true,
  primaryButtonDisabled: false,
  ButtonsDisabled: false,
  secondaryButtonLabel: null,
  secondaryButtonAction: null,
};

PageHeader.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  primaryButtonIcon: PropTypes.bool,
  primaryButtonDisabled: PropTypes.bool,
  ButtonsDisabled: PropTypes.bool,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
};

export default PageHeader;
