import React from 'react';
import PropTypes from 'prop-types';

import Input from '../atoms/Input';
import Select from '../atoms/Select';
import Checkbox from '../atoms/Checkbox';
import TextArea from '../atoms/TextArea';
import DatePicker from '../atoms/DatePicker';
import H2 from '../atoms/titles/H2';

export const getErrorMessage = (name, touched, errors) => {
  let isTouched = touched[name];
  let errorMessage = errors[name];

  // Particular case when the field to check is nested within an object.
  // In this case the format of the name is "foo.bar" making impossible to
  // target the element with a "touched[<name>]".
  // To bypass that, this logic split the name then check if
  // "touched[<name first part>][<name second part>]" exists.
  if (name.includes('.')) {
    const splittedName = name.split('.');

    isTouched =
      splittedName[0] in touched && splittedName[1] in touched[splittedName[0]];

    errorMessage =
      splittedName[0] in errors && splittedName[1] in errors[splittedName[0]]
        ? errors[splittedName[0]][splittedName[1]]
        : '';
  }

  return isTouched && errorMessage ? errorMessage : '';
};

export const FormikInput = ({
  field,
  form: { touched, errors },
  customError,
  ...props
}) => (
  <Input
    {...field}
    {...props}
    error={getErrorMessage(field.name, touched, errors) || customError}
  />
);

FormikInput.defaultProps = {
  customError: null,
};

FormikInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      date: PropTypes.string,
      eventType: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      date: PropTypes.bool,
      eventType: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  customError: PropTypes.string,
};

export const FormikCheckbox = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <Checkbox
    {...field}
    {...props}
    error={getErrorMessage(field.name, touched, errors)}
  />
);

FormikCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      date: PropTypes.string,
      eventType: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      date: PropTypes.bool,
      eventType: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormikSelect = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => (
  <Select
    {...field}
    {...props}
    error={getErrorMessage(field.name, touched, errors)}
  >
    {children}
  </Select>
);

FormikSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      date: PropTypes.string,
      eventType: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      date: PropTypes.bool,
      eventType: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export const FormikTextArea = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <TextArea
    {...field}
    {...props}
    error={getErrorMessage(field.name, touched, errors)}
  />
);

FormikTextArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      date: PropTypes.string,
      eventType: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      date: PropTypes.bool,
      eventType: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const FormikDatePickerArea = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  ...props
}) => (
  <DatePicker
    id={field.name}
    label={label}
    isModal
    info={<H2 blue>{label}</H2>}
    onChange={e => setFieldValue(field.name, e)}
    customMonthElement
    numberOfMonths={1}
    date={field.value}
    error={getErrorMessage(field.name, touched, errors)}
    {...props}
  />
);

FormikDatePickerArea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      date: PropTypes.string,
      eventType: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      date: PropTypes.bool,
      eventType: PropTypes.bool,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
};
