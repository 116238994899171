import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as DeleteIcon } from '../statics/ico/delete.svg';

const ButtonLinkStyle = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.grey700};
  font-weight: bold;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  &:hover {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }

  .icon * {
    stroke: currentColor;
  }

  .icon--delete {
    width: 12px;
    height: 14px;
  }

  .content {
    margin-left: 8px;
  }
`;

const ButtonLinkIcon = ({ children, icon, disabled, onClick }) => (
  <ButtonLinkStyle disabled={disabled} onClick={onClick}>
    {icon === 'delete' && <DeleteIcon className="icon icon--delete" />}
    <span className="content">{children}</span>
  </ButtonLinkStyle>
);

ButtonLinkIcon.defaultProps = {
  disabled: false,
};

ButtonLinkIcon.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(['delete']).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonLinkIcon;
