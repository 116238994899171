import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { getEventDate } from '../../commons/heiferEvents';
import { EVENT_TYPES } from '../../constants';

const Container = styled.div`
  overflow-y: scroll;
  background-color: #fff;
  margin-bottom: 2px;
`;

const Item = styled(Link)`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.grey500};
  text-decoration: none;

  > * {
    padding: 12px;
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.grey200};
  }

  .farmName {
    flex: 3;
  }
`;

const HeifersList = ({ list, type }) => (
  <Container>
    {list.map(h => (
      <Item to={`/heifer/${h.id}`} key={h.id}>
        <div>{h.id_heifer}</div>
        <div className="farmName">naisseur : {h.producer.farm_name}</div>
        {type === 'DELEGATION' ? (
          <div>née le {getEventDate(h.events, EVENT_TYPES.BIRTH)}</div>
        ) : (
          <div>
            vêlage estimé le {getEventDate(h.events, EVENT_TYPES.CALVING)}
          </div>
        )}
        <div />
      </Item>
    ))}
  </Container>
);

HeifersList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      id_heifer: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['RETURN', 'DELEGATION']).isRequired,
};

export default memo(HeifersList);
