import styled from 'styled-components';

const InlineListStyle = styled.div`
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child):after {
    margin: 0 5px;
    content: ${({ separator }) => (separator ? "'\\2022'" : "' '")};
  }
`;

export default InlineListStyle;
