import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EmptyList from '../EmptyList';
import Item from '../PoolWaitingItem';
import DelegationHeifersList from './HeifersList';
import Accordion from '../../atoms/Accordion';

const ListStyle = styled.div`
  overflow: hidden;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
`;

function containsAtLeastOneHeifer(pool) {
  return pool.heifers.length > 0;
}

const List = ({ items, onDeletePoolHeifer }) => {
  function onRemoveHeiferFromPool(poolId) {
    return heiferId => onDeletePoolHeifer(poolId, heiferId);
  }

  if (items.length === 0)
    return <EmptyList text="Il n’y a pas de lots à affecter pour l’instant." />;

  return items.map(item => (
    <ListStyle key={`${item.id}_${item.transfer_date}`}>
      {'farm_name' in item ? (
        <Item
          farmName={item.farm_name}
          transferDate={item.transfer_date}
          farmId={item.id}
          capacity={item.capacity_per_pool}
          poolType="DELEGATION"
          disabled
        />
      ) : (
        <Accordion
          expandable={
            item.heifers.length > 0 && (
              <DelegationHeifersList
                heifers={item.heifers}
                onDeletePoolHeifer={onRemoveHeiferFromPool(item.id)}
              />
            )
          }
          visible={({ toggleAccordion, isOpened }) => (
            <Item
              onShow={toggleAccordion}
              opened={isOpened}
              poolId={item.id}
              poolIdToShow={item.id_pool}
              transferDate={item.transfer_date}
              farmName={item.breeder.farm_name}
              farmId={item.breeder.id}
              heifersLength={item.heifers.length}
              capacity={item.breeder.capacity_per_pool}
              lastPoolAvailabilitiesNotification={
                item.last_pool_availabilities_notification
              }
              poolType="DELEGATION"
              activateButtons={containsAtLeastOneHeifer(item)}
            />
          )}
        />
      )}
    </ListStyle>
  ));
};

List.defaultProps = {
  onDeletePoolHeifer: () => {},
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDeletePoolHeifer: PropTypes.func,
};

export default List;
