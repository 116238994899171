import { closestTo, compareAsc, format, isFuture, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '../../atoms/Button';
import Modal from '../../atoms/Modal';
import H2 from '../../atoms/titles/H2';
import EditFarmAvailabilities from '../../organisms/EditFarmAvailabilities';
import Calendar from './Calendar';

const ContainerStyle = styled.div`
  display: flex;
  box-shadow: ${({ theme }) => theme.darkShadow};
  border-radius: 5px;

  .sidebar {
    flex: 0 0 212px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 5px 0 0 5px;
    background-color: ${({ theme }) => theme.grey700};
    color: #fff;

    .buttonContainer {
      margin-top: 30px;
    }
  }

  .availabilitiesContainer {
    display: flex;
    flex-direction: column;
  }

  .nextAvailability {
    color: inherit;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 10px 0 5px;
    margin-bottom: 20px;

    span {
      display: block;
      font-size: 16px;
      color: ${({ theme }) => theme.blue500};
      font-weight: 700;
      line-height: 22px;
    }
  }

  .availabilitiesList {
    .title {
      color: inherit;
      font-size: 12px;
    }

    .item {
      color: inherit;
      margin: 5px 0;
    }
  }

  .light.sidebar {
    flex: 0 0 100%;
    background-color: #fff;
    color: inherit;
    padding: 20px 30px;
    border-radius: 5px;

    .availabilitiesContainer {
      flex-direction: row;
    }

    .nextAvailability {
      padding: 0;
      margin: 0 50px 0 0;
      border: none;

      span {
        margin-top: 1px;
      }
    }

    .availabilitiesList {
      .title {
        font-size: 14px;
      }

      .list {
        display: flex;
        margin: 0 -5px;
      }

      .item {
        margin: 5px;
      }
    }
  }
`;

class FarmAvailabilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAvailabilitiesModalOpen: false,
      selectedDate: null,
    };
  }

  getFutureAvailabilities = () => {
    const { availabilities } = this.props;

    return availabilities
      .map(date => parseISO(date))
      .filter(date => isFuture(date))
      .sort((date1, date2) => compareAsc(date1, date2));
  };

  // Get only the date in the future and then find the
  // closest to now
  getNextAvailability = () => {
    const futureDates = this.getFutureAvailabilities();

    if (futureDates.length > 0) {
      return closestTo(new Date(), futureDates);
    }

    return null;
  };

  toggleAvailabilitiesModal = (date = null) => {
    const { isAvailabilitiesModalOpen } = this.state;

    this.setState({
      selectedDate: date,
      isAvailabilitiesModalOpen: !isAvailabilitiesModalOpen,
    });
  };

  render = () => {
    const { availabilities, farmId, lightVersion } = this.props;
    const { isAvailabilitiesModalOpen, selectedDate } = this.state;
    const futureAvailabilities = this.getFutureAvailabilities();
    const nextAvailability = futureAvailabilities.shift();

    return (
      <ContainerStyle>
        <div className={`sidebar ${lightVersion ? 'light' : ''}`}>
          <div>
            <H2 white={!lightVersion}>Disponibilités</H2>

            <div className="availabilitiesContainer">
              <p className="nextAvailability">
                Prochaine disponibilité
                <span data-cy="farmAvailabilities__nextAvailability">
                  {nextAvailability
                    ? format(nextAvailability, 'dd/MM/yyyy')
                    : 'Aucune'}
                </span>
              </p>

              {futureAvailabilities.length > 0 && (
                <div className="availabilitiesList">
                  <p className="title">
                    {futureAvailabilities.length === 1
                      ? 'Autre disponibilité'
                      : 'Autres disponibilités'}
                  </p>
                  <div className="list">
                    {futureAvailabilities.map(a => (
                      <p className="item" key={a}>
                        {format(a, 'dd/MM/yyyy')}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {!lightVersion && (
            <div className="buttonContainer">
              <Button
                secondary
                onClick={this.toggleAvailabilitiesModal}
                data-cy="farmAvailabilities__edit"
              >
                Modifier
              </Button>
            </div>
          )}
        </div>

        {!lightVersion && (
          <>
            <Calendar
              availabilities={availabilities}
              handleOnChange={date => this.toggleAvailabilitiesModal(date)}
            />
            <Modal
              isOpen={isAvailabilitiesModalOpen}
              onRequestClose={this.toggleAvailabilitiesModal}
            >
              <EditFarmAvailabilities
                closeModal={this.toggleAvailabilitiesModal}
                farmId={farmId}
                date={selectedDate}
              />
            </Modal>
          </>
        )}
      </ContainerStyle>
    );
  };
}

FarmAvailabilities.defaultProps = {
  lightVersion: false,
};

FarmAvailabilities.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  farmId: PropTypes.string.isRequired,
  lightVersion: PropTypes.bool,
};

export default FarmAvailabilities;
