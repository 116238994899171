import React from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator, ConfirmSignIn, Greetings } from 'aws-amplify-react';
import PropTypes from 'prop-types';

import Logout from '../../molecules/Logout';
import SignIn from './SignIn';
import RequireNewPassword from './RequireNewPassword';
import ForgotPassword from './ForgotPassword';

import UserContext from '../../context';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_GROUP_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
});

const AuthComponent = ({ onStateChange, authData, children }) => {
  const userGroups =
    authData.signInUserSession.idToken.payload['cognito:groups'];

  return (
    <UserContext.Provider
      value={{
        onStateChange,
        username: authData,
        userGroups,
      }}
    >
      {userGroups ? (
        children
      ) : (
        <div>
          <p>No user group</p>
          <Logout />
        </div>
      )}
    </UserContext.Provider>
  );
};

AuthComponent.propTypes = {
  onStateChange: PropTypes.func.isRequired,
  authData: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withAuthenticator(AuthComponent, false, [
  <Greetings key="greetings" />,
  <SignIn key="signin" />,
  <ConfirmSignIn key="confirmSignin" />,
  <RequireNewPassword key="requireNewPassword" />,
  <ForgotPassword key="forgotPassword" />,
]);
